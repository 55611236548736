import React, { PureComponent } from 'react'
import { bool } from 'prop-types'

import CustomRangeControl from './CustomRangeControl'
import NativeRangeControl from './NativeRangeControl'

class RangeControl extends PureComponent {
  render() {
    const { native, ...controlProps } = this.props
    const RangeControlType = native ? NativeRangeControl : CustomRangeControl

    return <RangeControlType {...controlProps} />
  }
}

RangeControl.displayName = 'RangeControl'

RangeControl.propTypes = {
  native: bool
}

export default RangeControl
