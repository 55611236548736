import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StandingsTable from '../Component/StandingsTable'
import withPrimus from '../../../../../../component/Primus/with-primus'

import { fetchStandings } from '../actions'

class StandingsTableContainer extends Component {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
  }

  componentDidMount() {
    this.props.fetchStandings(this.primus)
  }

  render() {
    return (
      <Fragment>
        <StandingsTable standings={this.props.standings} />
      </Fragment>
    )
  }
}

StandingsTableContainer.propTypes = {
  primus: PropTypes.object.isRequired,
  fetchStandings: PropTypes.func.isRequired,
  standings: PropTypes.array
}

StandingsTableContainer.defaultProps = {
  standings: []
}

const mapStateToProps = state => state.standings.toJS()
const mapDispatchToProps = dispatch => ({
  fetchStandings: primus => dispatch(fetchStandings(primus))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(StandingsTableContainer))
