import moment from 'moment'

const formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'D MMM YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY LT',
  LLLL: 'dddd D MMMM YYYY LT'
}

const FormattedDate = ({ date, format = 'LLL' }) => {
  if (!date) return ''
  return moment(date).format(formats[format] || formats['LLL'])
}

export default FormattedDate
