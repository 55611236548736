import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './LinkList.styl'

import Type from '../Type'

export class LinkList extends PureComponent {
  render() {
    const { children, heading, theme } = this.props

    return (
      <div
        className={classNames(
          styles.LinkList,
          theme && styles[`LinkList-${theme}`]
        )}
      >
        {heading && (
          <div className={classNames(styles.LinkListHeader)}>
            <Type element="h2" size="tiny">
              {heading}
            </Type>
          </div>
        )}
        {children}
      </div>
    )
  }
}

LinkList.displayName = 'LinkList'

LinkList.propTypes = {
  children: node.isRequired,
  heading: string,
  theme: string
}

export default withTheme(LinkList)
