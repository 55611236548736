import React, { PureComponent } from 'react'
import { object, array } from 'prop-types'
import moment from 'moment'
import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Hero from '../../../../../../component/Primitive/Hero'
import Type from '../../../../../../component/Primitive/Type'
import Rule from '../../../../../../component/Primitive/Rule'
import Summary from '../../../../../../component/Primitive/Summary'
import Byline from '../../../../../../component/Primitive/Byline'

import WidgetArea from '../../../../../../component/Common/WidgetArea'

class Article extends PureComponent {
  render() {
    let { article, articles } = this.props

    return (
      <Section grow>
        <ScrollArea>
          <Hero
            imageUrl={article.thumbnailImageUrl}
            heading={article.headline}
            category={article.category}
          />

          <SpacedList>
            <Section spacing="xt">
              <Byline
                displayDate={moment(article.displayDate).format('D MMMM YYYY')}
                timestamp={article.displayDate}
                author={article.author || ''}
              />
            </Section>
            <WidgetArea widgets={article.body.widgets} />
          </SpacedList>

          <Section spacing="xy">
            <SpacedList>
              <Rule muted />
              <Type element="h4" size="subtitle">
                More news
              </Type>
              {articles
                .filter(({ _id }) => _id !== article._id)
                .map(otherArticle => (
                  <Summary
                    key={`other-article-${otherArticle._id}`}
                    imageUrl={otherArticle.thumbnailImageUrl}
                    to={`/activity/home/news/article/${otherArticle.slug}`}
                    heading={otherArticle.headline}
                    category={otherArticle.category}
                  />
                ))}
            </SpacedList>
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Article.propTypes = {
  article: object,
  articles: array
}

export default Article
