import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class MotmTile extends PureComponent {
  render() {
    const { height } = this.props
    return (
      <Tile
        heading="Stowford&nbsp;Press Man&nbsp;of&nbsp;the&nbsp;Match"
        to="/activity/play/motm"
        imageUrl={image}
        height={height}
      />
    )
  }
}

MotmTile.propTypes = {
  height: number
}

export default MotmTile
