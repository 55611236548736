import React, { PureComponent } from 'react'
import { bool, func, node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Tile.styl'

import BackgroundImage from '../BackgroundImage'
import SmartLink from '../SmartLink'
import Type from '../Type'
import Icon from '../Icon'

class TileHeading extends PureComponent {
  render() {
    const { heading, secondary } = this.props
    return (
      <Type element="h2" size={secondary ? 'display1' : 'display2'}>
        {heading}
      </Type>
    )
  }
}

TileHeading.propTypes = {
  heading: node,
  secondary: bool
}

class Tile extends PureComponent {
  render() {
    const {
      active,
      ctaIcon,
      ctaIconOffset,
      ctaText,
      description,
      heading,
      height,
      imageUrl,
      handleClick,
      secondary,
      to,
      width
    } = this.props

    const dimensions = {
      flexBasis: `${parseFloat((height * 100).toFixed(4))}%`,
      width: `${parseFloat((width * 100).toFixed(4))}%`
    }

    const hasLink = to || handleClick
    return (
      <div
        className={classNames(styles.Tile, active && styles.active)}
        style={dimensions}
      >
        <div className={styles.TileImage}>
          {!!imageUrl && <BackgroundImage url={imageUrl} alt="" fullsize />}
        </div>
        <div className={styles.TileInner}>
          <div className={styles.TileContent}>
            {!hasLink && (
              <TileHeading heading={heading} secondary={secondary} />
            )}
            {!!hasLink && (
              <SmartLink
                className={styles.TileLink}
                to={to}
                onClick={handleClick}
              >
                <TileHeading heading={heading} secondary={secondary} />
              </SmartLink>
            )}
            {description && <p>{description}</p>}
          </div>

          <div className={styles.TileCta}>
            {!!hasLink && (
              <div
                className={styles.TileCtaIcon}
                style={{
                  marginLeft: ctaIconOffset,
                  marginRight: ctaIconOffset
                }}
              >
                <Icon type={ctaIcon} />
              </div>
            )}
            {ctaText && <div className={styles.TileCtaText}>{ctaText}</div>}
          </div>
        </div>
      </div>
    )
  }
}

Tile.displayName = 'Tile'

Tile.defaultProps = {
  ctaIcon: 'ChevronRight',
  ctaIconOffset: -7
}

Tile.propTypes = {
  active: bool,
  heading: node,
  ctaIcon: string,
  ctaIconOffset: number,
  ctaText: string,
  description: string,
  height: number,
  imageUrl: string.isRequired,
  handleClick: func,
  secondary: bool,
  to: string,
  width: number
}

export default Tile
