import React, { PureComponent } from 'react'
import { node, number, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Comment.styl'

import Icon from '../Icon'
import Type from '../Type'
import Prose from '../Prose'
import SocialEmbed from '../SocialEmbed'

export class Comment extends PureComponent {
  render() {
    const {
      a11yText,
      description,
      heading,
      icon,
      minute,
      socialId,
      theme,
      type
    } = this.props

    return (
      <div
        className={classNames(
          styles.Comment,
          theme && styles[`Comment-${theme}`],
          styles[type],
          theme && styles[`${type}-${theme}`]
        )}
      >
        <div className={styles.CommentInner}>
          <div className={styles.CommentAside}>
            <div
              className={classNames(
                styles.CommentIcon,
                theme && styles[`CommentIcon-${theme}`]
              )}
            >
              {icon && <Icon type={icon} a11yText={a11yText} height={24} />}
              {!icon && <span>{minute}</span>}
            </div>
            {icon && minute >= 0 && (
              <div className={styles.CommentTime}>{minute}</div>
            )}
          </div>
          <div
            className={classNames(
              styles.CommentContent,
              !heading && styles.spacingTop
            )}
          >
            {heading && (
              <Type
                className={styles.CommentHeading}
                element="h3"
                size="subtitle"
              >
                {heading}
              </Type>
            )}
            {description && <Prose html={description} />}
          </div>
        </div>
        {type === 'tweet' && (
          <div
            className={styles.CommentSocialEmbded}
            data-commentary-type={type}
            data-commentary-social={socialId}
          >
            <SocialEmbed type={type} socialId={socialId} />
          </div>
        )}
      </div>
    )
  }
}

Comment.displayName = 'Comment'

Comment.propTypes = {
  a11yText: string,
  description: node,
  heading: string,
  icon: string,
  minute: number,
  socialId: string,
  theme: string,
  type: string
}

export default withTheme(Comment)
