import Immutable from 'immutable'
import pick from 'lodash.pick'

import {
  PROFILE_ERROR_RESET,
  PROFILE_REGISTER_SUBMIT,
  PROFILE_FORM_CHANGE,
  PROFILE_SUBMIT_FAILURE,
  PROFILE_UPDATE_SUBMIT,
  PROFILE_AUTHENTICATE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_DEAUTHENTICATE
} from './actions'

const initialState = Immutable.fromJS({
  data: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    password: null,
    dateOfBirth: null,
    newsletterConsent: null,
    promotionsConsent: null,
    termsAgreed: false
  },
  authenticated: false,
  user: null,
  loading: false,
  error: null,
  errorProperties: {}
})

const dataSchema = Object.keys(initialState.toJS().data)
const strip = data => pick(data, dataSchema)

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_ERROR_RESET:
      return state.merge({
        loading: false,
        error: null,
        errorProperties: {}
      })
    case PROFILE_REGISTER_SUBMIT:
      return state.merge({
        loading: true,
        error: null,
        errorProperties: {},
        authenticated: false
      })
    case PROFILE_SUBMIT_FAILURE:
      return state.merge({
        loading: false,
        error: action.error,
        errorProperties: action.errorProperties
      })
    case PROFILE_UPDATE_SUCCESS:
    case PROFILE_AUTHENTICATE_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        errorProperties: {},
        authenticated: true,
        user: action.user,
        data: strip(action.user)
      })
    case PROFILE_UPDATE_SUBMIT:
      return state.merge({
        loading: true,
        error: null,
        errorProperties: {}
      })
    case PROFILE_FORM_CHANGE:
      return state.mergeIn(['data'], strip(action.data))
    case PROFILE_DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}
