import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, number, string } from 'prop-types'

import withTheme from '../../../../Theme/withTheme'
import styles from './ResponsiveTableHeaderCell.styl'

import VisuallyHidden from '../../../VisuallyHidden'

export class ResponsiveTableHeaderCell extends PureComponent {
  render() {
    const { abbr, hideTitle, hideUntil, theme, title } = this.props

    return (
      <th
        scope="col"
        className={classNames(
          styles.ResponsiveTableHeaderCell,
          theme && styles[`ResponsiveTableHeaderCell-${theme}`],
          hideUntil && `hideUntil-${hideUntil}`
        )}
      >
        {/* TODO: Better logic here */}
        {hideTitle && (
          <VisuallyHidden>
            {title}
            {abbr && ` (${abbr})`}
          </VisuallyHidden>
        )}
        {!hideTitle && abbr && <abbr title={title}>{abbr}</abbr>}
        {!hideTitle && !abbr && title}
      </th>
    )
  }
}

ResponsiveTableHeaderCell.propTypes = {
  abbr: string,
  hideTitle: bool,
  hideUntil: number,
  theme: string,
  title: string.isRequired
}

export default withTheme(ResponsiveTableHeaderCell)
