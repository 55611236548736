import initSentry from './service/sentry/init'
import initAnalytics from './service/analytics/init'
// import initSiteLinkChecker from './service/site-link-checker/init'
import initNotificationHandler from './service/notification/init'
import initDeviceShim from './service/device-shim/init'
import initKeyboard from './service/keyboard/init'

export default async store => {
  initDeviceShim(window)
  initSentry(window)
  //  initSiteLinkChecker(store)
  initAnalytics(window, store)
  initNotificationHandler(store)
  initKeyboard(store)
}
