import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { routerMiddleware } from 'react-router-redux'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { createHashHistory as createHistory } from 'history'
import Immutable from 'immutable'
import immutableTransform from 'redux-persist-transform-immutable'
import installDevTools from 'immutable-devtools'
import createLogger from './logger'
import rootReducer from './reducer'
import createDebug from 'debug'

const debug = createDebug('store')

// TODO: if (process.env.__DEV__)
installDevTools(Immutable)

const persistConfig = {
  key: 'primary',
  version: 1,
  storage,
  transforms: [immutableTransform()],
  blacklist: ['wifi']
}
const history = createHistory()
const reducer = persistReducer(persistConfig, rootReducer)
const middleware = [
  thunkMiddleware,
  routerMiddleware(history),
  createLogger(window)
]

history.listen(ctx => {
  debug('history change', ctx.pathname)
  if (window.FirebasePlugin) {
    window.FirebasePlugin.logEvent('select_content', {
      content_type: 'page_view',
      item_id: ctx.pathname
    })
  }
})

export default () =>
  new Promise((resolve, reject) => {
    const store = createStore(
      reducer,
      undefined,
      compose(
        applyMiddleware(...middleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f
      )
    )
    if (module.hot) {
      module.hot.accept('./reducer.js', () => {
        store.replaceReducer(rootReducer)
      })
    }
    const persistor = persistStore(store, null, () =>
      resolve({ store, reducer, persistor })
    )
  })
