import React from 'react'

import MainTemplate from '../component/Primitive/MainTemplate'
import Section from '../component/Primitive/Section'
import TextAlign from '../component/Primitive/TextAlign'
import Spinner from '../component/Primitive/Spinner'

import './App/AppBase.styl'
import './App/AppTypography.styl'

const Loading = () => (
  <MainTemplate>
    <Section spacing="xy" grow center>
      <TextAlign center>
        <Spinner spinning revealDelay={2000} />
      </TextAlign>
    </Section>
  </MainTemplate>
)

export default Loading
