import React, { PureComponent } from 'react'
import { bool, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../../../Theme/withTheme'
import styles from './CustomRangeControl.styl'

import NativeRangeControl from '../NativeRangeControl'

export class CustomRangeControl extends PureComponent {
  render() {
    const { condensed, status, theme } = this.props
    const componentClassName = classNames(
      styles.CustomRangeControl,
      theme && styles[`CustomRangeControl-${theme}`],
      status && styles[status],
      condensed && styles.condensed
    )

    return <NativeRangeControl className={componentClassName} {...this.props} />
  }
}

CustomRangeControl.displayName = 'CustomRangeControl'

CustomRangeControl.propTypes = {
  condensed: bool,
  status: oneOf(['error', 'notice', 'success', 'warning']),
  theme: string
}

export default withTheme(CustomRangeControl)
