import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Tile from '../../../Primitive/Tile'

export class ShowTile extends PureComponent {
  render() {
    const { height, title, showId, imageUrl } = this.props
    return (
      <Tile
        heading={title}
        to={`/audio/show/${showId}`}
        ctaText="Listen now"
        imageUrl={imageUrl}
        height={height}
      />
    )
  }
}

ShowTile.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  showId: PropTypes.string,
  imageUrl: PropTypes.string
}

export default ShowTile
