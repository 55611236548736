exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResponsiveTableHeaderCell__ResponsiveTableHeaderCell___JXj6P{text-align:center;padding:16px 8px;font-variant-numeric:tabular-nums;vertical-align:baseline;border-bottom:1px solid;font-size:11px;line-height:16px;text-transform:uppercase;font-weight:700}.ResponsiveTableHeaderCell__ResponsiveTableHeaderCell___JXj6P:first-child{padding-left:24px}.ResponsiveTableHeaderCell__ResponsiveTableHeaderCell___JXj6P:last-child{padding-right:24px}.ResponsiveTableHeaderCell__ResponsiveTableHeaderCell___JXj6P abbr[title]{border-bottom:0}.ResponsiveTableHeaderCell__ResponsiveTableHeaderCell-gloucester___-Qmzc{background:#54585a;color:#fff;border-bottom:0}", ""]);

// exports
exports.locals = {
	"ResponsiveTableHeaderCell": "ResponsiveTableHeaderCell__ResponsiveTableHeaderCell___JXj6P",
	"ResponsiveTableHeaderCell-gloucester": "ResponsiveTableHeaderCell__ResponsiveTableHeaderCell-gloucester___-Qmzc"
};