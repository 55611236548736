import React from 'react'
import { instanceOf, oneOfType, string } from 'prop-types'
import Countdown from '../'

class CountdownContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTime: new Date()
    }

    this.targetTime =
      this.props.targetTime instanceof Date
        ? this.props.targetTime
        : new Date(this.props.targetTime)
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        currentTime: new Date()
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    return (
      <Countdown
        {...this.props}
        targetTime={this.targetTime}
        currentTime={this.state.currentTime}
      />
    )
  }
}

CountdownContainer.propTypes = {
  targetTime: oneOfType([string, instanceOf(Date)]).isRequired
}

export default CountdownContainer
