import React, { Fragment, PureComponent } from 'react'
import { arrayOf, object, func, number, string, bool } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import StepProgress from '../../../../../../component/Primitive/StepProgress'
import QuizQuestion from '../../../../../../component/Primitive/QuizQuestion'
import QuizResult from '../../../../../../component/Primitive/QuizResult'
import Prose from '../../../../../../component/Primitive/Prose'
import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import Expander from '../../../../../../component/Primitive/Expander'
import FormattedDate from 'src/component/Primitive/FormattedDate'

class Questions extends PureComponent {
  constructor(...args) {
    super(...args)
    const questions = this.props.questions || []
    this.state = {
      questions,
      complete: this.props.complete || false,
      currentQuestionIndex: 0,
      userAnswers: [
        ...questions.map((_, i) => (i === 0 ? 'current' : 'incomplete'))
      ]
    }
    this.handleAnswer = this.handleAnswer.bind(this)
    this.handleNextQuestion = this.handleNextQuestion.bind(this)
  }

  componentDidMount() {
    const age = Date.now() - this.props.lastFetch
    if (age > 36000e3) this.props.fetchQuiz(this.props.primus)
  }

  componentWillReceiveProps({ questions }) {
    this.setState({
      questions,
      complete: this.props.complete || false,
      currentQuestionIndex: 0,
      userAnswers: [
        ...questions.map((_, i) => (i === 0 ? 'current' : 'incomplete'))
      ]
    })
  }

  handleAnswer(answer) {
    const userAnswers = [...this.state.userAnswers]
    userAnswers[this.state.currentQuestionIndex] = answer
      ? 'positive'
      : 'negative'
    this.setState({
      userAnswers
    })
    window.analytics.log('quiz', {
      stage: 'question',
      number: this.state.currentQuestionIndex + 1
    })
  }

  handleNextQuestion() {
    const userAnswers = [...this.state.userAnswers]
    const nextQuestionIndex = this.state.currentQuestionIndex + 1
    const complete = nextQuestionIndex === this.state.questions.length
    if (!complete) userAnswers[nextQuestionIndex] = 'current'
    this.setState(
      {
        currentQuestionIndex: nextQuestionIndex,
        complete,
        userAnswers
      },
      () => {
        if (complete) this.handleComplete()
      }
    )
  }

  handleComplete() {
    const total = this.state.userAnswers.length
    const correct = this.state.userAnswers.filter(
      answer => answer === 'positive'
    ).length
    const score = correct / total
    window.analytics.log('quiz', { stage: 'complete', score })
  }

  render() {
    const currentQuestion = this.state.questions[
      this.state.currentQuestionIndex
    ]

    const correctCount = this.state.userAnswers.filter(
      answer => answer === 'positive'
    ).length
    const questionCount = this.state.questions.length

    return (
      <Fragment>
        <Section spacing="xt">
          <SpacedList>
            <Type element="h1" size="display2">
              {this.props.fullTitle}
            </Type>
            <StepProgress steps={this.state.userAnswers} />
          </SpacedList>
        </Section>
        {!this.state.complete && currentQuestion && (
          <Section grow>
            <QuizQuestion
              handleSubmit={this.handleAnswer}
              handleComplete={this.handleNextQuestion}
              heading={currentQuestion.heading}
              question={currentQuestion.question}
              answers={currentQuestion.answers}
              completeCtaText={
                this.state.currentQuestionIndex + 1 ===
                this.state.questions.length
                  ? 'View Results'
                  : 'Next Question'
              }
            />
          </Section>
        )}
        {this.state.complete && (
          <Fragment>
            <Section grow spacing="xt">
              <QuizResult total={questionCount} correct={correctCount} />
            </Section>
            <Section spacing="xy">
              <SpacedList>
                <Type element="h2" size="base">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.props.enterMessage
                    }}
                  />
                </Type>
                <ButtonDisplay block to="/activity/play/quiz/capture">
                  Enter Now
                </ButtonDisplay>
                <Prose>
                  <p>
                    Closes{' '}
                    <strong>
                      <FormattedDate date={this.props.closeDate} />
                    </strong>
                  </p>
                  <Expander
                    labelClosed={'Terms and Conditions'}
                    labelOpen={'Hide'}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.props.terms
                      }}
                    />
                  </Expander>
                </Prose>
              </SpacedList>
            </Section>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
Questions.defaultProps = {
  questions: []
}
Questions.propTypes = {
  lastFetch: number,
  fullTitle: string.isRequired,
  questions: arrayOf(object),
  fetchQuiz: func,
  closeDate: string,
  terms: string,
  enterMessage: string,
  primus: object,
  complete: bool
}

export default Questions
