import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, node } from 'prop-types'

import styles from './OverlayTemplate.styl'

class OverlayTemplate extends PureComponent {
  render() {
    const { children, noReveal } = this.props

    return (
      <div
        className={classNames(
          styles.OverlayTemplate,
          noReveal && styles.noReveal
        )}
      >
        {children}
      </div>
    )
  }
}

OverlayTemplate.displayName = 'OverlayTemplate'

OverlayTemplate.propTypes = {
  children: node.isRequired,
  noReveal: bool
}

export default OverlayTemplate
