exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextAlign__TextAlign___3VjV9{border:red}.TextAlign__left___2UnPr{text-align:left}.TextAlign__center___2Di6h{text-align:center}.TextAlign__right___1Eehe{text-align:right}", ""]);

// exports
exports.locals = {
	"TextAlign": "TextAlign__TextAlign___3VjV9",
	"left": "TextAlign__left___2UnPr",
	"center": "TextAlign__center___2Di6h",
	"right": "TextAlign__right___1Eehe"
};