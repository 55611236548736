import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Episode from '../Component/Episode'

class EpisodeContainer extends PureComponent {
  render() {
    return (
      <Episode episode={this.props.episode} number={this.props.episodeNumber} />
    )
  }
}

EpisodeContainer.propTypes = {
  episode: PropTypes.object,
  episodeNumber: PropTypes.number
}

const mapStateToProps = (state, ownProps) => {
  const episodeId = ownProps.match.params.episodeId
  const showState = state.shows.toJS()
  let episodeNumber = null
  const episode = showState.episodes.find((episode, index) => {
    const found = episode._id === episodeId
    if (found) {
      episodeNumber = showState.episodes.length - index
    }
    return found
  })
  return { ...ownProps, episode, episodeNumber }
}
export default connect(mapStateToProps)(EpisodeContainer)
