const schemata = require('schemata')
const required = require('validity-required')
const isEmail = require('validity-email')

module.exports = () =>
  schemata({
    _id: {
      type: String
    },
    account: {
      type: String
    },
    name: {
      type: String,
      validators: [required]
    },
    emailAddress: {
      type: String,
      validators: [required, isEmail]
    },
    message: {
      type: String
    },
    images: {
      type: Object,
      validators: [required]
    },
    dateSubmitted: {
      type: Date,
      defaultValue: () => new Date()
    }
  })
