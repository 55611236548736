import React from 'react'

import ButtonDisplay from '../../../component/Primitive/ButtonDisplay'
import Icon from '../../../component/Primitive/Icon'
import Margin from '../../../component/Primitive/Margin'
import MinimalLayout from '../../../component/Layout/MinimalLayout'
import Prose from '../../../component/Primitive/Prose'
import ScrollArea from '../../../component/Primitive/ScrollArea'
import Section from '../../../component/Primitive/Section'
import Separator from '../../../component/Primitive/Separator'
import SmartLink from '../../../component/Primitive/SmartLink'
import SpacedList from '../../../component/Primitive/SpacedList'
import TextAlign from '../../../component/Primitive/TextAlign'
import Type from '../../../component/Primitive/Type'

class Data extends React.Component {
  render() {
    return (
      <MinimalLayout primary>
        <ScrollArea>
          <Section spacing="xy" center grow>
            <TextAlign center>
              <Icon type="CheckCircle" height={96} />
              <Type element="h1" size="title">
                That’s great
              </Type>
              <Margin my={3}>
                <Separator />
              </Margin>
              <Prose>
                <p>
                  As you use more of the apps features, you may be prompted for
                  additional permissions, such as camera or location services.
                  We will let you know how we safely use your data on each
                  occasion.
                </p>
              </Prose>
            </TextAlign>
          </Section>
          <Section spacing="xy">
            <TextAlign center>
              <SpacedList>
                <ButtonDisplay block to="/profile/register">
                  Create an account
                </ButtonDisplay>
                <ButtonDisplay outline block to="/featured">
                  Skip
                </ButtonDisplay>
                <Margin mt={2}>
                  <SmartLink to="/profile/login">
                    Login to existing account
                  </SmartLink>
                </Margin>
              </SpacedList>
            </TextAlign>
          </Section>
        </ScrollArea>
      </MinimalLayout>
    )
  }
}

export default Data
