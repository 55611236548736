import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import onComponentDidMount from '../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../component/Primus/with-primus'
import Form from '../component/Login'

const mapStateToProps = state => state.profile.toJS()
const mapDispatchToProps = (dispatch, ownProps) => ({
  onAuthenticated: () => dispatch(push('/profile'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPrimus(
    onComponentDidMount(({ onAuthenticated, authenticated }) => {
      if (authenticated) return onAuthenticated()
    }, Form)
  )
)
