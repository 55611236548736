import React, { PureComponent } from 'react'
import { bool, node } from 'prop-types'

import MinimalTemplate from '../../../component/Primitive/MinimalTemplate'

class MinimalLayout extends PureComponent {
  render() {
    const { children, header, primary } = this.props

    return (
      <MinimalTemplate header={header} primary={primary}>
        {children}
      </MinimalTemplate>
    )
  }
}

MinimalLayout.propTypes = {
  children: node,
  header: node,
  primary: bool
}

export default MinimalLayout
