import React, { PureComponent } from 'react'
import { bool, node, number, string } from 'prop-types'
import withTheme from '../../Theme/withTheme'
import classNames from 'classnames'
import styles from './CrestLockup.styl'
import Type from '../Type'

class CrestLockup extends PureComponent {
  render() {
    const {
      active,
      awayCrest,
      awayScore,
      homeCrest,
      homeScore,
      large,
      text,
      theme
    } = this.props

    return (
      <div
        className={classNames(
          styles.CrestLockup,
          active && styles.active,
          large && styles.large
        )}
      >
        {homeCrest}
        {homeScore >= 0 && (
          <Type className={styles.CrestLockupScore} size="title" element="div">
            {homeScore}
          </Type>
        )}
        <Type
          className={classNames(
            styles.CrestLockupSeparator,
            theme && styles[`CrestLockupSeparator-${theme}`]
          )}
          size="subtitle"
          element="div"
        >
          {text}
        </Type>
        {awayScore >= 0 && (
          <Type className={styles.CrestLockupScore} size="title" element="div">
            {awayScore}
          </Type>
        )}
        {awayCrest}
      </div>
    )
  }
}

CrestLockup.displayName = 'CrestLockup'

CrestLockup.defaultProps = {
  text: 'vs'
}

CrestLockup.propTypes = {
  active: bool,
  awayCrest: node.isRequired,
  awayScore: number,
  homeCrest: node.isRequired,
  homeScore: number,
  large: bool,
  text: string,
  theme: string
}

export default withTheme(CrestLockup)
