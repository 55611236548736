import { connect } from 'react-redux'

import onComponentDidMount from '../../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../../component/Primus/with-primus'

import Review from './Component/Review'
import { fetchMatchReviews } from './actions'

const mapStateToProps = state => ({
  match: state.matches.toJS().last,
  ...state.matchReviews.toJS()
})
const mapDispatchToProps = dispatch => ({
  fetchMatchReviews: (primus, match) =>
    dispatch(
      fetchMatchReviews(primus, {
        matchId: match._id,
        kickOff: match.dateTime
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPrimus(
    onComponentDidMount(
      ({ fetchMatchReviews, primus, match }) =>
        fetchMatchReviews(primus, match),
      Review
    )
  )
)
