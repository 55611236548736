import React, { Fragment } from 'react'

import Type from '../component/Primitive/Type'
import Section from '../component/Primitive/Section'

const NotFoundPage = () => (
  <Fragment>
    <Section spacing="xy">
      <Type element="h1" size="display2">
        Content not found
      </Type>
    </Section>
  </Fragment>
)

export default NotFoundPage
