import React, { PureComponent } from 'react'
import { bool, string, func } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './QuizQuestion.styl'

import Icon from '../Icon'
import ShrinkWrap, { ShrinkWrapItem } from '../ShrinkWrap'

export class QuizQuestionAnswer extends PureComponent {
  render() {
    const { text, value, correct, selected, disabled } = this.props
    return (
      <li
        className={classNames(
          styles.QuizQuestionAnswer,
          selected && styles.selected,
          disabled && selected && !correct && styles.negative,
          disabled && correct && styles.positive
        )}
      >
        <label className={styles.QuizQuestionLabel}>
          <input
            type="radio"
            name="QuizQuestion"
            className={styles.QuizQuestionNativeControl}
            value={value}
            checked={selected}
            disabled={disabled}
            onChange={this.props.onClick}
          />
          <ShrinkWrap fullwidth>
            <ShrinkWrapItem>{text}</ShrinkWrapItem>
            <ShrinkWrapItem shrink>
              <div className={styles.QuizQuestionIndicator}>
                <Icon type="CheckBoxOutline" />
                <div className={styles.QuizQuestionIndicatorActive}>
                  <Icon type="CheckBox" />
                </div>
              </div>
            </ShrinkWrapItem>
          </ShrinkWrap>
        </label>
      </li>
    )
  }
}

QuizQuestionAnswer.displayName = 'QuizQuestionAnswer'

QuizQuestionAnswer.propTypes = {
  text: string.isRequired,
  correct: bool,
  value: string,
  selected: bool,
  disabled: bool,
  onClick: func
}

export default withTheme(QuizQuestionAnswer)
