import React from 'react'
import { array, func, object, string } from 'prop-types'

import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Type from '../../../../../../component/Primitive/Type'
import Section from '../../../../../../component/Primitive/Section'
import ResultItem from '../../../../../../component/Primitive/ResultItem'
import ResultStat from '../../../../../../component/Primitive/ResultStat'
import Margin from '../../../../../../component/Primitive/Margin'

class MotmVoted extends React.Component {
  constructor(props) {
    super(props)
    this.primus = props.primus
  }

  componentDidMount() {
    this.primus.on('motm:vote', this.props.updateVotes)
    this.props.fetchAllVotes(this.primus)
  }

  componentWillUnmount() {
    this.primus.off('motm:vote', this.props.updateVotes)
  }

  render() {
    const { myVote, voteLeaders } = this.props

    return (
      <ScrollArea>
        <Section spacing="xy">
          <Type element="h1" size="display2">
            Live Results
          </Type>
        </Section>
        <Section>
          {voteLeaders
            .filter(player => player.voteCount > 0)
            .map(player => (
              <ResultItem
                key={`ResultItem${player._id}`}
                text={`${player.firstName} ${player.lastName}`}
                description={`${player.position}`}
                imageUrl={player.imgSrc}
                selected={myVote === player._id}
              >
                <Margin mt={1}>
                  <ResultStat>{player.votePercent}%</ResultStat>
                </Margin>
              </ResultItem>
            ))}
        </Section>
      </ScrollArea>
    )
  }
}

MotmVoted.propTypes = {
  myVote: string,
  voteLeaders: array.isRequired,
  updateVotes: func.isRequired,
  fetchAllVotes: func.isRequired,
  primus: object.isRequired
}

export default MotmVoted
