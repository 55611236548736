import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './MainTemplate.styl'

class MainTemplate extends PureComponent {
  render() {
    const { children, header, footer, theme } = this.props
    return (
      <div
        className={classNames(
          styles.MainTemplate,
          theme && styles[`MainTemplate-${theme}`]
        )}
      >
        {!!header && <div className={styles.MainTemplateHeader}>{header}</div>}
        <div className={styles.MainTemplateBody}>{children}</div>
        {!!footer && <div className={styles.MainTemplateFooter}>{footer}</div>}
      </div>
    )
  }
}

MainTemplate.displayName = 'MainTemplate'

MainTemplate.propTypes = {
  children: node.isRequired,
  header: node,
  footer: node,
  theme: string
}

export default withTheme(MainTemplate)
