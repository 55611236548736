import React, { PureComponent } from 'react'
import { instanceOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Countdown.styl'

import CountdownItem from './CountdownItem'

const getCountdownValues = (currentTime, targetTime) => {
  currentTime = currentTime || new Date()
  let seconds = 0
  if (currentTime.getTime() < targetTime.getTime()) {
    seconds = Math.round((targetTime.getTime() - currentTime.getTime()) / 1000)
  }
  const days = Math.floor(seconds / 86400)
  seconds -= days * 86400
  const hours = Math.floor(seconds / 3600) % 24
  seconds -= hours * 3600
  const minutes = Math.floor(seconds / 60) % 60
  seconds -= minutes * 60
  return { days, hours, minutes, seconds }
}

export class Countdown extends PureComponent {
  render() {
    const { currentTime, targetTime, theme } = this.props
    const { days, hours, minutes, seconds } = getCountdownValues(
      currentTime,
      targetTime
    )
    return (
      <div
        className={classNames(
          styles.Countdown,
          theme && styles[`Countdown-${theme}`]
        )}
      >
        <CountdownItem
          label="Day"
          value={days}
          pluralise
          padValue={2}
          hideEmpty
        />
        <CountdownItem
          label="Hour"
          value={hours}
          pluralise
          padValue={2}
          hideEmpty={days === 0}
        />
        <CountdownItem label="Min" value={minutes} pluralise padValue={2} />
        <CountdownItem
          label="Sec"
          value={seconds}
          pluralise
          padValue={2}
          hide={days > 0}
        />
      </div>
    )
  }
}

Countdown.displayName = 'Countdown'

Countdown.propTypes = {
  currentTime: instanceOf(Date),
  targetTime: instanceOf(Date).isRequired,
  theme: string
}

export default withTheme(Countdown)
