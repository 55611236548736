import React, { Fragment } from 'react'
import { func, string, object, bool } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import Notification from '../../../../../../component/Primitive/Notification'
import Prose from '../../../../../../component/Primitive/Prose'
import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import FieldTemplate from '../../../../../../component/Primitive/FieldTemplate'
import CheckControl from '../../../../../../component/Primitive/Control/CheckControl'
import Form from '../../../../../../component/Primitive/Form'
import TextControl from '../../../../../../component/Primitive/Control/TextControl'
import ConsentOptions from '../../../../../Profile/component/ContentOptions'
import Expander from '../../../../../../component/Primitive/Expander'
import Redirect from '../../../../../../component/Helper/Redirect'

const CaptureForm = ({
  onChange,
  onSubmit,
  fullTitle,
  successfulCreate,
  terms,
  error,
  errorProperties,
  data,
  primus,
  authenticated
}) => (
  <Fragment>
    {successfulCreate ? (
      <Redirect to="/activity/play/quiz/thanks" />
    ) : (
      <Section spacing="xy">
        <SpacedList>
          <Type element="h1" size="display2">
            {fullTitle}
          </Type>
          <Prose>
            <p>
              Please enter your details so we can contact you if you are
              selected as a winner.
            </p>
          </Prose>
          {error && (
            <Notification icon="Error" status="error">
              {error}
            </Notification>
          )}

          <Form onSubmit={e => onSubmit(e, primus)}>
            {!authenticated && (
              <Fragment>
                <FieldTemplate
                  controlName="emailAddress"
                  label="Email Address"
                  status={errorProperties.emailAddress && 'error'}
                  feedback={errorProperties.emailAddress}
                  required
                >
                  <TextControl
                    name="emailAddress"
                    type="email"
                    autoComplete="email"
                    status={errorProperties.emailAddress && 'error'}
                    onChange={onChange}
                    value={data.emailAddress}
                    required
                  />
                </FieldTemplate>
                <FieldTemplate
                  controlName="phone"
                  label="Phone number (optional)"
                  status={errorProperties.phone && 'error'}
                  feedback={errorProperties.phone}
                >
                  <TextControl
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    status={errorProperties.phone && 'error'}
                    onChange={onChange}
                    value={data.phone}
                  />
                </FieldTemplate>
                <ConsentOptions
                  data={data}
                  onChange={onChange}
                  errorProperties={errorProperties}
                />
              </Fragment>
            )}
            <FieldTemplate
              controlName="termsAgreed"
              template="check"
              required
              status={errorProperties.termsAgreed && 'error'}
              feedback={errorProperties.termsAgreed}
            >
              <CheckControl
                type="checkbox"
                name="termsAgreed"
                value="termsAgreed"
                checked={!!data.termsAgreed}
                status={errorProperties.termsAgreed && 'error'}
                onChange={onChange}
              >
                <Prose>I agree to the Terms and conditions</Prose>
              </CheckControl>
            </FieldTemplate>

            <ButtonDisplay type="submit" block>
              Enter Now
            </ButtonDisplay>
          </Form>
        </SpacedList>
        <Expander labelClosed="Terms and Conditions" labelOpen="Hide">
          {terms}
        </Expander>
      </Section>
    )}
  </Fragment>
)

CaptureForm.propTypes = {
  authenticated: bool,
  successfulCreate: bool,
  fullTitle: string,
  terms: string,
  onChange: func,
  onSubmit: func,
  error: string,
  errorProperties: object,
  data: object,
  primus: object
}

export default CaptureForm
