import React, { PureComponent } from 'react'
import { object, array, string } from 'prop-types'

import Crest from '../../../../../../../component/Primitive/Crest'
import Profile from '../../../../../../../component/Primitive/Profile'
import ProfileList from '../../../../../../../component/Primitive/ProfileList'
import ScrollArea from '../../../../../../../component/Primitive/ScrollArea'
import Section from '../../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../../component/Primitive/SpacedList'
import Square from '../../../../../../../component/Primitive/Square'
import Type from '../../../../../../../component/Primitive/Type'

const LineupItem = ({ type, player }) =>
  player.imageUrl ? (
    <Profile
      key={`type${type.fullName}`}
      imageSrc={player.imageUrl}
      imageRatio={6 / 5}
      heading={player.fullName}
      description={player.positionName}
      imageAddon={<Square>{player.position}</Square>}
    />
  ) : (
    <Profile
      key={`type${type.fullName}`}
      template="mini"
      heading={player.fullName}
      description={player.positionName}
      imageAddon={<Square>{player.position}</Square>}
      contentAddon={<Square>{player.position}</Square>}
    />
  )

LineupItem.propTypes = {
  player: object,
  type: string
}

class Team extends PureComponent {
  render() {
    const { homeTeam, awayTeam, homeLineUp, awayLineUp } = this.props

    return (
      <ScrollArea>
        <Section spacing="xy">
          <SpacedList>
            <Type element="h1" size="display2">
              Team Lineup
            </Type>
            <Crest
              club={homeTeam.name}
              a11yText={homeTeam.displayName}
              constrain={48}
            />
          </SpacedList>
        </Section>
        {homeLineUp.length < 1 && (
          <Section spacing="x">
            <p>Not yet announced</p>
          </Section>
        )}
        {homeLineUp.length > 0 && (
          <Section>
            <ProfileList>
              {homeLineUp.map((player, i) => (
                <LineupItem player={player} type="homeLineup" />
              ))}
            </ProfileList>
          </Section>
        )}
        <Section spacing="xt">
          <Crest club={awayTeam.name} a11yText={awayTeam.name} constrain={48} />
        </Section>
        {awayLineUp.length < 1 && (
          <Section spacing="xy">
            <p>Not yet announced</p>
          </Section>
        )}
        {awayLineUp.length > 0 && (
          <Section spacing="y">
            <ProfileList template="mini">
              {awayLineUp.map((player, i) => (
                <LineupItem player={player} type="awayLineup" />
              ))}
            </ProfileList>
          </Section>
        )}
      </ScrollArea>
    )
  }
}

Team.propTypes = {
  homeTeam: object.isRequired,
  awayTeam: object.isRequired,
  homeLineUp: array.isRequired,
  awayLineUp: array.isRequired
}

export default Team
