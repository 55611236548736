exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Section__Section___3aIKq{width:100%}.Section__grow___3b6hK{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.Section__grow___3b6hK .Section__grow___3b6hK{-ms-flex-negative:0;flex-shrink:0}.Section__center___2VUaP{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.Section__fullHeight___20lQF{height:100%}.Section__x___2Lizw{padding-left:24px;padding-right:24px}.Section__y___32FAD{padding-top:24px;padding-bottom:24px}.Section__xy___aEYpC{padding:24px}.Section__xt___22zVQ{padding:24px 24px 0}", ""]);

// exports
exports.locals = {
	"Section": "Section__Section___3aIKq",
	"grow": "Section__grow___3b6hK",
	"center": "Section__center___2VUaP",
	"fullHeight": "Section__fullHeight___20lQF",
	"x": "Section__x___2Lizw",
	"y": "Section__y___32FAD",
	"xy": "Section__xy___aEYpC",
	"xt": "Section__xt___22zVQ"
};