import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Hero.styl'

import ResponsiveMedia from '../ResponsiveMedia'
import Type from '../Type'

export class Hero extends PureComponent {
  render() {
    const { category, heading, imageUrl, theme } = this.props

    return (
      <div
        className={classNames(styles.Hero, theme && styles[`Hero-${theme}`])}
      >
        <div className={styles.HeroImage}>
          <ResponsiveMedia ratio={9 / 16}>
            <img src={imageUrl} />
          </ResponsiveMedia>
        </div>
        <div className={styles.HeroContent}>
          <Type element="h3" size="title" className={styles.HeroHeading}>
            {heading}
          </Type>
          <Type size="tiny">{category}</Type>
        </div>
      </div>
    )
  }
}

Hero.displayName = 'Hero'

Hero.propTypes = {
  category: string.isRequired,
  heading: node.isRequired,
  imageUrl: string.isRequired,
  theme: string
}

export default withTheme(Hero)
