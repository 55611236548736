import React, { PureComponent } from 'react'
import { node, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './ProfileList.styl'

import ScrollArea from '../ScrollArea'

class ProfileList extends PureComponent {
  render() {
    const { children, template } = this.props

    return (
      <div
        className={classNames(
          styles.ProfileList,
          template === 'mini' && styles.mini
        )}
      >
        <ScrollArea axis="x">
          <div className={styles.ProfileListContent}>{children}</div>
        </ScrollArea>
      </div>
    )
  }
}

ProfileList.displayName = 'ProfileList'

ProfileList.propTypes = {
  children: node,
  template: oneOf(['mini'])
}

export default ProfileList
