import React, { PureComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout/index'
import AnimatedRoute from '../../../../../../component/Primitive/AnimatedRoute'
import ArticleList from './Articles'
import Article from './Article'

class Articles extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <AnimatedRoute>
          {location => (
            <Switch location={location}>
              <Route
                exact
                path="/activity/home/news"
                render={props => <ArticleList {...props} />}
              />
              <Route
                exact
                path="/activity/home/news/article/:slug"
                render={props => <Article {...props} />}
              />
            </Switch>
          )}
        </AnimatedRoute>
      </OverlayLayout>
    )
  }
}

export default Articles
