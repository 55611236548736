import { connect } from 'react-redux'
import Quiz from './Component/Quiz'
import { fetchQuiz, initQuiz } from './actions'
import withPrimus from '../../../../../component/Primus/with-primus'

const mapDispatchToProps = dispatch => ({
  fetchQuiz: primus => dispatch(fetchQuiz(primus)),
  initQuiz: () => dispatch(initQuiz())
})

export default connect(
  state => state.quiz.toJS(),
  mapDispatchToProps
)(withPrimus(Quiz))
