import React, { PureComponent } from 'react'
import { string } from 'prop-types'

import styles from './InlineImage.styl'

import ResponsiveMedia from '../ResponsiveMedia'
import Type from '../Type'

export class InlineImage extends PureComponent {
  render() {
    const { alt, caption, imageUrl } = this.props

    return (
      <div className={styles.InlineImage}>
        <div className={styles.InlineImageImage}>
          <ResponsiveMedia ratio={9 / 16}>
            <img src={imageUrl} alt={alt} />
          </ResponsiveMedia>
        </div>
        {caption && (
          <div className={styles.InlineImageCaption}>
            <Type size="tiny">{caption}</Type>
          </div>
        )}
      </div>
    )
  }
}

InlineImage.displayName = 'InlineImage'

InlineImage.propTypes = {
  alt: string.isRequired,
  caption: string,
  imageUrl: string.isRequired
}

export default InlineImage
