exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CrestLockup__CrestLockup___1grY4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.CrestLockup__CrestLockupScore___hWKf4{min-width:64px;text-align:center;line-height:1}.CrestLockup__CrestLockupSeparator___1J7ue{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;text-align:center;padding-left:16px;padding-right:16px;vertical-align:middle;font-size:11px;line-height:16px;text-transform:uppercase;font-weight:700}.CrestLockup__large___3mVv4 .CrestLockup__CrestLockupSeparator___1J7ue{font-size:20px;line-height:32px;font-weight:900;text-transform:uppercase}.CrestLockup__active___1BaF- .CrestLockup__CrestLockupSeparator-base___d4ddQ{color:#00cfdd}.CrestLockup__active___1BaF- .CrestLockup__CrestLockupSeparator-gloucester___3h6Rv{color:#da1a32}.CrestLockup__active___1BaF- .CrestLockup__CrestLockupSeparator-inverse___1WTdm{color:#ff9800}", ""]);

// exports
exports.locals = {
	"CrestLockup": "CrestLockup__CrestLockup___1grY4",
	"CrestLockupScore": "CrestLockup__CrestLockupScore___hWKf4",
	"CrestLockupSeparator": "CrestLockup__CrestLockupSeparator___1J7ue",
	"large": "CrestLockup__large___3mVv4",
	"active": "CrestLockup__active___1BaF-",
	"CrestLockupSeparator-base": "CrestLockup__CrestLockupSeparator-base___d4ddQ",
	"CrestLockupSeparator-gloucester": "CrestLockup__CrestLockupSeparator-gloucester___3h6Rv",
	"CrestLockupSeparator-inverse": "CrestLockup__CrestLockupSeparator-inverse___1WTdm"
};