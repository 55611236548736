import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import styles from './ShrinkWrap.styl'

export class ShrinkWrapItem extends PureComponent {
  render() {
    const { children, shrink } = this.props
    return (
      <span
        className={classNames(styles.ShrinkWrapItem, shrink && styles.shrink)}
      >
        {children}
      </span>
    )
  }
}

ShrinkWrapItem.displayName = 'ShrinkWrapItem'

ShrinkWrapItem.propTypes = {
  children: node.isRequired,
  shrink: bool
}

class ShrinkWrap extends PureComponent {
  render() {
    const { children, element, fullwidth, vAlign } = this.props
    const ShrinkWrapEl = element || 'div'

    return (
      <ShrinkWrapEl
        className={classNames(
          styles.ShrinkWrap,
          fullwidth && styles.fullwidth,
          vAlign && styles[vAlign]
        )}
      >
        {children}
      </ShrinkWrapEl>
    )
  }
}

ShrinkWrap.displayName = 'ShrinkWrap'

ShrinkWrap.propTypes = {
  children: node.isRequired,
  element: string,
  fullwidth: bool,
  vAlign: oneOf(['top', 'middle', 'bottom'])
}

export default ShrinkWrap
