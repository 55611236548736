exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SummaryFeatured__SummaryFeaturedContent___2ut2F{padding:12px 16px 20px;margin-top:-48px;-webkit-transform:translateX(8px);-ms-transform:translateX(8px);transform:translateX(8px)}.SummaryFeatured__SummaryFeaturedHeading___NSyGw{margin-bottom:8px}.SummaryFeatured__SummaryFeatured-base___qkAgX .SummaryFeatured__SummaryFeaturedContent___2ut2F{background-color:#00cfdd;color:#fff}.SummaryFeatured__SummaryFeatured-gloucester___30-ug .SummaryFeatured__SummaryFeaturedContent___2ut2F{background-color:#da1a32;color:#fff}.SummaryFeatured__SummaryFeatured-inverse___mPKle .SummaryFeatured__SummaryFeaturedContent___2ut2F{background-color:#ff9800;color:#000}", ""]);

// exports
exports.locals = {
	"SummaryFeaturedContent": "SummaryFeatured__SummaryFeaturedContent___2ut2F",
	"SummaryFeaturedHeading": "SummaryFeatured__SummaryFeaturedHeading___NSyGw",
	"SummaryFeatured-base": "SummaryFeatured__SummaryFeatured-base___qkAgX",
	"SummaryFeatured-gloucester": "SummaryFeatured__SummaryFeatured-gloucester___30-ug",
	"SummaryFeatured-inverse": "SummaryFeatured__SummaryFeatured-inverse___mPKle"
};