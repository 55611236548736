import createCrudReducer from '../../../lib/crud-reducer'
import reduceReducers from 'reduce-reducers'
import getImage from '../../../lib/get-image'

const addImages = (crop, data) => ({
  ...data,
  imageUrl: getImage(data.images.widgets, 'Mobile App Thumbnail', crop, 900)
})

const fetchShowsReducer = createCrudReducer({
  prefix: 'SHOWS',
  fetchMapper: data => ({ shows: data.map(addImages.bind(null, 'Portrait')) })
})

const fetchShowWithEpisodesReducer = createCrudReducer({
  prefix: 'EPISODES',
  fetchMapper: data => ({
    episodes: data.map(addImages.bind(null, 'Landscape'))
  })
})

export default reduceReducers(fetchShowsReducer, fetchShowWithEpisodesReducer)
