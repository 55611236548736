export default (bufferSize = 16384) => ({
  buffer: new Float32Array(0),
  write(newAudio) {
    if (this.buffer.length > bufferSize) {
      this.buffer = new Float32Array(0)
      console.log('Clearing audio queue')
    }
    const newBuffer = new Float32Array(this.buffer.length + newAudio.length)
    newBuffer.set(this.buffer, 0)
    newBuffer.set(newAudio, this.buffer.length)
    this.buffer = newBuffer
  },

  read(nSamples) {
    const samplesToPlay = this.buffer.subarray(0, nSamples)
    this.buffer = this.buffer.subarray(nSamples, this.buffer.length)
    return samplesToPlay
  },

  length() {
    return this.buffer.length
  }
})
