import React from 'react'

import Type from '../../../../../../component/Primitive/Type'
import Section from '../../../../../../component/Primitive/Section'
import Prose from '../../../../../../component/Primitive/Prose'

const MotmInactive = () => (
  <Section grow>
    <Section spacing="xt">
      <Type element="h1" size="display2">
        Stowford&nbsp;Press Man&nbsp;of&nbsp;the&nbsp;Match
      </Type>
    </Section>
    <Section spacing="xy" grow>
      <Prose>
        <h3>There are no upcoming matches for you to vote on.</h3>
        <p>Come back on match-day to vote for your man of the match.</p>
      </Prose>
    </Section>
  </Section>
)

export default MotmInactive
