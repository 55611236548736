exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QuizResult__QuizResultScore___dm3pZ{color:#4caf50;font-size:72px;line-height:72px;font-weight:700}", ""]);

// exports
exports.locals = {
	"QuizResultScore": "QuizResult__QuizResultScore___dm3pZ"
};