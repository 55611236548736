import { connect } from 'react-redux'

import { enterCompetition, change, resetError } from '../actions'
import Form from '../Component/CaptureForm'
import withPrimus from '../../../../../../component/Primus/with-primus'

const mapStateToProps = state => state.quiz.toJS()
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (e, primus) => {
    e.preventDefault()
    dispatch(resetError())
    dispatch(enterCompetition(primus))
  },
  onChange: e =>
    dispatch(
      change({
        [e.currentTarget.name]:
          e.currentTarget.type === 'checkbox'
            ? e.currentTarget.checked
            : e.currentTarget.value
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(Form))
