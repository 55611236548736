exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonDisplay__ButtonDisplay___1KIje{font-size:20px;line-height:32px;font-weight:900;text-transform:uppercase;padding:16px;line-height:24px;position:relative;overflow:hidden}.ButtonDisplay__outline___1xkG9{padding:15px;border:1px solid}.ButtonDisplay__ButtonDisplay-base___1QNwX{color:#fff;background-color:#00cfdd}.ButtonDisplay__ButtonDisplay-base___1QNwX:focus,.ButtonDisplay__ButtonDisplay-base___1QNwX:hover{color:#fff;background-color:#1976d2}.ButtonDisplay__ButtonDisplay-base___1QNwX:active{background-color:#7ed0e3}.ButtonDisplay__outline-base___3mfpp{color:inherit;background-color:rgba(0,0,0,0)}.ButtonDisplay__reverse-base___3q5GA{color:#00cfdd;background-color:#fff}.ButtonDisplay__ButtonDisplay-gloucester___zBf4m{color:#fff;background-color:#54585a}.ButtonDisplay__ButtonDisplay-gloucester___zBf4m:active,.ButtonDisplay__ButtonDisplay-gloucester___zBf4m:focus,.ButtonDisplay__ButtonDisplay-gloucester___zBf4m:hover{color:#fff;background-color:#434648}.ButtonDisplay__outline-gloucester___2jKHV{color:inherit;background-color:rgba(0,0,0,0)}.ButtonDisplay__reverse-gloucester___26stQ{color:#54585a;background-color:#fff}.ButtonDisplay__ButtonDisplay-inverse___2k32S{color:#000;background-color:#ff9800}.ButtonDisplay__ButtonDisplay-inverse___2k32S:focus,.ButtonDisplay__ButtonDisplay-inverse___2k32S:hover{color:#000;background-color:#ffb74d}.ButtonDisplay__ButtonDisplay-inverse___2k32S:active{background-color:#fb8c00}.ButtonDisplay__outline-inverse___3WvDN{color:inherit;background-color:rgba(0,0,0,0)}.ButtonDisplay__reverse-inverse___1ua4-{color:#ff9800;background-color:#000}", ""]);

// exports
exports.locals = {
	"ButtonDisplay": "ButtonDisplay__ButtonDisplay___1KIje",
	"outline": "ButtonDisplay__outline___1xkG9",
	"ButtonDisplay-base": "ButtonDisplay__ButtonDisplay-base___1QNwX",
	"outline-base": "ButtonDisplay__outline-base___3mfpp",
	"reverse-base": "ButtonDisplay__reverse-base___3q5GA",
	"ButtonDisplay-gloucester": "ButtonDisplay__ButtonDisplay-gloucester___zBf4m",
	"outline-gloucester": "ButtonDisplay__outline-gloucester___2jKHV",
	"reverse-gloucester": "ButtonDisplay__reverse-gloucester___26stQ",
	"ButtonDisplay-inverse": "ButtonDisplay__ButtonDisplay-inverse___2k32S",
	"outline-inverse": "ButtonDisplay__outline-inverse___3WvDN",
	"reverse-inverse": "ButtonDisplay__reverse-inverse___1ua4-"
};