import difference from './minutes-differencer'
const isWithinMinutes = date => {
  const duration = difference(date)
  return minutes => {
    if (!date) return false
    const isFuture = minutes < 0
    if (isFuture) return duration > minutes
    return duration < minutes
  }
}
export default isWithinMinutes
