import React from 'react'
import { object, func } from 'prop-types'

class AudioOutput extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      playing: false
    }
    this.audioContext = this.props.audioContext
    this.handlePlay = this.handlePlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.source = null
  }

  handlePlay() {
    this.source = this.props.createSource()
    this.source.connect(this.audioContext.destination)
    this.source.onended = this.handleStop
    if (this.source.start) this.source.start()
    this.setState({ playing: true })
  }
  componentWillUnmount() {
    this.handleStop()
  }
  handleStop() {
    this.source && this.source.disconnect()
    this.props.onStop && this.props.onStop()
    this.setState({ playing: false })
  }

  handleClick() {
    if (this.state.playing) {
      this.handleStop()
    } else {
      this.handlePlay()
    }
  }

  render() {
    const Component = this.props.Component
    return (
      <Component
        playing={this.state.playing}
        onClick={this.handleClick}
        {...this.props}
      />
    )
  }
}

AudioOutput.propTypes = {
  Component: func.isRequired,
  createSource: func.isRequired,
  audioContext: object.isRequired,
  onStop: func
}

export default AudioOutput
