import React, { PureComponent } from 'react'
import { func, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonProgress.styl'

export class ButtonProgress extends PureComponent {
  componentDidMount() {
    const { onProgress, progressDuration } = this.props
    this.buttonProgressTimer = setTimeout(onProgress, progressDuration)
  }

  componentWillUnmount() {
    clearTimeout(this.buttonProgressTimer)
  }

  render() {
    const { className, progressDuration } = this.props
    return (
      <span
        className={classNames(styles.ButtonProgress, className)}
        style={{ animationDuration: `${progressDuration}ms` }}
      />
    )
  }
}

ButtonProgress.displayName = 'ButtonProgress'

ButtonProgress.defaultProps = {
  progressDuration: 2000
}

ButtonProgress.propTypes = {
  className: string,
  onProgress: func.isRequired,
  progressDuration: number
}

export default ButtonProgress
