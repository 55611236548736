import React, { PureComponent } from 'react'
import { bool, object, arrayOf } from 'prop-types'
import moment from 'moment'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'
import Section from '../../../../../../component/Primitive/Section'
import Spinner from '../../../../../../component/Primitive/Spinner'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Type from '../../../../../../component/Primitive/Type'
import Crest from '../../../../../../component/Primitive/Crest'
import CrestLockup from '../../../../../../component/Primitive/CrestLockup'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Panel from '../../../../../../component/Primitive/Panel'
import Byline from '../../../../../../component/Primitive/Byline'
import WidgetArea from '../../../../../../component/Common/WidgetArea'
import Commentary from '../../../../../../component/Common/Commentary'

class Review extends PureComponent {
  render() {
    const { loading, match, articles = [] } = this.props
    const { __commentary } = match
    return (
      <OverlayLayout>
        <ScrollArea>
          <Section spacing="xt">
            <Type element="h1" size="display2">
              {match.__homeTeam.displayName} v {match.__awayTeam.displayName}
            </Type>
          </Section>
          <Panel shadow padding="xy">
            <SpacedList>
              <CrestLockup
                homeCrest={
                  <Crest
                    club={match.__homeTeam.name}
                    a11yText={match.__homeTeam.displayName}
                    constrain={48}
                  />
                }
                awayCrest={
                  <Crest
                    club={match.__awayTeam.name}
                    a11yText={match.__awayTeam.displayName}
                    constrain={48}
                  />
                }
                homeScore={match.__scores.home}
                awayScore={match.__scores.away}
                text="Full time"
              />
            </SpacedList>
          </Panel>
          {loading ? (
            <Section spacing="xy" grow center>
              <TextAlign center>
                <Spinner spinning revealDelay={2000} />
              </TextAlign>
            </Section>
          ) : articles.length > 0 ? (
            articles.map(article => (
              <Section spacing="y" key={`ArticleId${article._id}`}>
                <SpacedList>
                  <Section spacing="x">
                    <SpacedList>
                      <Type element="h2" size="title">
                        {article.headline}
                      </Type>
                      <Byline
                        displayDate={moment(article.displayDate).format(
                          'D MMMM YYYY - HH:mm'
                        )}
                        timestamp={article.displayDate}
                      />
                    </SpacedList>
                  </Section>
                  <WidgetArea widgets={article.body.widgets} />
                </SpacedList>
              </Section>
            ))
          ) : (
            <Section spacing="xt" grow center>
              <TextAlign center>
                <p>Awaiting review. Check back later.</p>
              </TextAlign>
            </Section>
          )}

          {__commentary.length > 0 && (
            <Section spacing="y">
              <SpacedList>
                <Section spacing="x">
                  <Type element="h1" size="display2">
                    Match Feed
                  </Type>
                </Section>
                <Commentary
                  items={[...__commentary].filter(
                    item => item.type !== 'promo'
                  )}
                />
              </SpacedList>
            </Section>
          )}
        </ScrollArea>
      </OverlayLayout>
    )
  }
}

Review.propTypes = {
  loading: bool,
  match: object.isRequired,
  articles: arrayOf(object)
}

export default Review
