exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Detail__DetailHeader___yj7xe{display:-webkit-box;display:-ms-flexbox;display:flex;line-height:16px}.Detail__DetailIcon___3Nns7{margin-left:-2px;margin-right:8px}.Detail__Detail-base___1JOiQ .Detail__DetailHeader___yj7xe{color:#00cfdd}.Detail__Detail-gloucester___3NrLC .Detail__DetailHeader___yj7xe{color:#da1a32}.Detail__Detail-inverse___21ByG .Detail__DetailHeader___yj7xe{color:#ff9800}", ""]);

// exports
exports.locals = {
	"DetailHeader": "Detail__DetailHeader___yj7xe",
	"DetailIcon": "Detail__DetailIcon___3Nns7",
	"Detail-base": "Detail__Detail-base___1JOiQ",
	"Detail-gloucester": "Detail__Detail-gloucester___3NrLC",
	"Detail-inverse": "Detail__Detail-inverse___21ByG"
};