import Immutable from 'immutable'
const basicState = Immutable.fromJS({
  isLoading: false,
  error: null,
  errorProperties: {},
  lastFetch: null,
  successfulCreate: false
})

export default ({
  prefix,
  createMapper = f => f,
  fetchMapper = f => f,
  updateMapper = f => f,
  defaultState
}) => {
  const initialState = basicState.merge(defaultState || {})
  return (state = initialState, action) => {
    switch (action.type) {
      case `${prefix}_RESET_ERROR`:
        return state.merge({
          successfulCreate: false,
          isLoading: false,
          error: null,
          errorProperties: {}
        })
      case `${prefix}_CREATE`:
        return state.merge({
          successfulCreate: false,
          isLoading: true,
          error: null
        })
      case `${prefix}_FETCH`:
      case `${prefix}_UPDATE`:
        return state.merge({
          isLoading: true,
          error: null
        })
      case `${prefix}_CREATE_FAILURE`:
      case `${prefix}_FETCH_FAILURE`:
      case `${prefix}_UPDATE_FAILURE`:
        return state.merge({
          isLoading: false,
          currentStep: 'error',
          error: action.error,
          errorProperties: action.errorProperties
        })
      case `${prefix}_CREATE_COMPLETE`:
        return state.merge({
          isLoading: false,
          error: null,
          successfulCreate: true,
          ...createMapper(action.data, state)
        })
      case `${prefix}_FETCH_COMPLETE`:
        return state.merge({
          isLoading: false,
          error: null,
          lastFetch: Date.now(),
          ...fetchMapper(action.data, state)
        })
      case `${prefix}_UPDATE_COMPLETE`:
        return state.merge({
          isLoading: false,
          error: null,
          ...updateMapper(action.data, state)
        })
      default:
        return state
    }
  }
}
