import React, { PureComponent } from 'react'
import { number, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Avatar.styl'

export class Avatar extends PureComponent {
  render() {
    const { name, size, src, theme } = this.props

    if (!name || name === '') return null

    const initials = name.split(/[ '’-]/g).map(word => word[0].toUpperCase())
    const fontSize = Math.floor(
      initials.length > 1 ? (size * 0.95) / initials.length : (size * 0.95) / 2
    )
    const formattedFontSize = `${fontSize}px`
    const formattedSize = `${size}px`

    return (
      <div
        className={classNames(
          styles.Avatar,
          theme && styles[`Avatar-${theme}`],
          src && styles.image
        )}
        style={{
          fontSize: formattedFontSize,
          height: formattedSize,
          lineHeight: formattedSize,
          width: formattedSize
        }}
        title={name}
      >
        {src && <img src={src} alt="" />}
        {!src && initials && (
          <div className={styles.AvatarInitials}>{initials}</div>
        )}
      </div>
    )
  }
}

Avatar.displayName = 'Avatar'

Avatar.defaultProps = {
  size: 50
}

Avatar.propTypes = {
  name: string.isRequired,
  size: number,
  src: string,
  theme: string
}

export default withTheme(Avatar)
