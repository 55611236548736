import React, { PureComponent } from 'react'
import PrimusContext from './PrimusContext'

const withPrimus = ChildComponent => {
  class WithPrimus extends PureComponent {
    render() {
      return (
        <PrimusContext.Consumer>
          {primus => <ChildComponent {...this.props} primus={primus} />}
        </PrimusContext.Consumer>
      )
    }
  }
  return WithPrimus
}

export default withPrimus
