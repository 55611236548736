import React, { Fragment, PureComponent } from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'

import Section from '../../component/Primitive/Section'
import ScrollArea from '../../component/Primitive/ScrollArea'

import MatchPreviewTile from '../../component/Common/Tile/MatchPreview'
import FeedTile from '../../component/Common/Tile/Feed'
import TileGrid from '../../component/Primitive/TileGrid'
import LineupTile from '../../component/Common/Tile/Lineup'
import ClickAndCollectTile from '../../component/Common/Tile/ClickAndCollect'
import QuizTile from '../../component/Common/Tile/Quiz'
import VenueInfoTile from '../../component/Common/Tile/VenueInfo'
import MotmTile from '../../component/Common/Tile/Motm'
import NewsTile from '../../component/Common/Tile/News'
import GalleryTile from '../../component/Common/Tile/Gallery'
import ReviewTile from '../../component/Common/Tile/Review'
import WifiTile from '../../component/Common/Tile/Wifi'
import SeatServeTile from '../../component/Common/Tile/SeatServe'

import createWithinMinutes from '../../lib/within-minutes-checker'

const oneDay = 1440
const threeDays = oneDay * 3

const PrematchTiles = () => (
  <TileGrid>
    <NewsTile height={1 / 6} />
    <ReviewTile height={1 / 6} />
    <MatchPreviewTile height={1 / 3} />
    <ClickAndCollectTile height={1 / 6} />
    <VenueInfoTile heading="Directions" height={1 / 6} />
  </TileGrid>
)
const ImminentPrematchTiles = () => (
  <TileGrid>
    <MatchPreviewTile height={1 / 3} />
    <LineupTile height={1 / 12} />
    <NewsTile height={1 / 12} />
    <SeatServeTile height={1 / 6} />
    <GalleryTile height={1 / 12} />
    <ClickAndCollectTile height={1 / 12} />
    <WifiTile height={1 / 6} />
  </TileGrid>
)
const LiveMatchTiles = () => (
  <TileGrid>
    <FeedTile height={1 / 6} />
    <LineupTile height={1 / 6} />
    <MotmTile height={1 / 6} />
    <QuizTile height={1 / 6} />
    <SeatServeTile height={1 / 6} />
    <ClickAndCollectTile height={1 / 6} />
    <WifiTile height={1 / 6} />
  </TileGrid>
)
const PostMatchTiles = () => (
  <TileGrid>
    <ReviewTile height={1 / 6} />
    <MotmTile height={1 / 6} />
    <GalleryTile height={1 / 6} />
    <MatchPreviewTile height={1 / 3} />
    <NewsTile height={1 / 6} />
  </TileGrid>
)

class Featured extends PureComponent {
  render() {
    const { last, next, live } = this.props
    const lastMatchIsWithinMinutes = createWithinMinutes(last && last.dateTime)
    const nextMatchIsWithinMinutes = createWithinMinutes(next && next.dateTime)
    let Tiles
    if (live) {
      Tiles = LiveMatchTiles
    } else if (nextMatchIsWithinMinutes(-oneDay)) {
      Tiles = ImminentPrematchTiles
    } else if (lastMatchIsWithinMinutes(threeDays)) {
      Tiles = PostMatchTiles
    } else if (next) {
      Tiles = PrematchTiles
    } else if (last) {
      Tiles = PostMatchTiles
    } else {
      Tiles = () => <p>Sorry no match data available</p>
    }

    return (
      <Fragment>
        <Section grow fullHeight>
          <ScrollArea>
            <Tiles />
          </ScrollArea>
        </Section>
      </Fragment>
    )
  }
}

Featured.propTypes = {
  last: object,
  live: object,
  next: object
}

export default connect(state => state.matches.toJS())(Featured)
