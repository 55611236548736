exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Type__display4___3coJn{font-size:34px;line-height:36px;font-weight:900;text-transform:uppercase}@media screen and (min-width:350px){.Type__display4___3coJn{font-size:48px;line-height:52px;font-weight:900;text-transform:uppercase}}.Type__display3___32g_A{font-size:34px;line-height:36px;font-weight:900;text-transform:uppercase}.Type__display2___2Ldte{font-size:24px}.Type__display1___1iSJX,.Type__display2___2Ldte{line-height:32px;font-weight:900;text-transform:uppercase}.Type__display1___1iSJX{font-size:20px}.Type__title___3YIjv{font-size:34px;line-height:40px;font-weight:300}.Type__subtitle___bjt4p{font-size:24px;line-height:32px;font-weight:300}.Type__base___2-_am{font-size:16px;line-height:24px}.Type__small___enC0j{font-size:14px}.Type__small___enC0j,.Type__tiny___2kbU4{line-height:16px}.Type__tiny___2kbU4{font-size:11px;text-transform:uppercase;font-weight:700}.Type__success___hFg3u{color:#4caf50}.Type__notice___cQWBf{color:#2196f3}.Type__warning___2cGAm{color:#ff9800}.Type__error___2ygAf{color:#da1a32}.Type__tight___2PNxg{line-height:1}.Type__muted___1Js6c{opacity:.7}", ""]);

// exports
exports.locals = {
	"display4": "Type__display4___3coJn",
	"display3": "Type__display3___32g_A",
	"display2": "Type__display2___2Ldte",
	"display1": "Type__display1___1iSJX",
	"title": "Type__title___3YIjv",
	"subtitle": "Type__subtitle___bjt4p",
	"base": "Type__base___2-_am",
	"small": "Type__small___enC0j",
	"tiny": "Type__tiny___2kbU4",
	"success": "Type__success___hFg3u",
	"notice": "Type__notice___cQWBf",
	"warning": "Type__warning___2cGAm",
	"error": "Type__error___2ygAf",
	"tight": "Type__tight___2PNxg",
	"muted": "Type__muted___1Js6c"
};