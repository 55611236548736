import React, { PureComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'
import AnimatedRoute from '../../../../../../component/Primitive/AnimatedRoute'
import EventList from './Events'
import Event from './Event'

class Events extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <AnimatedRoute>
          {location => (
            <Switch location={location}>
              <Route
                exact
                path="/activity/access/events"
                render={props => <EventList {...props} />}
              />
              <Route
                exact
                path="/activity/access/events/:id"
                render={props => <Event {...props} />}
              />
            </Switch>
          )}
        </AnimatedRoute>
      </OverlayLayout>
    )
  }
}

export default Events
