import React from 'react' // eslint-disable-line no-unused-vars

// Sourced from https://material.io/tools/icons/?style=outline
// svgo ./src/component/Primitive/Icon/source/common --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2
import Add from './source/common/outline-add-24px.svg'
import ArrowBack from './source/common/outline-arrow_back-24px.svg'
import ArrowBackIos from './source/common/outline-arrow_back_ios-24px.svg'
import AutoRenew from './source/common/baseline-autorenew-24px.svg'
import Calendar from './source/common/baseline-calendar_today-24px.svg'
import Check from './source/common/outline-check-24px.svg'
import CheckBox from './source/common/baseline-check_box-24px.svg'
import CheckBoxOutline from './source/common/baseline-check_box_outline_blank-24px.svg'
import CheckCircle from './source/common/baseline-check_circle-24px.svg'
import ChevronRight from './source/common/outline-chevron_right-24px.svg'
import Close from './source/common/outline-close-24px.svg'
import Email from './source/common/outline-email-24px.svg'
import Error from './source/common/baseline-error_outline-24px.svg'
import Forward30 from './source/common/baseline-forward_30-24px.svg'
import Headset from './source/common/baseline-headset-24px.svg'
import HelpOutline from './source/common/outline-help_outline-24px.svg'
import KeyboardArrowDown from './source/common/baseline-keyboard_arrow_down-24px.svg'
import KeyboardArrowRight from './source/common/baseline-keyboard_arrow_right-24px.svg'
import KeyboardArrowUp from './source/common/baseline-keyboard_arrow_up-24px.svg'
import Menu from './source/common/outline-menu-24px.svg'
import NotificationsActive from './source/common/baseline-notifications_active-24px.svg'
import Pause from './source/common/baseline-pause-24px.svg'
import PauseCircle from './source/common/outline-pause_circle_filled-24px.svg'
import Person from './source/common/baseline-person-24px.svg'
import Place from './source/common/baseline-place-24px.svg'
import Play from './source/common/baseline-play_arrow-24px.svg'
import PlayCircle from './source/common/outline-play_circle_filled-24px.svg'
import Remove from './source/common/outline-remove-24px.svg'
import Replay10 from './source/common/baseline-replay_10-24px.svg'
import Search from './source/common/outline-search-24px.svg'
import Star from './source/common/outline-star-24px.svg'
import SwipeLeft from './source/common/swipe-left.svg'
import SwipeRight from './source/common/swipe-right.svg'
import SwipeStation from './source/common/swipestation.svg'
import ThumbUp from './source/common/baseline-thumb_up_alt-24px.svg'
import Timer from './source/common/outline-timer-24px.svg'
import ViewColumn from './source/common/view-column.svg'
import ViewCoverFlow from './source/common/view-cover-flow.svg'
import Visibility from './source/common/baseline-visibility-24px.svg'
import VisibilityOff from './source/common/baseline-visibility_off-24px.svg'
import VolumeMute from './source/common/baseline-volume_mute-24px.svg'
import VolumeUp from './source/common/baseline-volume_up-24px.svg'
import Wifi from './source/common/outline-wifi-24px.svg'

// Activity-specific: Rugby
// svgo ./src/component/Primitive/Icon/source/activity/rugby --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2
import Conversion from './source/activity/rugby/Conversion.svg'
import DropGoal from './source/activity/rugby/DropGoal.svg'
import Penalty from './source/activity/rugby/Penalty.svg'
import RugbyBall from './source/activity/rugby/RugbyBall.svg'
import SentOff from './source/activity/rugby/SentOff.svg'
import SinBin from './source/activity/rugby/SinBin.svg'
import Substitution from './source/activity/rugby/Substitution.svg'
import Twitter from './source/activity/rugby/Twitter.svg'
import Venue from './source/activity/rugby/Venue.svg'
import Whistle from './source/activity/rugby/Whistle.svg'

const Svgs = {
  Add,
  ArrowBack,
  ArrowBackIos,
  AutoRenew,
  Calendar,
  Check,
  CheckBox,
  CheckBoxOutline,
  CheckCircle,
  ChevronRight,
  Close,
  Email,
  Error,
  Forward30,
  Headset,
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Menu,
  NotificationsActive,
  Pause,
  PauseCircle,
  Person,
  Place,
  Play,
  PlayCircle,
  Remove,
  Replay10,
  Search,
  Star,
  SwipeLeft,
  SwipeRight,
  SwipeStation,
  ThumbUp,
  Timer,
  Venue,
  ViewColumn,
  ViewCoverFlow,
  Visibility,
  VisibilityOff,
  VolumeMute,
  VolumeUp,
  Wifi,

  // Activity-specific: Rugby
  Conversion,
  DropGoal,
  Penalty,
  RugbyBall,
  SentOff,
  SinBin,
  Substitution,
  Twitter,
  Whistle
}

export default Svgs
