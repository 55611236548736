import React from 'react'
import {
  array,
  func,
  node,
  instanceOf,
  object,
  oneOf,
  string
} from 'prop-types'

import OverlayLayout from '../../../../../component/Layout/OverlayLayout'

import MotmLoading from './step/MotmLoading'
import MotmError from './step/MotmError'
import MotmPreview from './step/MotmPreview'
import MotmActive from './step/MotmActive'
import MotmInactive from './step/MotmInactive'
import MotmVoted from './step/MotmVoted'
import MotmComplete from './step/MotmComplete'

const stepMap = {
  loading: MotmLoading,
  error: MotmError,
  preview: MotmPreview,
  active: MotmActive,
  inactive: MotmInactive,
  voted: MotmVoted,
  complete: MotmComplete
}

const Motm = props => {
  const { currentStep } = props
  const CurrentStep = stepMap[currentStep]

  return (
    <OverlayLayout>
      <CurrentStep {...props} />
    </OverlayLayout>
  )
}

Motm.propTypes = {
  currentStep: oneOf([
    'loading',
    'error',
    'preview',
    'active',
    'inactive',
    'voted',
    'complete'
  ]).isRequired,
  error: node,
  handleConfirmation: func,
  myVote: string,
  squad: array,
  startTime: instanceOf(Date),
  voteCloseDate: instanceOf(Date),
  voteLeaders: array,
  primus: object.isRequired
}

export default Motm
