import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import formatDateTime from '../lib/date-formatter'
import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import Prose from '../../../../../../component/Primitive/Prose'
import Detail from '../../../../../../component/Primitive/Detail'
import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'

class Event extends PureComponent {
  render() {
    const {
      title,
      startDate,
      endDate,
      location,
      ctaText,
      ctaLink,
      body
    } = this.props.event
    return (
      <Section grow>
        <ScrollArea>
          <Section spacing="xt">
            <SpacedList>
              <Type element="h1" size="display2">
                Upcoming events
              </Type>

              <Type element="h2" size="title">
                {title}
              </Type>

              <Detail icon="Timer" label="Time">
                {formatDateTime({ startDate, endDate })}
              </Detail>

              <Detail icon="Place" label="Location">
                {location}
              </Detail>

              <ButtonDisplay href={ctaLink} target="_blank" block>
                {ctaText}
              </ButtonDisplay>
            </SpacedList>
          </Section>
          <Section spacing="xy">
            <SpacedList>
              <Prose html={body} />
            </SpacedList>
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Event.propTypes = {
  event: object
}

export default Event
