import { node, arrayOf, oneOf } from 'prop-types'

let currentPlatform = 'Server'

if (typeof window !== 'undefined') {
  currentPlatform = 'Browser'
  if (window.cordova) {
    currentPlatform = window.cordova.device.platform
  }
}

currentPlatform = 'Android'

const PlatformConditional = ({ platforms, children }) =>
  platforms.includes(currentPlatform) ? children : null

PlatformConditional.propTypes = {
  platforms: arrayOf(oneOf('iOS', 'Browser', 'Android', 'Server')),
  children: node
}

export default PlatformConditional
