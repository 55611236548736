import React, { PureComponent } from 'react'
import { node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Square.styl'

export const sizes = styles.sizes
  ? styles.sizes.split(' ').map(size => parseInt(size, 10))
  : []

export class Square extends PureComponent {
  render() {
    const { children, size, theme } = this.props

    return (
      <div
        className={classNames(
          styles.Square,
          theme && styles[`Square-${theme}`],
          size && styles[`size${size}`]
        )}
      >
        {children}
      </div>
    )
  }
}

Square.displayName = 'Square'

Square.defaultProps = {
  size: 5
}

Square.propTypes = {
  children: node,
  size: oneOf(sizes),
  theme: string
}

export default withTheme(Square)
