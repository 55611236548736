import React, { PureComponent } from 'react'
import { array, number, object, shape } from 'prop-types'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'
import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Type from '../../../../../../component/Primitive/Type'
import Crest from '../../../../../../component/Primitive/Crest'
import CrestLockup from '../../../../../../component/Primitive/CrestLockup'
import Sticky from '../../../../../../component/Primitive/Sticky'
import Panel from '../../../../../../component/Primitive/Panel'
// import Expander from '../../../../../../component/Primitive/Expander'
// import { Grid, GridItem } from '../../../../../../component/Primitive/Grid'
// import IconList from '../../../../../../component/Primitive/IconList'
import Margin from '../../../../../../component/Primitive/Margin'
import Prose from '../../../../../../component/Primitive/Prose'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import Icon from '../../../../../../component/Primitive/Icon'
import IconAnimation from '../../../../../../component/Primitive/IconAnimation'

import Commentary from '../../../../../../component/Common/Commentary'

// const highlightEventTypes = [
//   'conversion',
//   'try',
//   'redCard',
//   'yellowCard',
//   'dropGoal',
//   'penalty'
// ]

class Feed extends PureComponent {
  render() {
    const { commentary, match, scores } = this.props
    const { __homeTeam: homeTeam, __awayTeam: awayTeam, isLive } = match

    // const highlightEvents = commentary.filter(item =>
    //   highlightEventTypes.includes(item.type)
    // )
    // const highlights = { home: [], away: [] }

    // highlightEvents.map(item =>
    //   highlights[item.team].push({
    //     icon: icons[item.type],
    //     label: `${item.minute}′`,
    //     // TODO: Should be the name of the player involved (scored/carded)
    //     text: item.heading
    //   })
    // )

    return (
      <OverlayLayout>
        <ScrollArea>
          <div>
            <Section spacing="xt">
              <Type element="h1" size="display2">
                Live Feed
              </Type>
            </Section>
            <Sticky zIndex={1}>
              <Panel shadow>
                <Margin mx={3} my={2}>
                  <CrestLockup
                    homeCrest={
                      <Crest
                        club={homeTeam.name}
                        a11yText={homeTeam.displayName}
                        constrain={48}
                      />
                    }
                    awayCrest={
                      <Crest
                        club={awayTeam.name}
                        a11yText={awayTeam.displayName}
                        constrain={48}
                      />
                    }
                    homeScore={scores.home}
                    awayScore={scores.away}
                    active
                  />
                </Margin>
                {/* {(highlights.home.length > 0 || highlights.away.length > 0) && (
                <Expander direction="up">
                  <Margin mx={2}>
                    <Grid gap={2}>
                      <GridItem sizes={['one-half']}>
                        <IconList items={highlights.home} />
                      </GridItem>
                      <GridItem sizes={['one-half']}>
                        <IconList items={highlights.away} reverse />
                      </GridItem>
                    </Grid>
                  </Margin>
                </Expander>
              )} */}
              </Panel>
            </Sticky>
            <Section grow>
              {isLive && (
                <Margin my={2}>
                  <TextAlign center>
                    <Type size="tiny">
                      <IconAnimation animation="refresh">
                        <Icon width={16} type="AutoRenew" />
                      </IconAnimation>{' '}
                      Commentary will automatically update
                    </Type>
                  </TextAlign>
                </Margin>
              )}
              {commentary.length < 1 && (
                <Section spacing="xy" grow center>
                  <TextAlign center>
                    <Prose>
                      <p>No updates yet, check back soon.</p>
                    </Prose>
                  </TextAlign>
                </Section>
              )}
              <Commentary items={commentary} />
            </Section>
          </div>
        </ScrollArea>
      </OverlayLayout>
    )
  }
}

Feed.propTypes = {
  match: object.isRequired,
  commentary: array.isRequired,
  scores: shape({
    home: number,
    away: number
  }).isRequired
}

export default Feed
