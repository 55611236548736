import React from 'react'
import { bool, string } from 'prop-types'

import LoginBox from '../container/LoginBox'
import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import Prose from '../../../../component/Primitive/Prose'
import Rule from '../../../../component/Primitive/Rule'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Type from '../../../../component/Primitive/Type'

const DefaultMessage = () => (
  <p>
    To get the most out of Colooder, you need to create an account. You can get
    started by creating a new account or logging in.
  </p>
)

const Login = ({ authenticated, title, message, redirect }) => (
  <ScrollArea>
    <Section spacing="xy">
      {authenticated ? (
        <p>You are already logged in.</p>
      ) : (
        <SpacedList>
          <Type element="h1" size="display2">
            {title || 'Hi there'}
          </Type>
          <Prose>{message ? <p>{message}</p> : <DefaultMessage />}</Prose>
          <ButtonDisplay block to="/profile/register">
            Create an Account
          </ButtonDisplay>

          <Rule spaced muted />
          <LoginBox redirect={redirect} />
        </SpacedList>
      )}
    </Section>
  </ScrollArea>
)

Login.propTypes = {
  authenticated: bool,
  title: string,
  message: string,
  redirect: string
}

export default Login
