import createCrudActioner from '../../../../../lib/crud-actioner'

const { fetch } = createCrudActioner({
  prefix: 'MATCH_REVIEWS',
  action: 'matchReviews'
})

const fetchMatchReviews = (primus, query) => fetch(primus, query)

export { fetchMatchReviews }
