import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { node, number, string } from 'prop-types'

import withTheme from '../../../../Theme/withTheme'
import styles from './ResponsiveTableDataCell.styl'

export class ResponsiveTableDataCell extends PureComponent {
  render() {
    const { children, hideUntil, theme } = this.props

    return (
      <td
        className={classNames(
          styles.ResponsiveTableDataCell,
          theme && styles[`ResponsiveTableDataCell-${theme}`],
          hideUntil && `hideUntil-${hideUntil}`
        )}
      >
        {children}
      </td>
    )
  }
}

ResponsiveTableDataCell.propTypes = {
  children: node,
  hideUntil: number,
  theme: string
}

export default withTheme(ResponsiveTableDataCell)
