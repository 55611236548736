import React, { PureComponent } from 'react'
import { node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Notification.styl'

import Icon from '../Icon'
import ShrinkWrap, { ShrinkWrapItem } from '../ShrinkWrap'

export class Notification extends PureComponent {
  render() {
    const { children, className, icon, status } = this.props

    return (
      <div
        className={classNames(
          styles.Notification,
          status && styles[status],
          className
        )}
      >
        {!!icon && (
          <ShrinkWrap vAlign="middle">
            <ShrinkWrapItem shrink>
              <Icon type={icon} />
            </ShrinkWrapItem>
            <ShrinkWrapItem>{children}</ShrinkWrapItem>
          </ShrinkWrap>
        )}
        {!icon && children}
      </div>
    )
  }
}

Notification.displayName = 'Notification'

Notification.propTypes = {
  children: node.isRequired,
  className: string,
  icon: string,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default Notification
