import React from 'react'
import { func } from 'prop-types'

import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import Prose from '../../../../../../component/Primitive/Prose'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Section from '../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

const GallerySource = ({ onSetSource }) => (
  <OverlayLayout>
    <Section grow>
      <ScrollArea>
        <Section spacing="xt">
          <Type element="h1" size="display2">
            Fan Gallery
          </Type>
        </Section>
        <Section spacing="xy">
          <SpacedList>
            <Prose>Add a photo from:</Prose>
            <ButtonDisplay
              block
              onClick={() => {
                onSetSource('camera')
              }}
            >
              Camera
            </ButtonDisplay>
            <ButtonDisplay
              block
              onClick={() => {
                onSetSource('photoLibrary')
              }}
            >
              Photo Library
            </ButtonDisplay>
          </SpacedList>
        </Section>
      </ScrollArea>
    </Section>
  </OverlayLayout>
)

GallerySource.propTypes = {
  onSetSource: func
}

export default GallerySource
