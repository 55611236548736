import 'babel-polyfill'
import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import { Provider } from 'react-redux'
import init from './init'
import createStore from './store'
import App from './view/App'
import Loading from './view/Loading'

const rootElement = document.getElementById('root')

render(<Loading />, rootElement)

document.addEventListener(
  document.location.port === '8080' ? 'deviceready' : 'DOMContentLoaded',
  async () => {
    const { store, history } = await createStore()
    await init(store)

    // UI setup
    const renderApp = Component => {
      render(
        <AppContainer>
          <Provider store={store}>
            <Component history={history} />
          </Provider>
        </AppContainer>,
        rootElement
      )
    }

    renderApp(App)

    if (module.hot) {
      module.hot.accept('./view/App/index.jsx', () => {
        renderApp(require('./view/App').default)
      })
    }
  }
)
