import React from 'react'
import { connect } from 'react-redux'
import { onConnect, getConnectedSSID } from './actions'

import Wifi from './component/Wifi'

const WifiContainer = props => <Wifi {...props} />

const mapDispatchToProps = dispatch => ({
  onConnect: () => dispatch(onConnect()),
  getConnectedSSID: () => dispatch(getConnectedSSID())
})

const mapStateToProps = state => state.wifi.toJS()

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WifiContainer)
