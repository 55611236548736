import React, { PureComponent } from 'react'
import { number, object } from 'prop-types'
import { connect } from 'react-redux'
import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class FeedTile extends PureComponent {
  render() {
    const { height, live } = this.props
    if (!live) return false
    return (
      <Tile
        heading="Live Feed"
        ctaText="Live"
        to="/activity/live/feed"
        imageUrl={image}
        height={height}
        active
      />
    )
  }
}

FeedTile.propTypes = {
  height: number,
  live: object
}

export default connect(state => state.matches.toJS())(FeedTile)
