import React, { PureComponent } from 'react'
import { bool, func, object, string } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import Notification from '../../../../../../component/Primitive/Notification'
import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import FieldTemplate from '../../../../../../component/Primitive/FieldTemplate'
import Form from '../../../../../../component/Primitive/Form'
import TextControl from '../../../../../../component/Primitive/Control/TextControl'
import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

class GalleryForm extends PureComponent {
  render() {
    const {
      primus,
      onChange,
      name,
      emailAddress,
      message,
      error,
      cameraError,
      onSubmit,
      validationErrors,
      sending
    } = this.props

    const onFormChange = e => {
      const el = e.target
      let inputData = {}
      inputData[el.name] = el.value
      onChange(inputData)
    }

    const imageError = validationErrors.images ? 'A photo is required' : null

    const hasError = cameraError || error || imageError

    return (
      <OverlayLayout>
        <Section grow>
          <ScrollArea>
            <Section spacing="xt">
              <Type element="h1" size="display2">
                Fan Gallery
              </Type>
            </Section>

            <Section spacing="xy">
              <SpacedList>
                {hasError && (
                  <Notification icon="Error" status="error">
                    {cameraError}
                    {error}
                    {imageError}
                  </Notification>
                )}

                {!hasError && (
                  <Form>
                    <FieldTemplate
                      controlName="name"
                      label="Name"
                      required
                      {...validationErrors.name && {
                        status: 'error',
                        feedback: validationErrors.name
                      }}
                    >
                      <TextControl
                        name="name"
                        value={name}
                        required
                        autoComplete="name"
                        onChange={onFormChange}
                        {...validationErrors.name && {
                          status: 'error'
                        }}
                      />
                    </FieldTemplate>

                    <FieldTemplate
                      controlName="emailAddress"
                      label="Email Address"
                      required
                      {...validationErrors.emailAddress && {
                        status: 'error',
                        feedback: validationErrors.emailAddress
                      }}
                    >
                      <TextControl
                        name="emailAddress"
                        type="email"
                        value={emailAddress}
                        required
                        autoComplete="email"
                        onChange={onFormChange}
                        {...validationErrors.emailAddress && {
                          status: 'error'
                        }}
                      />
                    </FieldTemplate>

                    <FieldTemplate
                      controlName="message"
                      label="Anything you want to say?"
                      {...validationErrors.message && {
                        status: 'error',
                        feedback: validationErrors.message
                      }}
                    >
                      <TextControl
                        name="message"
                        multiline
                        value={message}
                        onChange={onFormChange}
                        {...validationErrors.message && {
                          status: 'error'
                        }}
                      />
                    </FieldTemplate>

                    <ButtonDisplay block onClick={() => onSubmit(primus)}>
                      {sending ? 'Sending…' : 'Send'}
                    </ButtonDisplay>
                  </Form>
                )}
              </SpacedList>
            </Section>
          </ScrollArea>
        </Section>
      </OverlayLayout>
    )
  }
}

GalleryForm.defaultProps = {
  validationErrors: {}
}

GalleryForm.propTypes = {
  primus: object.isRequired,
  onChange: func.isRequired,
  error: object,
  validationErrors: object,
  onSubmit: func,
  name: string,
  sending: bool,
  message: string,
  emailAddress: string,
  cameraError: string
}

export default GalleryForm
