import script from 'scriptjs'

export default (window = {}) => {
  if (process.env.NODE_ENV === 'production') {
    script('https://cdn.ravenjs.com/3.26.4/raven.min.js', error => {
      if (error) {
        return console.error(error)
      }
      window.Raven.config(
        'https://dec3fdaaf5ff4ef09a7d8bf71239dc3d@sentry.io/1265760'
      ).install()
    })
  }
}
