import React from 'react'
import { func, string, object, bool } from 'prop-types'

import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
// import Icon from '../../../../component/Primitive/Icon'
// import DecoratedTextControl from '../../../../component/Primitive/Control/DecoratedTextControl'
import Notification from '../../../../component/Primitive/Notification'
import CheckControl from '../../../../component/Primitive/Control/CheckControl'
import FieldTemplate from '../../../../component/Primitive/FieldTemplate'
import Form from '../../../../component/Primitive/Form'
import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import SmartLink from '../../../../component/Primitive/SmartLink'
import SpacedList from '../../../../component/Primitive/SpacedList'
import TextControl from '../../../../component/Primitive/Control/TextControl'
import Type from '../../../../component/Primitive/Type'
import ConsentOptions from '../../component/ContentOptions'

const Register = ({
  onChange,
  onSubmit,
  error,
  errorProperties,
  data,
  dateOfBirth,
  authenticated,
  primus
}) => (
  <Section grow>
    <ScrollArea>
      <Section spacing="xy">
        {authenticated ? (
          <p>You are already logged in.</p>
        ) : (
          <SpacedList>
            <Type element="h1" size="display2">
              Create Account
            </Type>
            <Prose>
              <p>
                To get the most out of Colooder, you need to create an account.
              </p>
            </Prose>
            {error && (
              <Notification icon="Error" status="error">
                {error}
              </Notification>
            )}
            <Form onSubmit={e => onSubmit(e, primus)}>
              <FieldTemplate
                controlName="firstName"
                label="First name"
                required
                status={errorProperties.firstName && 'error'}
                feedback={errorProperties.firstName}
              >
                <TextControl
                  name="firstName"
                  autoComplete="given-name"
                  status={errorProperties.firstName && 'error'}
                  onChange={onChange}
                  value={data.firstName}
                  required
                />
              </FieldTemplate>

              <FieldTemplate
                controlName="lastName"
                label="Last name"
                status={errorProperties.lastName && 'error'}
                required
                feedback={errorProperties.lastName}
              >
                <TextControl
                  name="lastName"
                  autoComplete="family-name"
                  onChange={onChange}
                  status={errorProperties.lastName && 'error'}
                  value={data.lastName}
                  required
                />
              </FieldTemplate>

              <FieldTemplate
                controlName="emailAddress"
                label="Email Address"
                status={errorProperties.emailAddress && 'error'}
                feedback={errorProperties.emailAddress}
                required
              >
                <TextControl
                  name="emailAddress"
                  type="email"
                  autoComplete="email"
                  onChange={onChange}
                  status={errorProperties.emailAddress && 'error'}
                  value={data.emailAddress}
                  required
                />
              </FieldTemplate>

              <FieldTemplate
                controlName="password"
                label="Create password"
                status={errorProperties.password && 'error'}
                feedback={errorProperties.password}
                required
              >
                <TextControl
                  name="password"
                  type="password"
                  status={errorProperties.password && 'error'}
                  onChange={onChange}
                  required
                />
              </FieldTemplate>
              {/* <FieldTemplate
                controlName="dateOfBirth"
                label="Date of birth"
                status={errorProperties.dateOfBirth && 'error'}
                feedback={errorProperties.dateOfBirth}
                assistance="We use your age to ensure you only see content appropriate for your age."
                required
                inverse
              >
                <DecoratedTextControl
                  after={<Icon type="Calendar" />}
                  name="dateOfBirth"
                  type="date"
                  value={data.dateOfBirth || dateOfBirth || ''}
                  status={errorProperties.dateOfBirth && 'error'}
                  onChange={onChange}
                  required
                />
              </FieldTemplate> */}
              <ConsentOptions
                errorProperties={errorProperties}
                data={data}
                onChange={onChange}
              />
              <FieldTemplate
                controlName="termsAgreed"
                template="check"
                required
                status={errorProperties.termsAgreed && 'error'}
                feedback={errorProperties.termsAgreed}
              >
                <CheckControl
                  type="checkbox"
                  name="termsAgreed"
                  value="termsAgreed"
                  checked={!!data.termsAgreed}
                  status={errorProperties.termsAgreed && 'error'}
                  onChange={onChange}
                >
                  <Prose>
                    I agree to the{' '}
                    <SmartLink to="/terms">Terms and conditions</SmartLink>
                  </Prose>
                </CheckControl>
              </FieldTemplate>

              <ButtonDisplay block type="submit">
                Submit
              </ButtonDisplay>
            </Form>
          </SpacedList>
        )}
      </Section>
    </ScrollArea>
  </Section>
)

Register.propTypes = {
  onChange: func,
  onSubmit: func,
  error: string,
  errorProperties: object,
  data: object,
  dateOfBirth: string,
  authenticated: bool,
  primus: object
}

export default Register
