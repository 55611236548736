exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonBase__ButtonBase___2PFf9{display:inline-block;vertical-align:middle;text-align:center;text-decoration:none;-webkit-transition:all .3s cubic-bezier(.165,.84,.44,1);transition:all .3s cubic-bezier(.165,.84,.44,1)}.ButtonBase__ButtonBase___2PFf9:focus,.ButtonBase__ButtonBase___2PFf9:hover{outline:0;-webkit-transition-duration:.15s;transition-duration:.15s;text-decoration:none}.ButtonBase__disabled___1bdrN{opacity:.75;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none}.ButtonBase__block___T7BVb{display:block;width:100%}", ""]);

// exports
exports.locals = {
	"ButtonBase": "ButtonBase__ButtonBase___2PFf9",
	"disabled": "ButtonBase__disabled___1bdrN",
	"block": "ButtonBase__block___T7BVb"
};