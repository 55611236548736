import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import onComponentDidMount from '../../../../../../lib/component-did-mount-helper'

import Loading from './Loading'
import Start from './Start'
import Questions from './Questions'
import CaptureForm from '../Container/CaptureForm'
import Thanks from './Thanks'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'
import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'

class Quiz extends PureComponent {
  constructor(...args) {
    super(...args)
    this.state = {
      reverseToolbar: true,
      showToolbarLogo: false,
      showToolbar: true
    }
    this.onEnterCompetition = this.onEnterCompetition.bind(this)
  }

  componentDidMount() {
    this.props.initQuiz()
    this.props.fetchQuiz(this.props.primus)
  }

  onEnterCompetition() {
    this.props.onEnterCompetition(this.props.primus, this.props.emailAddress)
  }

  render() {
    const { isLoading } = this.props
    return (
      <OverlayLayout
        showToolbar={this.state.showToolbar}
        reverse={this.state.reverseToolbar}
        buttonsBefore={this.state.showBack ? null : <div />}
        buttonsAfter={this.state.showToolbarLogo ? null : <div />}
      >
        <Section grow>
          <ScrollArea>
            <Section grow>
              {isLoading ? (
                <Loading />
              ) : (
                <Switch>
                  <Route
                    exact
                    path="/activity/play/quiz"
                    component={onComponentDidMount(
                      () =>
                        this.setState({
                          reverseToolbar: true,
                          showToolbarLogo: false,
                          showBack: true
                        }),
                      () => (
                        <Start {...this.props} />
                      )
                    )}
                  />
                  <Route
                    exact
                    path="/activity/play/quiz/questions"
                    component={onComponentDidMount(
                      () =>
                        this.setState({
                          reverseToolbar: false,
                          showToolbarLogo: true,
                          showBack: false
                        }),
                      () => (
                        <Questions
                          {...this.props}
                          onEnterCompetition={this.onEnterCompetition}
                        />
                      )
                    )}
                  />
                  <Route
                    exact
                    path="/activity/play/quiz/capture"
                    render={() => {
                      this.setState({
                        reverseToolbar: false,
                        showToolbarLogo: true,
                        showBack: false
                      })
                      return <CaptureForm />
                    }}
                  />
                  <Route
                    exact
                    path="/activity/play/quiz/thanks"
                    component={onComponentDidMount(
                      () =>
                        this.setState({
                          showToolbar: false
                        }),
                      () => (
                        <Thanks {...this.props} />
                      )
                    )}
                  />
                </Switch>
              )}
            </Section>
          </ScrollArea>
        </Section>
      </OverlayLayout>
    )
  }
}

Quiz.defaultProps = {
  fetchQuiz: f => f
}

Quiz.propTypes = {
  fetchQuiz: func,
  isLoading: bool,
  onEnterCompetition: func
}

export default Quiz
