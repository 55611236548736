import React, { PureComponent } from 'react'
import { string } from 'prop-types'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

import Team from './step/Team'

class Lineups extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <Team {...this.props} />
      </OverlayLayout>
    )
  }
}

Lineups.propTypes = {
  currentStep: string
}

export default Lineups
