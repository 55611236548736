import { connect } from 'react-redux'

import withPrimus from '../../../../component/Primus/with-primus'
import { authenticate, change, resetErrors } from '../../actions'
import Form from '../component/LoginBox'

const mapStateToProps = state => state.profile.toJS()
const mapDispatchToProps = (dispatch, ownProps) => ({
  resetErrors: () => dispatch(resetErrors()),
  onSubmit: (e, primus) => {
    e.preventDefault()
    dispatch(authenticate(primus, ownProps.redirect))
  },
  onChange: e =>
    dispatch(
      change({
        [e.currentTarget.name]:
          e.currentTarget.type === 'checkbox'
            ? e.currentTarget.checked
            : e.currentTarget.value
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(Form))
