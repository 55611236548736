import React, { PureComponent } from 'react'
import { bool, node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from '../Profile.styl'

import ResponsiveMedia from '../../ResponsiveMedia'
import Type from '../../Type'

export class ProfileImage extends PureComponent {
  render() {
    const {
      imageAddon,
      imageMask,
      imageRatio,
      imageSrc,
      imageAlt,
      theme
    } = this.props
    return (
      <div
        className={classNames(
          styles.ProfileImage,
          theme && styles[`ProfileImage-${theme}`]
        )}
      >
        <div className={styles.ProfileImageInner}>
          <ResponsiveMedia ratio={imageRatio}>
            <img src={imageSrc} alt={imageAlt} />
          </ResponsiveMedia>
          {imageMask && <div className={styles.ProfileImageMask} />}
        </div>

        {imageAddon && (
          <div className={styles.ProfileImageAddon}>{imageAddon}</div>
        )}
      </div>
    )
  }
}

ProfileImage.displayName = 'ProfileImage'

ProfileImage.defaultProps = {
  imageAlt: '',
  imageRatio: 1
}

ProfileImage.propTypes = {
  imageAddon: node,
  imageAlt: string,
  imageMask: bool,
  imageRatio: number,
  imageSrc: string,
  theme: string
}

export class ProfileContent extends PureComponent {
  render() {
    const { children, contentAddon, description, heading, theme } = this.props
    return (
      <div
        className={classNames(
          styles.ProfileContent,
          theme && styles[`ProfileContent-${theme}`]
        )}
      >
        {contentAddon && (
          <div className={styles.ProfileContentAddon}>{contentAddon}</div>
        )}

        <div className={styles.ProfileContentBody}>
          {(heading || description) && (
            <div className={styles.ProfileContentDefaults}>
              <Type
                element="h3"
                size="display1"
                className={styles.ProfileContentBodyHeading}
                tight
              >
                {heading}
              </Type>
              {description && <Type size="tiny">{description}</Type>}
            </div>
          )}
          {children && (
            <div className={styles.ProfileContentAdditions}>{children}</div>
          )}
        </div>
      </div>
    )
  }
}

ProfileContent.displayName = 'ProfileContent'

ProfileContent.propTypes = {
  children: node,
  contentAddon: node,
  description: string,
  heading: string,
  theme: string
}
