import React, { Component } from 'react'
import { arrayOf, func, instanceOf, number, shape, string } from 'prop-types'
import styles from './PlayerSelector.styl'

import ButtonDisplay from '../ButtonDisplay'
import DecoratedTextControl from '../Control/DecoratedTextControl'
import Type from '../Type'
import IconButton from '../IconButton'
import Prose from '../Prose'
import Notification from '../Notification'
import ScrollArea from '../ScrollArea'
import Section from '../Section'
import SelectableItem from '../SelectableItem'
import TextAlign from '../TextAlign'
import VisuallyHidden from '../VisuallyHidden'

class PlayerSelector extends Component {
  constructor() {
    super()

    this.state = {
      selectedId: '',
      currentFilter: '',
      minutesRemaining: -1
    }

    this.handleFilter = this.handleFilter.bind(this)
    this.handleFilterClear = this.handleFilterClear.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.handleClearSelection = this.handleClearSelection.bind(this)
    this.getRemainingTime = this.getRemainingTime.bind(this)
    this.getNotificationText = this.getNotificationText.bind(this)
  }

  handleFilter(e) {
    const currentFilter = e.target.value
    this.setState({ currentFilter })
  }

  handleFilterClear() {
    this.setState({ currentFilter: '' })
  }

  handleSelection(e) {
    const value = e.target.value
    const selectedId = this.state.selectedId === value ? '' : value
    this.setState({ selectedId })
  }

  handleClearSelection() {
    this.setState({ selectedId: '' })
  }

  getRemainingTime() {
    const oneMinute = 1000 * 60
    const now = new Date().getTime()
    const then = this.props.voteCloseDate.getTime()
    const diff = Math.ceil((then - now) / oneMinute)
    this.setState({ minutesRemaining: diff })
  }

  getNotificationText() {
    if (this.state.minutesRemaining <= 1) return 'Voting is about to close'
    return `${this.state.minutesRemaining} minute${!!this.state
      .minutesRemaining !== 1 && 's'} left to vote`
  }

  componentDidMount() {
    if (this.props.voteCloseDate) {
      this.getRemainingTime()
      this.minutesTimer = setInterval(() => this.getRemainingTime(), 1000 * 10)
    }
  }

  componentWillUnmount() {
    clearInterval(this.minutesTimer)
  }

  render() {
    const { handleConfirmation, notificationMins, squad } = this.props
    const filteredSquad = squad.filter(player => {
      const formattedName = `${player.firstName} ${
        player.lastName
      }`.toLowerCase()
      const formattedFilter = this.state.currentFilter.toLowerCase()
      return formattedName.includes(formattedFilter)
    })

    return (
      <div className={styles.PlayerSelector}>
        <ScrollArea>
          <Section spacing="xt">
            <Type element="h1" size="display2">
              Stowford&nbsp;Press Man&nbsp;of&nbsp;the&nbsp;Match
            </Type>
          </Section>
          <Section spacing="y">
            <Section spacing="x">
              <form>
                <label>
                  <VisuallyHidden>Search</VisuallyHidden>
                  <DecoratedTextControl
                    name="player-selector-filter"
                    placeholder="Player search"
                    value={this.state.currentFilter}
                    onChange={this.handleFilter}
                    after={
                      !!this.state.currentFilter && (
                        <IconButton
                          type="button"
                          icon="Close"
                          a11yText="Clear"
                          onClick={this.handleFilterClear}
                          transparent
                        />
                      )
                    }
                    afterInteractive={!!this.state.currentFilter}
                  />
                </label>
              </form>
            </Section>
            {this.state.minutesRemaining >= 0 &&
              this.state.minutesRemaining <= notificationMins && (
                <Notification
                  icon="Timer"
                  className={styles.PlayerSelectorNotification}
                  children={this.getNotificationText()}
                />
              )}
          </Section>
          <Section grow>
            <form
              action=""
              className={styles.PlayerSelectorList}
              onChange={this.handleSelection}
            >
              {filteredSquad.length > 0 &&
                filteredSquad.map(player => (
                  <SelectableItem
                    key={`SelectableItem${player._id}`}
                    text={`${player.firstName} ${player.lastName}`}
                    description={player.position}
                    imageUrl={player.imgSrc}
                    value={player._id}
                    name="playerSelectorItems"
                  />
                ))}
              {filteredSquad.length < 1 && (
                <TextAlign center>
                  <Prose>
                    No matches found for{' '}
                    <strong>“{this.state.currentFilter}”</strong>
                  </Prose>
                </TextAlign>
              )}
            </form>
          </Section>
        </ScrollArea>

        {this.state.selectedId && (
          <div className={styles.PlayerSelectorConfirmation}>
            <ButtonDisplay
              block
              onClick={() => handleConfirmation(this.state.selectedId)}
            >
              Confirm
            </ButtonDisplay>
          </div>
        )}
      </div>
    )
  }
}

const playerShape = {
  _id: string.isRequired,
  imgSrc: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  position: string
}

PlayerSelector.defaultProps = {
  handleConfirmation: () => {
    console.log('Submitted')
  },
  notificationMins: 5
}

PlayerSelector.propTypes = {
  handleConfirmation: func,
  notificationMins: number,
  squad: arrayOf(shape(playerShape)).isRequired,
  voteCloseDate: instanceOf(Date)
}

export default PlayerSelector
