import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class ClickAndCollectTile extends PureComponent {
  render() {
    const { height } = this.props
    return (
      <Tile
        heading="Click & Collect"
        to="/activity/order/collect"
        imageUrl={image}
        height={height}
      />
    )
  }
}

ClickAndCollectTile.propTypes = {
  height: number
}

export default ClickAndCollectTile
