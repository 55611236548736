import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'

import Type from '../../../../../../component/Primitive/Type'
import ImageList from '../../../../../../component/Primitive/ImageList'
import Position from '../../../../../../component/Primitive/Position'
import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

const GalleryList = ({ images }) => (
  <OverlayLayout>
    <Section grow>
      <ScrollArea>
        <Section spacing="xy">
          <Type element="h1" size="display2">
            Fan Gallery
          </Type>
        </Section>
        <Position position="relative">
          <ImageList images={images} />
        </Position>
      </ScrollArea>
      <ButtonDisplay block to="/activity/play/gallery/source">
        Add a photo
      </ButtonDisplay>
    </Section>
  </OverlayLayout>
)

GalleryList.propTypes = {
  images: arrayOf(
    shape({
      url: string.isRequired,
      alt: string,
      ratio: number.isRequired
    })
  )
}

export default GalleryList
