exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShrinkWrap__ShrinkWrap___1Gafx{display:table;width:auto}.ShrinkWrap__fullwidth___3LlpW{width:100%}.ShrinkWrap__ShrinkWrapItem___3WYUn{display:table-cell}.ShrinkWrap__ShrinkWrapItem___3WYUn:not(:first-child){padding-left:8px}.ShrinkWrap__top___2yJnI .ShrinkWrap__ShrinkWrapItem___3WYUn{vertical-align:top}.ShrinkWrap__middle___3yNnT .ShrinkWrap__ShrinkWrapItem___3WYUn{vertical-align:middle}.ShrinkWrap__bottom___1mWqd .ShrinkWrap__ShrinkWrapItem___3WYUn{vertical-align:bottom}.ShrinkWrap__shrink___1MJVd{width:1px}", ""]);

// exports
exports.locals = {
	"ShrinkWrap": "ShrinkWrap__ShrinkWrap___1Gafx",
	"fullwidth": "ShrinkWrap__fullwidth___3LlpW",
	"ShrinkWrapItem": "ShrinkWrap__ShrinkWrapItem___3WYUn",
	"top": "ShrinkWrap__top___2yJnI",
	"middle": "ShrinkWrap__middle___3yNnT",
	"bottom": "ShrinkWrap__bottom___1mWqd",
	"shrink": "ShrinkWrap__shrink___1MJVd"
};