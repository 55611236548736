import React from 'react'

import Section from '../../../../../../component/Primitive/Section'
import Spinner from '../../../../../../component/Primitive/Spinner'
import TextAlign from '../../../../../../component/Primitive/TextAlign'

const MotmLoading = () => (
  <Section spacing="xy" grow center>
    <TextAlign center>
      <Spinner spinning revealDelay={2000} />
    </TextAlign>
  </Section>
)

export default MotmLoading
