import React, { PureComponent } from 'react'

import { func, string } from 'prop-types'
import ToolbarButton from './ToolbarButton'
import Icon from '../Icon'

import { createHashHistory as createHistory } from 'history'
const history = createHistory()

export class ToolbarBackButton extends PureComponent {
  constructor() {
    super()
    this.getIconType = this.getIconType.bind(this)
  }

  getIconType() {
    return window.device && window.device.platform === 'iOS'
      ? 'ArrowBackIos'
      : 'ArrowBack'
  }

  render() {
    const { onClick, to } = this.props
    return (
      <ToolbarButton to={to} {...!to && { onClick: onClick || history.goBack }}>
        <Icon type={this.getIconType()} /> Back
      </ToolbarButton>
    )
  }
}

ToolbarBackButton.displayName = 'ToolbarBackButton'

ToolbarBackButton.propTypes = {
  onClick: func,
  to: string
}

export default ToolbarBackButton
