import React, { PureComponent } from 'react'
import { node } from 'prop-types'

import styles from './SelectableText.styl'

export class SelectableText extends PureComponent {
  render() {
    const { children } = this.props
    return <span className={styles.SelectableText}>{children}</span>
  }
}

SelectableText.displayName = 'SelectableText'

SelectableText.propTypes = {
  children: node.isRequired
}

export default SelectableText
