import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

import Crest from '../../../../../../component/Primitive/Crest'
import Margin from '../../../../../../component/Primitive/Margin'
import Prose from '../../../../../../component/Primitive/Prose'
import ResponsiveTable from '../../../../../../component/Primitive/ResponsiveTable'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Section from '../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import Type from '../../../../../../component/Primitive/Type'

// Highlight home team on table
const homeTeamId = '1100'

const cols = [
  { title: 'Position', abbr: 'Pos' },
  { title: 'Team', abbr: 'Team' },
  { title: 'Played', abbr: 'Pl' },
  { title: 'Won', abbr: 'W', hideUntil: 320 },
  { title: 'Drawn', abbr: 'D', hideUntil: 320 },
  { title: 'Lost', abbr: 'L', hideUntil: 320 },
  { title: 'Points for', abbr: 'PF', hideUntil: 440 },
  { title: 'Points against', abbr: 'PA', hideUntil: 440 },
  { title: 'Points difference', abbr: 'Diff', hideUntil: 440 },
  { title: 'Tries for', abbr: 'TF', hideUntil: 530 },
  { title: 'Tries against', abbr: 'TA', hideUntil: 530 },
  { title: 'Tries bonus points', abbr: 'TBP', hideUntil: 660 },
  { title: 'Losing bonus points', abbr: 'LBP', hideUntil: 660 },
  { title: 'Total bonus points', abbr: 'BP', hideUntil: 660 },
  { title: 'League Points', abbr: 'Pts' }
]

const formatTeam = (team, i, teams) => {
  const highlighted = team.team === homeTeamId
  // Add separators for playoff/relegation cutoffs
  const separatedRows = [4, teams.length - 1]
  const separatorBelow = separatedRows.includes(team.position)
  const data = [
    team.position,
    <Margin my={-1}>
      <Crest club={team.name} constrain={36} a11yText={team.displayName} />
    </Margin>,
    team.played,
    team.won,
    team.drawn,
    team.lost,
    team.pointsFor,
    team.pointsAgainst,
    team.pointsDiff,
    team.triesFor,
    team.triesAgainst,
    team.triesBonusPoints,
    team.losingBonusPoints,
    team.totalBonusPoints,
    <strong>{team.points}</strong>
  ]
  return { data, highlighted, separatorBelow }
}

class Table extends PureComponent {
  render() {
    const rows = this.props.standings.map(formatTeam)
    return (
      <OverlayLayout>
        <ScrollArea>
          <Section spacing="xy">
            <SpacedList>
              <Type element="h1" size="display2">
                Gallagher Premiership Rugby Table
              </Type>
            </SpacedList>
          </Section>
          <Section grow>
            {rows.length === 0 && (
              <Section spacing="xy" grow center>
                <TextAlign center>
                  <Prose>
                    <p>Table data not available, check back soon.</p>
                  </Prose>
                </TextAlign>
              </Section>
            )}
            {rows.length > 0 && <ResponsiveTable cols={cols} rows={rows} />}
          </Section>
        </ScrollArea>
      </OverlayLayout>
    )
  }
}

Table.propTypes = {
  standings: PropTypes.array
}

export default Table
