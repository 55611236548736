import React, { PureComponent } from 'react'
import { node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from '../Profile.styl'

import { ProfileContent, ProfileImage } from '../component'

class CardProfile extends PureComponent {
  render() {
    const { theme } = this.props

    return (
      <div
        className={classNames(
          styles.Profile,
          theme && styles[`Profile-${theme}`],
          styles.card
        )}
      >
        <ProfileImage {...this.props} imageMask />
        <div className={styles.ProfileContentOverlay}>
          <ProfileContent {...this.props} />
        </div>
      </div>
    )
  }
}

CardProfile.displayName = 'CardProfile'

CardProfile.defaultProps = {
  imageAlt: '',
  imageRatio: 1
}

CardProfile.propTypes = {
  children: node,
  description: string,
  heading: node.isRequired,
  imageAlt: string,
  contentAddon: node,
  imageDecoration: node,
  imageRatio: number,
  imageSrc: string,
  theme: string
}

export default CardProfile
