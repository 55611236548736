import React, { Fragment } from 'react'
import { bool } from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'

import MainLayout from './component/Layout/MainLayout'

import Wifi from './view/Wifi'
import UserGate from './view/UserGate/container/Form'
import UserGateComplete from './view/UserGate/component/Complete'
import Notification from './view/Notification/'

import Featured from './view/Featured'
import Audio from './view/Audio'
import Activity from './view/Activity'

import LiveLineups from './view/Activity/component/Live/Lineups/Container/Lineups'
import LiveFeed from './view/Activity/component/Live/Feed/Container/Feed'
import LiveReview from './view/Activity/component/Live/Review'
import LiveTable from './view/Activity/component/Live/Table/Container/StandingsTable'

import PlayMotm from './view/Activity/component/Play/Motm/container'
import PlayGallery from './view/Activity/component/Play/Gallery/container/Gallery'
import PlayGallerySource from './view/Activity/component/Play/Gallery/container/Source'
import PlayGalleryForm from './view/Activity/component/Play/Gallery/container/Form'
import PlayGalleryThanks from './view/Activity/component/Play/Gallery/component/Thanks'
import PlayQuiz from './view/Activity/component/Play/Quiz'

import OrderCollect from './view/Activity/component/Order/Collect'

import HomeNews from './view/Activity/component/Home/News/Container'

import AccessEvents from './view/Activity/component/Access/Events/Container'

import AudioShows from './view/Audio/Shows/Container'

import Venue from './view/Venue'
import VenueInfo from './view/Venue/Info'
import VenueFacilities from './view/Venue/Facilities'

import Profile from './view/Profile/Profile/container/Profile'
import ProfileLogin from './view/Profile/Login/container/Login'
import ProfileRegister from './view/Profile/Register/container/Form'
import ProfileRegisterThanks from './view/Profile/Register/container/Thanks'
import ProfileUpdate from './view/Profile/Profile/container/Form'
import ProfilePasswordRequest from './view/Profile/Password/Request'
import ProfilePasswordSent from './view/Profile/Password/Sent'
import ProfilePasswordReset from './view/Profile/Password/Reset'
import ProfilePasswordComplete from './view/Profile/Password/Complete'

import Terms from './view/Terms'
import NotFound from './view/NotFound'

const Routes = ({ keyboardHidden }) => {
  return (
    <Fragment>
      <Switch>
        <Redirect exact from="/" to="/wifi" />
        <Route exact path="/wifi" component={Wifi} />
        <Route exact path="/notification" component={Notification} />
        <Route exact path="/gate" component={UserGate} />
        <Route exact path="/gate/complete" component={UserGateComplete} />
        <Route exact path="/terms" component={Terms} />
        <MainLayout keyboardHidden={keyboardHidden}>
          <Switch>
            <Route exact path="/featured" component={Featured} />

            <Route path="/audio" component={Audio} />

            <Redirect exact from="/activity" to="/activity/live" />
            <Route exact path="/activity/*" component={Activity} />

            <Route path="/venue" component={Venue} />

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/register" component={ProfileRegister} />
            <Route
              exact
              path="/profile/thanks"
              component={ProfileRegisterThanks}
            />
            <Route exact path="/profile/login" component={ProfileLogin} />
            <Route
              exact
              path="/profile/login/seat-serve"
              component={() => (
                <ProfileLogin
                  title="In-Seat Delivery"
                  redirect="/activity/order"
                  message={
                    <p>
                      To get food and drink delivered straight to your seat
                      please login or register.
                    </p>
                  }
                />
              )}
            />

            <Route exact path="/profile/update" component={ProfileUpdate} />
            <Route
              exact
              path="/profile/password/request"
              component={ProfilePasswordRequest}
            />
            <Route
              exact
              path="/profile/password/sent"
              component={ProfilePasswordSent}
            />
            <Route
              exact
              path="/profile/password/reset"
              component={ProfilePasswordReset}
            />
            <Route
              exact
              path="/profile/password/complete"
              component={ProfilePasswordComplete}
            />
            <Route component={NotFound} />
          </Switch>

          <Route path="/audio/show" component={AudioShows} />

          <Route exact path="/activity/live/lineups" component={LiveLineups} />
          <Route exact path="/activity/live/feed" component={LiveFeed} />
          <Route exact path="/activity/live/review" component={LiveReview} />
          <Route exact path="/activity/live/table" component={LiveTable} />
          <Route exact path="/activity/play/motm" component={PlayMotm} />

          <Route
            exact
            path="/activity/order/collect"
            component={OrderCollect}
          />

          <Route exact path="/activity/play/gallery" component={PlayGallery} />
          <Route
            exact
            path="/activity/play/gallery/source"
            component={PlayGallerySource}
          />
          <Route
            exact
            path="/activity/play/gallery/form"
            component={PlayGalleryForm}
          />
          <Route
            exact
            path="/activity/play/gallery/thanks"
            component={PlayGalleryThanks}
          />
          <Route exact path="/activity/play/form" component={PlayGalleryForm} />
          <Route path="/activity/play/quiz" component={PlayQuiz} />

          <Route path="/activity/home/news" component={HomeNews} />

          <Route path="/activity/access/events" component={AccessEvents} />

          <Route exact path="/venue/info" component={VenueInfo} />
          <Route exact path="/venue/facilities" component={VenueFacilities} />
        </MainLayout>
      </Switch>
    </Fragment>
  )
}

Routes.propTypes = {
  keyboardHidden: bool
}

export default Routes
