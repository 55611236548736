import React from 'react'
import { instanceOf } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import MotmPreviewTile from '../../../../../../component/Common/Tile/MotmPreview'

const MotmPreview = ({ startTime }) => {
  return (
    <Section grow>
      <ScrollArea>
        <Section grow>
          <MotmPreviewTile height={1} startTime={startTime} />
        </Section>
      </ScrollArea>
    </Section>
  )
}

MotmPreview.propTypes = {
  startTime: instanceOf(Date).isRequired
}
export default MotmPreview
