exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TileCustom__TileCustom___1xR4-{position:relative;overflow:hidden;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1 0 auto;flex:1 0 auto;min-height:-webkit-min-content;min-height:-moz-min-content;min-height:min-content;border-top:1px solid #fff}.TileCustom__TileCustom___1xR4-:first-child{border-top:none}.TileCustom__TileCustomLink___22rIE{z-index:1}.TileCustom__TileCustomImage___5EXxa,.TileCustom__TileCustomLink___22rIE{position:absolute;top:0;right:0;bottom:0;left:0}.TileCustom__TileCustomImage___5EXxa:after{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,.3)}.TileCustom__TileCustomInner___3cBgA{position:relative;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:16px 24px}.TileCustom__TileCustomContent___3TWeR{color:#fff}", ""]);

// exports
exports.locals = {
	"TileCustom": "TileCustom__TileCustom___1xR4-",
	"TileCustomLink": "TileCustom__TileCustomLink___22rIE",
	"TileCustomImage": "TileCustom__TileCustomImage___5EXxa",
	"TileCustomInner": "TileCustom__TileCustomInner___3cBgA",
	"TileCustomContent": "TileCustom__TileCustomContent___3TWeR"
};