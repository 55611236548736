import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import styles from './InlineVideo.styl'

import ResponsiveMedia from '../ResponsiveMedia'
import VideoEmbed from '../VideoEmbed'

export class InlineVideo extends PureComponent {
  render() {
    const { ratio } = this.props

    return (
      <div className={styles.InlineVideo}>
        <div className={styles.InlineVideoMedia}>
          <ResponsiveMedia ratio={ratio}>
            <VideoEmbed {...this.props} />
          </ResponsiveMedia>
        </div>
      </div>
    )
  }
}

InlineVideo.displayName = 'InlineVideo'

InlineVideo.defaultProps = {
  ratio: 9 / 16
}

InlineVideo.propTypes = {
  ratio: number
}

export default InlineVideo
