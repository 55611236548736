import createCrudActioner from '../../lib/crud-actioner'
export const ACTIVITY_MATCHES_ON_UPDATE = 'ACTIVITY_MATCHES_ON_UPDATE'

const { fetch: fetchMatches } = createCrudActioner({
  prefix: 'ACTIVITY_MATCHES',
  action: 'matches'
})

const onMatchesUpdate = matches => ({
  type: ACTIVITY_MATCHES_ON_UPDATE,
  matches
})

export { fetchMatches, onMatchesUpdate }
