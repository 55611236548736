import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import styles from '../Profile.styl'

import { ProfileContent, ProfileImage } from '../component'

class StandardProfile extends PureComponent {
  render() {
    const { theme } = this.props

    return (
      <div
        className={classNames(
          styles.Profile,
          theme && styles[`Profile-${theme}`]
        )}
      >
        <ProfileImage {...this.props} />
        <ProfileContent {...this.props} />
      </div>
    )
  }
}

StandardProfile.displayName = 'StandardProfile'

StandardProfile.propTypes = {
  theme: string
}

export default StandardProfile
