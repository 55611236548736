import React, { PureComponent } from 'react'
import { arrayOf, object } from 'prop-types'

import QuoteWidget from '../../../component/Common/Widget/Quote'
import TextWidget from '../../../component/Common/Widget/Text'
import InlineImageWidget from '../../../component/Common/Widget/InlineImage'
import InlineVideoWidget from '../../../component/Common/Widget/InlineVideo'

const factory = {
  text: TextWidget,
  inlineImage: InlineImageWidget,
  quote: QuoteWidget,
  video: InlineVideoWidget,
  default: () => {
    console.warn('Widget type not found')
  }
}

class WidgetArea extends PureComponent {
  render() {
    return this.props.widgets.map(widget => {
      const WidgetComponent = factory[widget.type]
        ? factory[widget.type]
        : factory.default
      return <WidgetComponent widget={widget} key={`widget-${widget.id}`} />
    })
  }
}

WidgetArea.propTypes = {
  widgets: arrayOf(object)
}

export default WidgetArea
