import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { any, number } from 'prop-types'
import styles from './Margin.styl'

const unit = parseInt(styles.MarginUnit, 10) || 8

class Margin extends PureComponent {
  render() {
    const { children, m, mx, my, mt, mr, mb, ml } = this.props

    const formattedValues = {
      ...(m && { margin: m * unit }),

      ...(mx && { marginLeft: mx * unit, marginRight: mx * unit }),
      ...(my && { marginTop: my * unit, marginBottom: my * unit }),

      ...(mt && { marginTop: mt * unit }),
      ...(ml && { marginLeft: ml * unit }),
      ...(mb && { marginBottom: mb * unit }),
      ...(mr && { marginRight: mr * unit })
    }

    return (
      <div className={classNames(styles.Margin)} style={formattedValues}>
        {children}
      </div>
    )
  }
}

Margin.displayName = 'Margin'

Margin.defaultProps = {}

Margin.propTypes = {
  children: any.isRequired,
  m: number,
  mx: number,
  my: number,
  mt: number,
  mr: number,
  mb: number,
  ml: number
}

export default Margin
