export default ({ prefix, action }) => {
  const create = (primus, data) => (dispatch, getState) => {
    dispatch({ type: `${prefix}_CREATE` })
    primus.writeAndWait(
      { action: `${action}:create`, data },
      ({ error, response }) => {
        if (error) return dispatch(createFailure(error))
        dispatch(createComplete(response))
      }
    )
  }

  const createComplete = data => ({
    type: `${prefix}_CREATE_COMPLETE`,
    data
  })

  const createFailure = error => ({
    type: `${prefix}_CREATE_FAILURE`,
    error: error.message,
    errorProperties: error.errors
  })

  const fetch = (primus, data) => dispatch => {
    dispatch({ type: `${prefix}_FETCH` })

    primus.writeAndWait(
      { action: `${action}:fetch`, data },
      ({ error, response }) => {
        if (error) return dispatch(fetchFailure(error))
        dispatch(fetchComplete(response))
      }
    )
  }

  const fetchComplete = data => ({
    type: `${prefix}_FETCH_COMPLETE`,
    data
  })

  const fetchFailure = error => ({
    type: `${prefix}_FETCH_FAILURE`,
    error: error.message,
    errorProperties: error.errors
  })

  const update = (primus, data) => dispatch => {
    dispatch({ type: `${prefix}_UPDATE` })

    primus.writeAndWait(
      { action: `${action}:update`, data },
      ({ error, response }) => {
        if (error) return dispatch(updateFailure(error))
        dispatch(updateComplete(response))
      }
    )
  }

  const updateComplete = data => ({
    type: `${prefix}_UPDATE_COMPLETE`,
    data
  })

  const updateFailure = error => ({
    type: `${prefix}_UPDATE_FAILURE`,
    error: error.message,
    errorProperties: error.errors
  })

  const resetError = () => ({
    type: `${prefix}_RESET_ERROR`
  })

  return {
    create,
    fetch,
    update,
    resetError
  }
}
