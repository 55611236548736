import React, { PureComponent } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './LinkList.styl'

import Icon from '../Icon'
import SmartLink from '../SmartLink'
import Type from '../Type'

export class LinkListItem extends PureComponent {
  render() {
    const { condensed, heading, description, to, theme } = this.props

    return (
      <SmartLink
        className={classNames(
          styles.LinkListItem,
          theme && styles[`LinkListItem-${theme}`]
        )}
        to={to}
      >
        <div className={styles.LinkListItemContent}>
          <Type
            element="h3"
            size={condensed ? 'base' : 'subtitle'}
            className={styles.LinkListItemHeading}
          >
            {heading}
          </Type>
          {description && (
            <Type
              className={styles.LinkListItemDescription}
              size={condensed ? 'tiny' : 'base'}
            >
              {description}
            </Type>
          )}
        </div>
        <div className={styles.LinkListItemAction}>
          <Icon type="ChevronRight" />
        </div>
      </SmartLink>
    )
  }
}

LinkListItem.displayName = 'LinkListItem'

LinkListItem.propTypes = {
  condensed: bool,
  heading: string.isRequired,
  description: string,
  to: string.isRequired,
  theme: string
}

export default withTheme(LinkListItem)
