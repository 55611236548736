import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Hero from '../../../../component/Primitive/Hero'
import Byline from '../../../../component/Primitive/Byline'
import Rule from '../../../../component/Primitive/Rule'
import AudioPlayer from '../../../../component/Primitive/AudioPlayer'
import Prose from '../../../../component/Primitive/Prose'

class Episode extends PureComponent {
  render() {
    const { episode, number } = this.props

    return (
      <Section grow>
        <ScrollArea>
          <Hero
            imageUrl={episode.imageUrl}
            heading={episode.title}
            category={''}
          />

          <Section spacing="xt">
            <SpacedList>
              <Byline
                displayDate={`Episode ${number}: ${moment(
                  episode.createdDate
                ).format('D MMMM YYYY')}`}
                timestamp={episode.createdDate}
              />
              <Rule muted />
              <AudioPlayer src={episode.audioUrl} />
              <Rule muted />
            </SpacedList>
          </Section>
          <Section spacing="xy">
            <Prose
              html={`<p>${episode.description.replace(/\n/g, '<br />')}</p>`}
            />
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Episode.propTypes = {
  episode: PropTypes.object,
  number: PropTypes.number
}

export default Episode
