import Immutable from 'immutable'
import {
  WIFI_CONNECT,
  WIFI_CONNECT_FAILURE,
  WIFI_CONNECT_COMPLETE,
  WIFI_CONNECT_INCORRECT_NETWORK,
  WIFI_GET_SSID,
  WIFI_GET_SSID_FAILURE,
  WIFI_GET_SSID_COMPLETE,
  WIFI_INCOMPATIBLE
} from './actions'

/*
 * Current Step:
 *  - loading - Current connected network unknown
 *  - ready - Connected to restrictedSSID
 *  - connecting - Connecting to unrestrictedSSID
 *  - complete - Connected to unrestrictedSSID
 *  - failed - Could not connect to unrestrictedSSID for any reason
 *  - unknown - Not connected to unrestrictedSSID
 *  - incompatible - Not connected to unrestrictedSSID
 */
const initialState = Immutable.fromJS({
  currentStep: 'loading',
  currentSSID: ''
})

const unrestrictedSSID = process.env.UNRESTRICTED_SSID
const restrictedSSID = process.env.RESTRICTED_SSID

const wifiReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIFI_CONNECT:
      return state.merge({
        currentStep: 'connecting'
      })
    case WIFI_INCOMPATIBLE:
      return state.merge({
        currentStep: 'incompatible'
      })
    case WIFI_CONNECT_COMPLETE:
      return state.merge({
        currentStep: 'complete'
      })
    case WIFI_CONNECT_FAILURE:
      return state.merge({
        currentStep: 'failed'
      })
    case WIFI_CONNECT_INCORRECT_NETWORK:
      return state.merge({
        currentStep: 'not-ready'
      })
    case WIFI_GET_SSID:
      return state.merge({
        currentStep: 'loading'
      })
    case WIFI_GET_SSID_COMPLETE:
      let currentStep = 'unknown'
      if (action.ssid === unrestrictedSSID) currentStep = 'complete'
      if (action.ssid === restrictedSSID) currentStep = 'ready'
      return state.merge({
        currentStep
      })
    case WIFI_GET_SSID_FAILURE:
      return state.merge({
        currentStep: 'unknown'
      })
    default:
      return state
  }
}

export default wifiReducer
