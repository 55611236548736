import { hide } from './actions'
const debug = require('debug')('keyboard')

const init = ({ dispatch }) => {
  debug('Init')
  if (window.Keyboard && window.Keyboard.hideFormAccessoryBar) {
    dispatch(hide())
    window.Keyboard.hideFormAccessoryBar(false)
    // window.addEventListener('keyboardWillShow', event => {
    //   debug('keyboard will show')
    //   dispatch(show())
    // })
    // window.addEventListener('keyboardWillHide', event => {
    //   debug('keyboard will hide')
    //   dispatch(hide())
    // })
    // window.addEventListener('keyboardDidShow', event => {
    //   debug('keyboard did show')
    //   window.Keyboard.hideFormAccessoryBar(false)
    // })
  }
}

export default init
