import React from 'react'
import { array, string } from 'prop-types'

import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Type from '../../../../../../component/Primitive/Type'
import Section from '../../../../../../component/Primitive/Section'
import ResultItem from '../../../../../../component/Primitive/ResultItem'
import ResultStat from '../../../../../../component/Primitive/ResultStat'
// import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
// import Panel from '../../../../../../component/Primitive/Panel'
import Margin from '../../../../../../component/Primitive/Margin'

const topLimit = 5

const MotmComplete = ({ myVote, voteLeaders }) => (
  <Section grow>
    <Section grow>
      <ScrollArea>
        <Section spacing="xy">
          <Type element="h1" size="display2">
            We have our winner
          </Type>
        </Section>
        <Section grow>
          {voteLeaders.slice(0, topLimit).map(player => (
            <ResultItem
              key={`ResultItem${player._id}`}
              text={`${player.firstName} ${player.lastName}`}
              description={player.position}
              imageUrl={player.imgSrc}
              selected={myVote === player._id}
            >
              <Margin mt={1}>
                <ResultStat>{player.votePercent}%</ResultStat>
              </Margin>
            </ResultItem>
          ))}
        </Section>
      </ScrollArea>
    </Section>
    {/* <Panel padding="xy">
      <ButtonDisplay block outline>
        Share Result
      </ButtonDisplay>
    </Panel> */}
  </Section>
)

MotmComplete.propTypes = {
  myVote: string,
  voteLeaders: array.isRequired
}

export default MotmComplete
