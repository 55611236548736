exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextImageLockup__TextImageLockup___3UB9R{width:100%}.TextImageLockup__TextImageLockupText___1bnmu{position:relative;max-width:calc(100% - 24px);display:inline-block;z-index:1;padding:8px 24px}.TextImageLockup__TextImageLockupImage___oQJaZ{margin-top:-24px}.TextImageLockup__TextImageLockup-base___s_55k .TextImageLockup__TextImageLockupText___1bnmu,.TextImageLockup__TextImageLockup-gloucester___1e22w .TextImageLockup__TextImageLockupText___1bnmu{background-color:#fff}.TextImageLockup__TextImageLockup-inverse___qWHAc .TextImageLockup__TextImageLockupText___1bnmu{background-color:#000}", ""]);

// exports
exports.locals = {
	"TextImageLockup": "TextImageLockup__TextImageLockup___3UB9R",
	"TextImageLockupText": "TextImageLockup__TextImageLockupText___1bnmu",
	"TextImageLockupImage": "TextImageLockup__TextImageLockupImage___oQJaZ",
	"TextImageLockup-base": "TextImageLockup__TextImageLockup-base___s_55k",
	"TextImageLockup-gloucester": "TextImageLockup__TextImageLockup-gloucester___1e22w",
	"TextImageLockup-inverse": "TextImageLockup__TextImageLockup-inverse___qWHAc"
};