import React, { PureComponent } from 'react'
import { array, object } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Event from '../Component/Event'

const debug = createDebug('event')

class EventContainer extends PureComponent {
  render() {
    const { id } = this.props.match.params
    const event = this.props.rawEvents.find(({ _id }) => _id === id)
    debug('rendering', id, event)
    if (!event) {
      return <h1>Not found</h1>
    }
    return <Event event={event} />
  }
}

EventContainer.propTypes = {
  match: object.isRequired,
  rawEvents: array.isRequired
}

const mapStateToProps = state => state.events.toJS()

export default connect(mapStateToProps)(withPrimus(EventContainer))
