import React from 'react'
import { func, bool } from 'prop-types'
import { HashRouter as Router } from 'react-router-dom'
import Routes from '../../Routes'
import { connect } from 'react-redux'

import { fetchMatches, onMatchesUpdate } from './actions'
import initRealtime from '../../lib/realtime'
import ThemeContext from '../../component/Theme/ThemeContext'
import PrimusContext from '../../component/Primus/PrimusContext'

import './AppBase.styl'
import './AppTypography.styl'

const realtimeUrl = process.env.REALTIME_URL

class App extends React.Component {
  constructor(props) {
    super(props)
    this.primus = initRealtime({ realtimeUrl })
    this.fetchMatches = this.fetchMatches.bind(this)
  }

  fetchMatches() {
    this.props.fetchMatches(this.primus)
  }

  componentDidMount() {
    this.fetchMatches()
    this.primus.on('matchesUpdate', this.props.onMatchUpdate)
    this.primus.on('reconnect', this.fetchMatches)
  }

  componentWillUnmount() {
    this.primus.off('matchUpdate', this.props.onMatchUpdate)
    this.primus.off('reconnect', () => this.fetchMatches)
  }

  render() {
    return (
      // TODO: Get theme name from somewhere dynamic
      <ThemeContext.Provider value="gloucester">
        <PrimusContext.Provider value={this.primus}>
          <Router>
            <Routes keyboardHidden={this.props.keyboardHidden} />
          </Router>
        </PrimusContext.Provider>
      </ThemeContext.Provider>
    )
  }
}

App.propTypes = {
  fetchMatches: func.isRequired,
  onMatchUpdate: func.isRequired,
  keyboardHidden: bool
}

const mapDispatchToProps = dispatch => ({
  fetchMatches: primus => dispatch(fetchMatches(primus)),
  onMatchUpdate: matches => dispatch(onMatchesUpdate(matches))
})

export default connect(
  state => ({ keyboardHidden: state.keyboard.get('hidden') }),
  mapDispatchToProps
)(App)
