import React, { PureComponent } from 'react'
import { object } from 'prop-types'
import formatDateTime from '../lib/date-formatter'

import Section from '../../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import LinkList from '../../../../../../component/Primitive/LinkList'
import LinkListItem from '../../../../../../component/Primitive/LinkList/LinkListItem'
import Prose from '../../../../../../component/Primitive/Prose'

class Events extends PureComponent {
  render() {
    const { events } = this.props

    return (
      <Section grow>
        <ScrollArea>
          <Section>
            <Section spacing="xy">
              <Type element="h1" size="display2">
                Upcoming Events
              </Type>
            </Section>
            <SpacedList>
              {Object.keys(events).length < 1 && (
                <Section spacing="x">
                  <Prose>
                    <p>No events are currently planned.</p>
                  </Prose>
                </Section>
              )}
              {Object.keys(events).length > 0 &&
                Object.keys(events).map(key => (
                  <LinkList key={`EventHeading${key}`} heading={key}>
                    {events[key].map(event => (
                      <LinkListItem
                        key={`Event${key}${event._id}`}
                        heading={event.title}
                        description={formatDateTime(event)}
                        to={`/activity/access/events/${event._id}`}
                      />
                    ))}
                  </LinkList>
                ))}
            </SpacedList>
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Events.propTypes = {
  events: object
}

export default Events
