exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResultItem__ResultItem___253Ep{display:block;position:relative;border-bottom:1px solid #d9d9d9}.ResultItem__ResultItem___253Ep:first-child{border-top:1px solid #d9d9d9}.ResultItem__ResultItemInner___ojIwE{padding:8px 16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ResultItem__ResultItemAvatar___1EXMp{position:relative}.ResultItem__ResultItemHeading___3kYk4{line-height:20px}.ResultItem__ResultItemDescription___2wlNp{font-size:11px;line-height:16px;text-transform:uppercase;font-weight:700}.ResultItem__ResultItemIndicator___gKVzH{position:absolute;top:50%;right:0;-webkit-transform:translate(50%,-50%);-ms-transform:translate(50%,-50%);transform:translate(50%,-50%);width:24px;height:24px;border:1px solid #add8e6;border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;overflow:hidden;background-color:#000}.ResultItem__ResultItemIndicator___gKVzH svg{-webkit-transform:translateX(-1px);-ms-transform:translateX(-1px);transform:translateX(-1px);color:#fff}.ResultItem__ResultItemContent___1Y60f{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:16px}.ResultItem__isSelected___3b-5H .ResultItem__ResultItemInner-base___3xB6R{background-color:#00cfdd;color:#fff}.ResultItem__isSelected___3b-5H .ResultItem__ResultItemInner-gloucester___CwOt5{background-color:#da1a32;color:#fff}.ResultItem__isSelected___3b-5H .ResultItem__ResultItemInner-inverse___2qOv0{background-color:#ff9800;color:#fff}", ""]);

// exports
exports.locals = {
	"ResultItem": "ResultItem__ResultItem___253Ep",
	"ResultItemInner": "ResultItem__ResultItemInner___ojIwE",
	"ResultItemAvatar": "ResultItem__ResultItemAvatar___1EXMp",
	"ResultItemHeading": "ResultItem__ResultItemHeading___3kYk4",
	"ResultItemDescription": "ResultItem__ResultItemDescription___2wlNp",
	"ResultItemIndicator": "ResultItem__ResultItemIndicator___gKVzH",
	"ResultItemContent": "ResultItem__ResultItemContent___1Y60f",
	"isSelected": "ResultItem__isSelected___3b-5H",
	"ResultItemInner-base": "ResultItem__ResultItemInner-base___3xB6R",
	"ResultItemInner-gloucester": "ResultItem__ResultItemInner-gloucester___CwOt5",
	"ResultItemInner-inverse": "ResultItem__ResultItemInner-inverse___2qOv0"
};