import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'

import Field, {
  FieldQuestionWithoutLabel,
  FieldAnswer,
  // FieldRequired,
  FieldAssistance,
  FieldFeedback
} from '../../Field'
import VisuallyHidden from '../../VisuallyHidden'

class MultiTextFieldTemplate extends PureComponent {
  render() {
    const {
      assistance,
      children,
      controlName,
      feedback,
      hideLabel,
      inverse,
      label,
      // required,
      status
    } = this.props

    const MultiTextFieldTemplateQuestion = () => (
      <FieldQuestionWithoutLabel>{label}</FieldQuestionWithoutLabel>
    )
    return (
      <Field id={`field--${controlName}`} status={status} template="text">
        {hideLabel ? (
          <VisuallyHidden>
            <MultiTextFieldTemplateQuestion />
          </VisuallyHidden>
        ) : (
          <MultiTextFieldTemplateQuestion />
        )}
        <FieldAnswer>{children}</FieldAnswer>
        {assistance && <FieldAssistance>{assistance}</FieldAssistance>}
        {feedback && (
          <FieldFeedback inverse={inverse}>{feedback}</FieldFeedback>
        )}
      </Field>
    )
  }
}
MultiTextFieldTemplate.propTypes = {
  assistance: node,
  children: node.isRequired,
  controlName: string.isRequired,
  feedback: node,
  hideLabel: bool,
  inverse: bool,
  label: node.isRequired,
  // required: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}
export default MultiTextFieldTemplate
