import Immutable from 'immutable'
import createCrudReducer from '../../../../../lib/crud-reducer'

const initialState = Immutable.fromJS({
  currentStep: 'loading',
  articles: [],
  lastFetchArticles: -Infinity
})

const fetchArticlesMapper = articles => ({
  articles: articles,
  lastFetchArticles: Date.now()
})

const dataReducer = createCrudReducer({
  prefix: 'ARTICLES',
  fetchMapper: fetchArticlesMapper
})

const articlesReducer = (state = initialState, action) => {
  switch (action) {
    default:
      return dataReducer(state, action)
  }
}

export default articlesReducer
