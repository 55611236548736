import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import Section from '../../Primitive/Section'
import Prose from '../../Primitive/Prose'

export class TextWidget extends PureComponent {
  render() {
    const { widget } = this.props
    const { html } = widget

    return (
      <Section spacing="x">
        <Prose html={html} />
      </Section>
    )
  }
}

TextWidget.propTypes = {
  widget: object
}

export default TextWidget
