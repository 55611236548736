import React, { Fragment, PureComponent } from 'react'
import { object } from 'prop-types'
import { Route } from 'react-router-dom'
import SwipeableRoutes from 'react-swipeable-routes'

import Section from '../../component/Primitive/Section'
import Toolbar from '../../component/Primitive/Toolbar'
import ScrollNav from '../../component/Primitive/ScrollNav'
import ScrollNavItem from '../../component/Primitive/ScrollNav/ScrollNavItem'

import Live from './component/Live'
import Access from './component/Access'
import Order from './component/Order'
import Play from './component/Play'
import Home from './component/Home'

const ToolbarNav = ({ location }) => {
  return (
    <Toolbar>
      <ScrollNav pathname={location.pathname}>
        <ScrollNavItem to="/activity/live">Live</ScrollNavItem>
        <ScrollNavItem to="/activity/access">Access</ScrollNavItem>
        <ScrollNavItem to="/activity/order">Order</ScrollNavItem>
        <ScrollNavItem to="/activity/play">Play</ScrollNavItem>
        <ScrollNavItem to="/activity/home">Home</ScrollNavItem>
      </ScrollNav>
    </Toolbar>
  )
}

ToolbarNav.propTypes = {
  location: object.isRequired
}

class Activity extends PureComponent {
  render() {
    return (
      <Fragment>
        <Section>
          <Route component={ToolbarNav} />
        </Section>

        <Section grow fullHeight>
          <SwipeableRoutes
            style={{
              display: 'flex',
              flex: '1 0 auto'
            }}
            containerStyle={{
              display: 'flex',
              flex: '1 0 auto',
              width: '100%'
            }}
            slideStyle={{
              display: 'flex',
              flex: '1 0 auto',
              position: 'relative',
              width: '100%'
            }}
          >
            <Route path="/activity/live" component={Live} />
            <Route path="/activity/access" component={Access} />
            <Route path="/activity/order" component={Order} />
            <Route path="/activity/play" component={Play} />
            <Route path="/activity/home" component={Home} />
          </SwipeableRoutes>
        </Section>
      </Fragment>
    )
  }
}

export default Activity
