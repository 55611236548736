import React, { PureComponent } from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './AudioPlayer.styl'

import Audio from '../Audio'
import MediaControls from '../MediaControls'
import MediaScrubber from '../MediaScrubber'
import MediaVolume from '../MediaVolume'

export class AudioPlayer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      audioState: 'loading',
      duration: 0,
      progress: 0,
      volume: null
    }

    this.setNewRef = this.setNewRef.bind(this)

    this.handleCanPlay = this.handleCanPlay.bind(this)
    this.handleTogglePlayState = this.handleTogglePlayState.bind(this)
    this.handleUpdatePlayState = this.handleUpdatePlayState.bind(this)
    this.handleVolumeEvent = this.handleVolumeEvent.bind(this)
    this.handleVolumeChange = this.handleVolumeChange.bind(this)
    this.handleTimeJump = this.handleTimeJump.bind(this)
    this.handleTimeScrub = this.handleTimeScrub.bind(this)
    this.handleTimeEvent = this.handleTimeEvent.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
  }

  setNewRef(el) {
    this.audio = el
  }

  handleCanPlay(e) {
    this.setState({ duration: this.audio.duration })

    // Avoid duplicate 'canplay' which occasionally occur after playback starts
    if (this.state.audioState !== 'loading') return

    this.handleUpdatePlayState(e)
  }

  handleTogglePlayState() {
    if (this.audio.paused) {
      this.audio.play()
      this.setState({ audioState: 'play' })
    } else {
      this.audio.pause()
      this.setState({ audioState: 'pause' })
    }
  }

  handleUpdatePlayState(e) {
    this.setState({ audioState: e.type })
  }

  handleVolumeChange(volume) {
    this.setState({ volume })
    this.audio.volume = volume / 100 || 0
  }

  handleVolumeEvent(e) {
    // This doesn't currently account for the e.currentTarget.muted property
    const volume = Math.round(e.currentTarget.volume * 100)
    if (volume === this.state.volume) return
    this.handleVolumeChange(volume)
  }

  handleTimeJump(changeAmount) {
    const targetTime = this.audio.currentTime + changeAmount
    this.audio.currentTime = targetTime
  }

  handleTimeEvent(e) {
    this.handleTimeChange(e.currentTarget.currentTime)
  }

  handleTimeScrub(progress) {
    this.audio.currentTime = progress
    this.handleTimeChange(progress)
  }

  handleTimeChange(progress) {
    this.setState({ progress })
  }

  componentDidMount() {
    this.audio.addEventListener('canplay', this.handleCanPlay)
    this.audio.addEventListener('play', this.handleUpdatePlayState)
    this.audio.addEventListener('pause', this.handleUpdatePlayState)
    this.audio.addEventListener('ended', this.handleUpdatePlayState)
    this.audio.addEventListener('timeupdate', this.handleTimeEvent)

    if (this.props.volume) {
      this.audio.addEventListener('volumechange', this.handleVolumeEvent)
      this.handleVolumeChange(this.audio.volume * 100)
    }
  }

  componentWillUnmount() {
    this.audio.removeEventListener('canplay', this.handleCanPlay)
    this.audio.removeEventListener('play', this.handleUpdatePlayState)
    this.audio.removeEventListener('pause', this.handleUpdatePlayState)
    this.audio.removeEventListener('ended', this.handleUpdatePlayState)
    this.audio.removeEventListener('timeupdate', this.handleTimeEvent)

    if (this.props.volume) {
      this.audio.removeEventListener('volumechange', this.handleVolumeEvent)
    }
  }

  render() {
    const { volume } = this.props
    return (
      <div className={classNames(styles.AudioPlayer)}>
        <MediaScrubber
          duration={this.state.duration}
          progress={this.state.progress}
          onChange={this.handleTimeScrub}
        />

        <MediaControls
          onTogglePlayState={this.handleTogglePlayState}
          onReplay={() => {
            this.handleTimeJump(-10)
          }}
          onForwards={() => {
            this.handleTimeJump(30)
          }}
          playing={this.state.audioState === 'play'}
        />

        {volume && (
          <MediaVolume
            volume={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        )}

        <Audio setRef={this.setNewRef} {...this.props} />
      </div>
    )
  }
}

AudioPlayer.displayName = 'AudioPlayer'

AudioPlayer.propTypes = {
  children: node,
  volume: bool
}

export default AudioPlayer
