import React, { PureComponent } from 'react'
import { func, number } from 'prop-types'

import styles from './MediaScrubber.styl'

import formatTime from '../../../lib/time-formatter'

import Type from '../Type'
import RangeControl from '../Control/RangeControl'

import ShrinkWrap, { ShrinkWrapItem } from '../ShrinkWrap'

export class MediaScrubber extends PureComponent {
  constructor() {
    super()

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.onChange(e.currentTarget.value)
  }

  render() {
    const { duration, progress } = this.props

    return (
      <div className={styles.MediaScrubber}>
        <ShrinkWrap fullwidth>
          <ShrinkWrapItem shrink>
            <Type size="tiny">{formatTime(progress)}</Type>
          </ShrinkWrapItem>
          <ShrinkWrapItem>
            <RangeControl
              min="0"
              step="0.25"
              max={`${Math.floor(duration)}`}
              onChange={this.handleChange}
              value={`${progress}`}
              name="scrubber"
              condensed
            />
          </ShrinkWrapItem>
          <ShrinkWrapItem shrink>
            <Type size="tiny">{formatTime(duration)}</Type>
          </ShrinkWrapItem>
        </ShrinkWrap>
      </div>
    )
  }
}

MediaScrubber.displayName = 'MediaScrubber'

MediaScrubber.defaultProps = {
  progress: 0,
  duration: 0
}

MediaScrubber.propTypes = {
  duration: number,
  onChange: func,
  progress: number
}

export default MediaScrubber
