import React, { Fragment } from 'react'
import { node } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import Prose from '../../../../../../component/Primitive/Prose'

const MotmError = ({ error }) => (
  <Fragment>
    <Section spacing="xy" grow>
      <TextAlign center>
        <Prose>
          <p>{error}</p>
        </Prose>
      </TextAlign>
    </Section>
  </Fragment>
)

MotmError.propTypes = {
  error: node.isRequired
}

export default MotmError
