import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './ResultItem.styl'

import Avatar from '../Avatar/'
import Type from '../Type/'
import Icon from '../Icon/'
import Prose from '../Prose/'

export class ResultItem extends PureComponent {
  render() {
    const {
      children,
      description,
      imageUrl,
      selected,
      text,
      theme
    } = this.props

    return (
      <div
        className={classNames(
          styles.ResultItem,
          theme && styles[`ResultItem-${theme}`],
          selected && styles.isSelected
        )}
      >
        <div
          className={classNames(
            styles.ResultItemInner,
            theme && styles[`ResultItemInner-${theme}`]
          )}
        >
          <div className={styles.ResultItemAvatar}>
            <Avatar name={text} src={imageUrl} size={56} />
            {selected && (
              <div className={styles.ResultItemIndicator}>
                <Icon type="Check" width={16} />
              </div>
            )}
          </div>
          <div className={styles.ResultItemContent}>
            <Type
              element="h3"
              size="display1"
              className={styles.ResultItemHeading}
            >
              {text}
            </Type>
            {description && (
              <div className={styles.ResultItemDescription}>
                <Prose>{description}</Prose>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    )
  }
}

ResultItem.displayName = 'ResultItem'

ResultItem.propTypes = {
  children: node,
  description: string,
  imageUrl: string,
  selected: bool,
  text: string.isRequired,
  theme: string
}

export default withTheme(ResultItem)
