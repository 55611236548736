import React, { PureComponent } from 'react'
import { number } from 'prop-types'
import classNames from 'classnames'

import styles from './QuizResult.styl'

import Type from '../Type'
import Prose from '../Prose'
import Rule from '../Rule'
import SpacedList from '../SpacedList'

export class QuizResult extends PureComponent {
  render() {
    const { total, correct } = this.props
    const score = Math.ceil((correct / total) * 100)
    return (
      <div className={classNames(styles.QuizResult)}>
        <SpacedList>
          <div>
            <Type size="title">You scored</Type>
            <div className={styles.QuizResultScore}>{score}%</div>
            <Prose>
              By answering {correct} out of {total} questions correctly
            </Prose>
          </div>
          <Rule muted />
        </SpacedList>
      </div>
    )
  }
}

QuizResult.displayName = 'QuizResult'

QuizResult.propTypes = {
  total: number,
  correct: number
}

export default QuizResult
