import React, { PureComponent } from 'react'
import { bool, oneOf, string } from 'prop-types'
import ReactJWPlayer from 'react-jw-player'

const createVideoIframe = (srcPrefix, videoId, query) => (
  <iframe
    src={srcPrefix + videoId + (query || '')}
    width="500"
    height="281"
    frameBorder="0"
    allowFullScreen
  />
)

export const embedVideo = (provider, videoId, showRelatedVideos) => {
  switch (provider) {
    case 'youtube':
      const rel = showRelatedVideos ? 1 : 0
      return createVideoIframe(
        'https://www.youtube.com/embed/',
        videoId,
        `?showinfo=0&playsinline=1&rel=${rel}`
      )
    case 'vimeo':
      return createVideoIframe('https://player.vimeo.com/video/', videoId)
    case 'jwplayer':
      return (
        <ReactJWPlayer
          playerId={`video-${Math.random()
            .toString(36)
            .substr(2)}`}
          playlist={`https://content.jwplatform.com/feeds/${videoId}.json`}
          playerScript={`https://content.jwplatform.com/libraries/8rqal5Ga.js`}
        />
      )
    default:
      return ''
  }
}

export class InlineVideo extends PureComponent {
  render() {
    const { videoId, provider, showRelatedVideos } = this.props
    return embedVideo(provider, videoId, showRelatedVideos)
  }
}

InlineVideo.displayName = 'InlineVideo'

InlineVideo.propTypes = {
  videoId: string,
  provider: oneOf(['youtube', 'vimeo', 'jwplayer']),
  showRelatedVideos: bool
}

export default InlineVideo
