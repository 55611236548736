import createCrudReducer from '../../../../../lib/crud-reducer'
import reduceReducers from 'reduce-reducers'

const fetchStandingsReducer = createCrudReducer({
  prefix: 'STANDINGS',
  fetchMapper: data => ({
    standings: data
  })
})

export default reduceReducers(fetchStandingsReducer)
