exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Blockquote__Blockquote___154WA{font-style:normal;border-left:1px solid;padding-left:16px}.Blockquote__Blockquote___154WA *{font-size:24px;line-height:32px;font-weight:300}.Blockquote__quotemarks___11zWK .Blockquote__BlockquoteQuote___2jC-G:before{content:open-quote}.Blockquote__quotemarks___11zWK .Blockquote__BlockquoteQuote___2jC-G:after{content:close-quote}.Blockquote__BlockquoteCitation___2GEwq{display:block;margin-top:8px;font-style:normal;font-size:11px;line-height:16px;text-transform:uppercase;font-weight:700}.Blockquote__Blockquote-base___Z04tt{border-left-color:#00cfdd}.Blockquote__Blockquote-base___Z04tt .Blockquote__BlockquoteCitation___2GEwq{color:#00cfdd}.Blockquote__Blockquote-gloucester___Ibfaw{border-left-color:#da1a32}.Blockquote__Blockquote-gloucester___Ibfaw .Blockquote__BlockquoteCitation___2GEwq{color:#da1a32}.Blockquote__Blockquote-inverse___2BZ9H{border-left-color:#ff9800}.Blockquote__Blockquote-inverse___2BZ9H .Blockquote__BlockquoteCitation___2GEwq{color:#ff9800}", ""]);

// exports
exports.locals = {
	"Blockquote": "Blockquote__Blockquote___154WA",
	"quotemarks": "Blockquote__quotemarks___11zWK",
	"BlockquoteQuote": "Blockquote__BlockquoteQuote___2jC-G",
	"BlockquoteCitation": "Blockquote__BlockquoteCitation___2GEwq",
	"Blockquote-base": "Blockquote__Blockquote-base___Z04tt",
	"Blockquote-gloucester": "Blockquote__Blockquote-gloucester___Ibfaw",
	"Blockquote-inverse": "Blockquote__Blockquote-inverse___2BZ9H"
};