import React, { PureComponent } from 'react'

import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Type from '../../../../component/Primitive/Type'
import SmartLink from '../../../../component/Primitive/SmartLink'

class PasswordComplete extends PureComponent {
  render() {
    return (
      <ScrollArea>
        <Section spacing="xy">
          <SpacedList>
            <Type element="h1" size="display2">
              Password Reset
            </Type>
            <Prose>
              <p>Your new password has been saved.</p>
              <p>
                You’ll now be able to{' '}
                <SmartLink to="/profile/login">login</SmartLink> with your new
                password.
              </p>
            </Prose>
          </SpacedList>
        </Section>
      </ScrollArea>
    )
  }
}

export default PasswordComplete
