export default () => [
  {
    name: 'newsletterConsent',
    label:
      'I would like to get @Holm emails from Gloucester Rugby with the latest news, updates and exclusives'
  },
  {
    name: 'promotionsConsent',
    label:
      'I would like up to date information, exclusive offers, promotions and reminders through the app - all personal to me'
  }
]
