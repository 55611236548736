import { connect } from 'react-redux'
import Title from './component/Tile'
import { push } from 'react-router-redux'
import withPrimus from 'src/component/Primus/with-primus'
const createUrl = token =>
  `https://kingsholm.seatserve.com/app/#/login/${token}`

const mapDispatchToProps = dispatch => ({
  onClick: (primus, emailAddress, key) => {
    if (emailAddress) {
      primus.writeAndWait(
        { action: `seatServe:auth`, data: { emailAddress, key } },
        ({ error, response }) => {
          if (error) return alert(error)
          console.log(response)
          window.location = createUrl(response)
        }
      )
    } else {
      dispatch(push('/profile/login/seat-serve'))
    }
  }
})

export default connect(
  state => ({
    emailAddress: state.profile.getIn(['user', 'emailAddress']),
    key: state.profile.getIn(['user', 'key'])
  }),
  mapDispatchToProps
)(withPrimus(Title))
