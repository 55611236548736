import React, { PureComponent, Children } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'
import scrollIntoView from 'scroll-into-view'
import withTheme from '../../Theme/withTheme'
import styles from './ScrollNav.styl'

class ScrollNav extends PureComponent {
  constructor() {
    super()

    this.setNewRef = this.setNewRef.bind(this)
    this.scrollActiveIntoView = this.scrollActiveIntoView.bind(this)
  }

  setNewRef(el) {
    this.scrollList = el
  }

  componentDidMount() {
    this.scrollActiveIntoView()
  }

  componentDidUpdate() {
    this.scrollActiveIntoView()
  }

  scrollActiveIntoView() {
    !!this.scrollList && scrollIntoView(this.scrollList, { time: 300 })
  }

  render() {
    const { pathname, children, theme } = this.props

    return (
      <div
        className={classNames(
          styles.ScrollNav,
          theme && styles[`ScrollNav-${theme}`]
        )}
      >
        <ul className={styles.ScrollNavList}>
          {children &&
            Children.map(children, (child, i) => {
              const active = !!pathname && pathname.includes(child.props.to)
              return React.cloneElement(child, {
                ...(active && { setRef: this.setNewRef }),
                active
              })
            })}
        </ul>
      </div>
    )
  }
}

ScrollNav.displayName = 'ScrollNav'

ScrollNav.propTypes = {
  pathname: string,
  children: node,
  theme: string
}

export default withTheme(ScrollNav)
