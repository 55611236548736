import Immutable from 'immutable'
import reduceReducers from 'reduce-reducers'

import { ACTIVITY_MATCHES_ON_UPDATE } from './actions'
import createCrudReducer from '../../lib/crud-reducer'
const defaultState = {
  last: null,
  live: null,
  next: null
}
const initialState = Immutable.fromJS(defaultState)

const fetchMatchesReducer = createCrudReducer({
  prefix: 'ACTIVITY_MATCHES'
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_MATCHES_ON_UPDATE:
      return state.merge({ ...defaultState, ...action.matches })
    default:
      return state
  }
}

export default reduceReducers(reducer, fetchMatchesReducer)
