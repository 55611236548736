exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Position__absolute___3oRuj{position:absolute}.Position__fixed___CZEB7{position:fixed}.Position__relative___dugxQ{position:relative}.Position__static___2uY1u{position:static}.Position__sticky___2mmBd{position:-webkit-sticky;position:sticky}", ""]);

// exports
exports.locals = {
	"absolute": "Position__absolute___3oRuj",
	"fixed": "Position__fixed___CZEB7",
	"relative": "Position__relative___dugxQ",
	"static": "Position__static___2uY1u",
	"sticky": "Position__sticky___2mmBd"
};