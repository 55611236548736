import React, { PureComponent } from 'react'
import { oneOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../../../Theme/withTheme'
import styles from './CustomCheckControl.styl'

import NativeCheckControl from '../NativeCheckControl'

export class CustomCheckControl extends PureComponent {
  render() {
    const { status, theme, type } = this.props

    const componentClassName = classNames(
      styles.CustomCheckControl,
      theme && styles[`CustomCheckControl-${theme}`],
      type && styles[type],
      status && styles[status]
    )

    return (
      <span className={componentClassName}>
        <NativeCheckControl
          className={classNames(
            styles.CustomCheckControlControl,
            theme && styles[`CustomCheckControlControl-${theme}`]
          )}
          {...this.props}
        />
        <span
          className={classNames(
            styles.CustomCheckControlIndicator,
            theme && styles[`CustomCheckControlIndicator-${theme}`]
          )}
        />
      </span>
    )
  }
}

CustomCheckControl.displayName = 'CustomCheckControl'

CustomCheckControl.propTypes = {
  status: oneOf(['error', 'notice', 'success', 'warning']),
  type: oneOf(['checkbox', 'radio']).isRequired,
  theme: string
}

export default withTheme(CustomCheckControl)
