import React from 'react'

const onComponentDidMount = (fn, Component) => {
  class Container extends React.Component {
    componentDidMount() {
      fn(this.props)
    }
    render() {
      return <Component {...this.props} />
    }
  }
  return Container
}

export default onComponentDidMount
