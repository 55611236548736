import { push } from 'react-router-redux'
export const PROFILE_ERROR_RESET = 'PROFILE_ERROR_RESET'
export const PROFILE_FORM_CHANGE = 'PROFILE_FORM_CHANGE'
export const PROFILE_REGISTER_SUBMIT = 'PROFILE_REGISTER_SUBMIT'
export const PROFILE_SUBMIT_FAILURE = 'PROFILE_SUBMIT_FAILURE'
export const PROFILE_LOGIN_SUBMIT = 'PROFILE_LOGIN_SUBMIT'
export const PROFILE_LOGIN_FAILURE = 'PROFILE_LOGIN_SUBMIT'
export const PROFILE_UPDATE_SUBMIT = 'PROFILE_UPDATE_SUBMIT'
export const PROFILE_AUTHENTICATE_SUCCESS = 'PROFILE_AUTHENTICATE_SUCCESS'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_DEAUTHENTICATE = 'PROFILE_DEAUTHENTICATE'

export const resetErrors = data => ({
  type: PROFILE_ERROR_RESET
})

export const change = data => ({
  type: PROFILE_FORM_CHANGE,
  data
})

export const register = (primus, data) => async (dispatch, getState) => {
  dispatch({ type: 'PROFILE_REGISTER_SUBMIT' })
  const data = getState().profile.toJS().data
  primus.writeAndWait(
    { action: 'user:register', data },
    ({ error, response }) => {
      if (error) return dispatch(submitFailure(error))
      dispatch(authenticateSuccess(response))
      dispatch(push('/profile/thanks'))
    }
  )
}

export const submitFailure = error => {
  const message =
    error.message === 'Validation Error'
      ? 'There was a problem with your form'
      : error.message
  return {
    type: PROFILE_SUBMIT_FAILURE,
    error: message,
    errorProperties: error.errors || {}
  }
}

export const update = (primus, data) => async (dispatch, getState) => {
  dispatch({ type: 'PROFILE_UPDATE_SUBMIT' })
  const profile = getState().profile.toJS()
  primus.writeAndWait(
    {
      action: 'user:update',
      data: { data: profile.data, key: profile.user.key }
    },
    ({ error, response }) => {
      if (error) return dispatch(updateFailure(error))
      dispatch(updateSuccess(response))
    }
  )
}

export const updateFailure = error => {
  const message =
    error.message === 'Validation Error'
      ? 'There was a problem with your form'
      : error.message
  return {
    type: PROFILE_SUBMIT_FAILURE,
    error: message,
    errorProperties: error.errors || {}
  }
}

export const authenticate = (primus, redirect) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'PROFILE_LOGIN_SUBMIT' })
  const data = getState().profile.toJS().data
  primus.writeAndWait(
    { action: 'user:authenticate', data },
    ({ error, response }) => {
      if (error) return dispatch(submitFailure(error))
      window.analytics.setUser(response._id)
      dispatch(authenticateSuccess(response))
      dispatch(push(redirect || '/profile'))
    }
  )
}

export const authenticateSuccess = user => ({
  type: PROFILE_AUTHENTICATE_SUCCESS,
  user
})

export const updateSuccess = user => ({
  type: PROFILE_UPDATE_SUCCESS,
  user
})

export const deauthenticate = () => ({
  type: PROFILE_DEAUTHENTICATE
})
