const debug = require('debug')('device-shim')

const init = window => {
  debug('Init')
  if (!window.device) {
    window.device = {
      cordova: '',
      model: '',
      platform: '',
      device: '',
      uuid: '',
      version: '',
      manufacturer: '',
      isVirtual: '',
      serial: ''
    }
  }
}

export default init
