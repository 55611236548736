exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AnimatedRoute__AnimatedRoute___2yxEI{position:absolute;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"AnimatedRoute": "AnimatedRoute__AnimatedRoute___2yxEI"
};