exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InlineVideo__InlineVideoMedia___3kCdM{background-color:#d9d9d9;padding-left:24px;padding-right:24px}", ""]);

// exports
exports.locals = {
	"InlineVideoMedia": "InlineVideo__InlineVideoMedia___3kCdM"
};