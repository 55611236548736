import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import { Route } from 'react-router-dom'
import { Transition, animated } from 'react-spring'

import styles from './AnimatedRoute.styl'

export class AnimatedRoute extends PureComponent {
  render() {
    const { children } = this.props

    return (
      <Route
        render={({ location }) => (
          <Transition
            native
            items={location}
            keys={location => location.pathname}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0, pointerEvents: 'none' }}
            config={{ duration: 100 }}
          >
            {location => style => (
              <animated.div className={styles.AnimatedRoute} style={style}>
                {children(location)}
              </animated.div>
            )}
          </Transition>
        )}
      />
    )
  }
}

AnimatedRoute.propTypes = {
  children: func
}

export default AnimatedRoute
