import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import withPrimus from '../../../../component/Primus/with-primus'
import { deauthenticate } from '../../actions'
import Profile from '../component/Profile'

const mapStateToProps = state => state.profile.toJS()
const mapDispatchToProps = dispatch => ({
  onLogOut: () => {
    dispatch(deauthenticate())
    dispatch(push('/profile/login'))
  },
  onReset: () => {
    if (!confirm('Reset app')) return null
    Object.keys(localStorage)
      .filter(key => key.includes('persist'))
      .forEach(key => localStorage.removeItem(key))
    dispatch({
      type: 'RESET_ALL'
    })
    document.location = '/'
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(Profile))
