import React, { PureComponent } from 'react'
import { string, array, object, func } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import { fetchLineups } from '../actions'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Section from '../../../../../../component/Primitive/Section'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import Spinner from '../../../../../../component/Primitive/Spinner'
import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'
import Lineups from '../Component/Lineups'

const debug = createDebug('lineups')

class LineupsContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.primus = this.props.primus
  }

  componentDidMount() {
    debug('Fetching matches')
    this.props.fetchLineups(this.primus)
  }

  render() {
    const { currentStep } = this.props

    if (currentStep === 'team') {
      return <Lineups {...this.props} />
    } else {
      return (
        <OverlayLayout>
          <Section spacing="xy" grow center>
            <TextAlign center>
              <Spinner spinning revealDelay={2000} />
            </TextAlign>
          </Section>
        </OverlayLayout>
      )
    }
  }
}

LineupsContainer.propTypes = {
  primus: object.isRequired,
  fetchLineups: func.isRequired,
  currentStep: string,
  rating: array,
  rateProgress: array // ?
}

const mapStateToProps = state => ({ ...state.lineups.toJS() })
const mapDispatchToProps = dispatch => ({
  fetchLineups: primus => dispatch(fetchLineups(primus))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(LineupsContainer))
