import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import styles from '../Profile.styl'

import { ProfileContent } from '../component'

class MiniProfile extends PureComponent {
  render() {
    const { theme } = this.props

    return (
      <div
        className={classNames(
          styles.Profile,
          theme && styles[`Profile-${theme}`],
          styles.mini
        )}
      >
        <ProfileContent {...this.props} />
      </div>
    )
  }
}

MiniProfile.displayName = 'MiniProfile'

MiniProfile.propTypes = {
  theme: string
}

export default MiniProfile
