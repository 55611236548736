exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DecoratedTextControl__DecoratedTextControl___Ha9hT{position:relative}.DecoratedTextControl__DecoratedTextControlDecoration___nH78M{position:absolute;top:0;bottom:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:48px;pointer-events:none;overflow:hidden}.DecoratedTextControl__DecoratedTextControlDecoration___nH78M:first-child{left:0}.DecoratedTextControl__DecoratedTextControlDecoration___nH78M:last-child{right:0}.DecoratedTextControl__DecoratedTextControlDecoration___nH78M.DecoratedTextControl__interactive___3Z-Fj{pointer-events:auto}.DecoratedTextControl__before___3FNE9 .DecoratedTextControl__DecoratedTextControlControl___2cEQ5{padding-left:48px}.DecoratedTextControl__after___QRC84 .DecoratedTextControl__DecoratedTextControlControl___2cEQ5{padding-right:48px}.DecoratedTextControl__DecoratedTextControl-base___3lHFW{color:#000}.DecoratedTextControl__DecoratedTextControl-gloucester___1B5nE{color:#54585a}.DecoratedTextControl__DecoratedTextControl-inverse___3Cv0k{color:#fff}", ""]);

// exports
exports.locals = {
	"DecoratedTextControl": "DecoratedTextControl__DecoratedTextControl___Ha9hT",
	"DecoratedTextControlDecoration": "DecoratedTextControl__DecoratedTextControlDecoration___nH78M",
	"interactive": "DecoratedTextControl__interactive___3Z-Fj",
	"before": "DecoratedTextControl__before___3FNE9",
	"DecoratedTextControlControl": "DecoratedTextControl__DecoratedTextControlControl___2cEQ5",
	"after": "DecoratedTextControl__after___QRC84",
	"DecoratedTextControl-base": "DecoratedTextControl__DecoratedTextControl-base___3lHFW",
	"DecoratedTextControl-gloucester": "DecoratedTextControl__DecoratedTextControl-gloucester___1B5nE",
	"DecoratedTextControl-inverse": "DecoratedTextControl__DecoratedTextControl-inverse___3Cv0k"
};