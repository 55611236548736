import React from 'react'
import { array, func, instanceOf } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section'
import PlayerSelector from '../../../../../../component/Primitive/PlayerSelector'

const MotmActive = ({ handleConfirmation, squad, voteCloseDate }) => (
  <Section grow>
    <PlayerSelector
      squad={squad}
      handleConfirmation={handleConfirmation}
      voteCloseDate={voteCloseDate}
    />
  </Section>
)

MotmActive.propTypes = {
  handleConfirmation: func.isRequired,
  squad: array.isRequired,
  voteCloseDate: instanceOf(Date)
}

export default MotmActive
