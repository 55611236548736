import React, { PureComponent } from 'react'

import Position from '../../../../component/Primitive/Position'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import TileGrid from '../../../../component/Primitive/TileGrid'

import ClickAndCollect from '../../../../component/Common/Tile/ClickAndCollect'
import SeatServe from '../../../../component/Common/Tile/SeatServe'

class Order extends PureComponent {
  render() {
    return (
      <Position position="absolute" style={{ width: '100%', height: '100%' }}>
        <Section grow fullHeight>
          <ScrollArea>
            <TileGrid>
              <ClickAndCollect height={1 / 3} />
              <SeatServe height={1 / 3} />
            </TileGrid>
          </ScrollArea>
        </Section>
      </Position>
    )
  }
}

export default Order
