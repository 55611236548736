import { PureComponent } from 'react'
import { func, string } from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

class Redirect extends PureComponent {
  componentDidMount() {
    this.props.push(this.props.to)
  }
  render() {
    return null
  }
}

Redirect.propTypes = {
  push: func,
  to: string
}

export default connect(
  null,
  dispatch => ({
    push: to => dispatch(push(to))
  })
)(Redirect)
