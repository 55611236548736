exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Icon__Icon___28Y6G{display:inline-block;vertical-align:baseline;font-style:normal}.Icon__Icon___28Y6G svg{vertical-align:bottom;height:auto;width:inherit;fill:currentColor}.Icon__success___1Ftga svg{fill:#4caf50}.Icon__notice___4Jela svg{fill:#2196f3}.Icon__warning___Wj2H_ svg{fill:#ff9800}.Icon__error___349Fs svg{fill:#da1a32}", ""]);

// exports
exports.locals = {
	"Icon": "Icon__Icon___28Y6G",
	"success": "Icon__success___1Ftga",
	"notice": "Icon__notice___4Jela",
	"warning": "Icon__warning___Wj2H_",
	"error": "Icon__error___349Fs"
};