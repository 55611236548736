import createCrudReducer from '../../../../../lib/crud-reducer'
import { QUIZ_FORM_CHANGE, QUIZ_INIT } from './actions'
import reduceReducers from 'reduce-reducers'

// TODO: If you change the shape of the state up this number.
const version = 1
const fetchReducer = createCrudReducer({
  createMapper: (response, state) => ({
    data: { ...state.toJS().data, terms: false },
    errorProperties: {}
  }),
  prefix: 'QUIZ',
  defaultState: {
    version,
    questions: [],
    preTitle: '',
    title: '',
    fullTitle: '',
    sell: '',
    description: '',
    publishedDate: null,
    openDate: null,
    closeDate: null,
    terms: '',
    closedMessage: '',
    enterMessage: '',
    data: {},
    loggedIn: false
  }
})

const reducer = (state, action) => {
  if (state.get('version') !== version) {
    return fetchReducer(undefined, {})
  }
  switch (action.type) {
    case QUIZ_INIT:
      return state.set('successfulCreate', false).mergeIn(['data'], action.data)
    case QUIZ_FORM_CHANGE:
      return state.mergeIn(['data'], action.data)
  }
  return state
}

export default reduceReducers(fetchReducer, reducer)
