import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import InlineImage from '../../Primitive/InlineImage'

export class InlineImageWidget extends PureComponent {
  render() {
    const { widget } = this.props
    const { caption, imageUrl, title } = widget

    return <InlineImage imageUrl={imageUrl} caption={caption} alt={title} />
  }
}

InlineImageWidget.propTypes = {
  widget: object
}

export default InlineImageWidget
