import React from 'react'
import { func, string, object, bool } from 'prop-types'

import Notification from '../../../../component/Primitive/Notification'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import CheckControl from '../../../../component/Primitive/Control/CheckControl'
import Prose from '../../../../component/Primitive/Prose'
import Form from '../../../../component/Primitive/Form'
import SpacedList from '../../../../component/Primitive/SpacedList'
import FieldTemplate from '../../../../component/Primitive/FieldTemplate'
import SmartLink from '../../../../component/Primitive/SmartLink'
import TextControl from '../../../../component/Primitive/Control/TextControl'
import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import Type from '../../../../component/Primitive/Type'
import TextAlign from '../../../../component/Primitive/TextAlign'
import Rule from '../../../../component/Primitive/Rule'
import ShrinkWrap, {
  ShrinkWrapItem
} from '../../../../component/Primitive/ShrinkWrap'

import ConsentOptions from '../../component/ContentOptions'

const Profile = ({
  onChange,
  onSubmit,
  onLogOut,
  error,
  errorProperties,
  data,
  user,
  authenticated,
  primus
}) => (
  <Section grow>
    <ScrollArea>
      <Section spacing="xt" grow>
        {authenticated ? (
          <SpacedList>
            <Type element="h1" size="display2">
              Hi, {user.firstName}
            </Type>
            {error && (
              <Notification icon="Error" status="error">
                {error}
                <ul>
                  {Object.keys(errorProperties).map(property => (
                    <li key={`profile-register-${property}`}>
                      {errorProperties[property]}
                    </li>
                  ))}
                </ul>
              </Notification>
            )}
            <Form onSubmit={e => onSubmit(e, primus)}>
              <FieldTemplate
                controlName="firstName"
                label="First name"
                status={errorProperties.firstName && 'error'}
                required
                feedback={errorProperties.firstName}
              >
                <TextControl
                  name="firstName"
                  autoComplete="given-name"
                  status={errorProperties.firstName && 'error'}
                  onChange={onChange}
                  value={data.firstName}
                  required
                />
              </FieldTemplate>

              <FieldTemplate
                controlName="lastName"
                label="Last name"
                required
                feedback={errorProperties.lastName}
                status={errorProperties.lastName && 'error'}
              >
                <TextControl
                  name="lastName"
                  autoComplete="family-name"
                  status={errorProperties.lastName && 'error'}
                  onChange={onChange}
                  value={data.lastName}
                  required
                />
              </FieldTemplate>

              <FieldTemplate
                controlName="emailAddress"
                label="Email Address"
                feedback={errorProperties.emailAddress}
                status={errorProperties.emailAddress && 'error'}
                required
              >
                <TextControl
                  name="emailAddress"
                  type="email"
                  autoComplete="email"
                  status={errorProperties.emailAddress && 'error'}
                  onChange={onChange}
                  value={data.emailAddress}
                  required
                />
              </FieldTemplate>
              <ConsentOptions
                errorProperties={errorProperties}
                data={data}
                onChange={onChange}
              />
              {!user.termsAgreed && (
                <FieldTemplate
                  controlName="termsAgreed"
                  template="check"
                  required
                  status={errorProperties.termsAgreed && 'error'}
                  feedback={errorProperties.termsAgreed}
                >
                  <CheckControl
                    type="checkbox"
                    name="termsAgreed"
                    value="termsAgreed"
                    checked={!!data.termsAgreed}
                    status={errorProperties.termsAgreed && 'error'}
                    onChange={onChange}
                  >
                    <Prose>
                      I agree to the{' '}
                      <SmartLink to="/terms">Terms and conditions</SmartLink>
                    </Prose>
                  </CheckControl>
                </FieldTemplate>
              )}
              <SpacedList>
                <ButtonDisplay block type="submit">
                  Update
                </ButtonDisplay>
                <ShrinkWrap fullwidth>
                  <ShrinkWrapItem>
                    <a onClick={() => alert('Coming soon')}>Change Password</a>
                  </ShrinkWrapItem>
                  <ShrinkWrapItem>
                    <TextAlign right>
                      <a onClick={onLogOut}>Log out</a>
                    </TextAlign>
                  </ShrinkWrapItem>
                </ShrinkWrap>
              </SpacedList>
            </Form>
          </SpacedList>
        ) : (
          <p>Not logged in</p>
        )}
      </Section>
      <Section spacing="xy">
        <Rule muted spaced />
        <TextAlign center>
          <Type size="tiny">Powered by Colooder</Type>
        </TextAlign>
      </Section>
    </ScrollArea>
  </Section>
)
Profile.propTypes = {
  onChange: func,
  onSubmit: func,
  onLogOut: func,
  error: string,
  errorProperties: object,
  data: object,
  user: object,
  authenticated: bool,
  primus: object
}

export default Profile
