import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ShowTile from '../../../../component/Common/Tile/Show'
import withPrimus from '../../../../component/Primus/with-primus'

import { fetchShows } from '../actions'

class ShowsListContainer extends Component {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
  }

  componentDidMount() {
    this.props.fetchShows(this.primus)
  }

  render() {
    return (
      <Fragment>
        {this.props.shows.map((show, i) => (
          <ShowTile
            key={`show-${i}`}
            title={show.title}
            showId={show._id}
            imageUrl={show.imageUrl}
          />
        ))}
      </Fragment>
    )
  }
}

ShowsListContainer.propTypes = {
  primus: PropTypes.object.isRequired,
  fetchShows: PropTypes.func.isRequired,
  shows: PropTypes.array
}

ShowsListContainer.defaultProps = {
  shows: []
}

const mapStateToProps = state => state.shows.toJS()
const mapDispatchToProps = dispatch => ({
  fetchShows: primus => dispatch(fetchShows(primus))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(ShowsListContainer))
