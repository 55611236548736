import React, { PureComponent } from 'react'
import { array, func, number, object, shape, string } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import {
  fetchMatches,
  newCommentary,
  updateCommentary,
  deleteCommentary,
  onMatchUpdate,
  onScoresUpdate
} from '../actions'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Feed from '../Component/Feed'

const debug = createDebug('liveFeed')

class FeedContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
    this.onConnect = this.onConnect.bind(this)
  }

  componentDidMount() {
    debug('Refreshing matches, registering handlers')
    this.props.fetchMatches(this.primus)
    this.primus.on('open', this.onConnect)
    this.primus.on('newCommentary', this.props.newCommentary)
    this.primus.on('commentary', this.props.updateCommentary)
    this.primus.on('deleteCommentary', this.props.deleteCommentary)
    this.primus.on('matchUpdate', this.props.onMatchUpdate)
    this.primus.on('updateScore', this.props.onScoresUpdate)

    this.onConnect()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.live && this.props.live) {
      debug('Did update: Emit matchDayLive')
      this.primus.send('matchDayLive', this.props.live._id)
    }
  }

  onConnect() {
    if (this.props.live) {
      debug('Did mount: Emit matchDayLive')
      this.primus.send('matchDayLive', this.props.live._id)
    }
  }

  componentWillUnmount() {
    debug('Removing handlers')
    this.primus.off('open', this.onConnect)
    this.primus.off('newCommentary', this.props.newCommentary)
    this.primus.off('commentary', this.props.updateCommentary)
    this.primus.off('deleteCommentary', this.props.deleteCommentary)
    this.primus.off('matchUpdate', this.props.onMatchUpdate)
    this.primus.off('updateScore', this.props.onScoresUpdate)
  }

  render() {
    if (!this.props.live) {
      return <h1>no match live</h1>
    }
    return (
      <Feed
        commentary={this.props.commentary}
        match={this.props.live}
        scores={this.props.scores}
      />
    )
  }
}

FeedContainer.propTypes = {
  primus: object.isRequired,
  fetchMatches: func.isRequired,
  newCommentary: func.isRequired,
  updateCommentary: func.isRequired,
  deleteCommentary: func.isRequired,
  onMatchUpdate: func.isRequired,
  onScoresUpdate: func.isRequired,
  live: shape({
    _id: string
  }),
  commentary: array,
  scores: shape({
    home: number,
    away: number
  })
}

const mapStateToProps = state => ({ ...state.feed.toJS() })
const mapDispatchToProps = dispatch => ({
  fetchMatches: primus => dispatch(fetchMatches(primus)),
  newCommentary: commentary => dispatch(newCommentary(commentary)),
  updateCommentary: commentary => dispatch(updateCommentary(commentary)),
  deleteCommentary: commentaryId => dispatch(deleteCommentary(commentaryId)),
  onMatchUpdate: match => dispatch(onMatchUpdate(match)),
  onScoresUpdate: scores => dispatch(onScoresUpdate(scores))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(FeedContainer))
