import React, { PureComponent } from 'react'
import { array } from 'prop-types'

import Comment from '../../Primitive/Comment'

const icons = {
  text: null,
  conversion: 'Conversion',
  halfTime: 'Whistle',
  try: 'RugbyBall',
  kickOff: 'Whistle',
  redCard: 'SentOff',
  substitution: 'Substitution',
  yellowCard: 'SinBin',
  dropGoal: 'DropGoal',
  tweet: 'Twitter',
  penalty: 'Penalty',
  fullTime: 'Whistle',

  // PromoTypes
  drinks: 'NotificationsActive',
  motm: 'Person',
  quiz: 'HelpOutline'
}

export class Commentary extends PureComponent {
  render() {
    const { items } = this.props

    if (!items.length) return null

    return items.map(item => (
      <Comment
        key={`CommentaryItem${item._id}`}
        type={item.type}
        icon={item.type === 'promo' ? icons[item.promoType] : icons[item.type]}
        a11yText={item.text}
        modifiers={item.modifiers}
        heading={item.heading}
        description={item.text}
        socialId={item.socialId}
        {...!['promo', 'tweet'].includes(item.type) && {
          minute: item.minute
        }}
      />
    ))
  }
}

Commentary.propTypes = {
  items: array
}

export default Commentary
