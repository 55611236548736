import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import Cover from '../../../../../../component/Primitive/Cover'

class QuizThanks extends PureComponent {
  render() {
    return (
      <Cover
        crestClub="Gloucester"
        crestDisplayName="Gloucester Rugby"
        preTitle={this.props.preTitle}
        title={this.props.title}
        cta="Close"
        to="/featured"
      >
        {this.props.thanks}
      </Cover>
    )
  }
}

QuizThanks.propTypes = {
  preTitle: string,
  title: string,
  thanks: string
}

export default QuizThanks
