import debug from 'debug'
import Primus from '../../../realtime/vendor/primus'
const log = debug('realtime:log')
const error = debug('realtime:error')

export default ({ realtimeUrl }) => {
  let primus

  try {
    primus = new Primus(realtimeUrl)
  } catch (e) {
    return error('Error creating primus connection', e, e.stack)
  }

  primus.on('open', () => {
    log('Connected to realtime server')
  })

  primus.on('error', err => {
    error('Error from realtime server', err.stack)
  })

  primus.on('incoming::data', data => {
    log('Data from server', data)
  })

  primus.on('disconnection', () => log('disconnected'))
  primus.on('end', () => log('end'))
  primus.on('reconnect failed', () => log('reconnect failed'))
  return primus
}
