import { connect } from 'react-redux'

import { submit, change } from '../actions'
import { push } from 'react-router-redux'
import Form from '../component/Form'

const mapStateToProps = state => state.userGate.toJS()
const mapDispatchToProps = (dispatch, ownProps) => ({
  onAlreadyChecked: () => dispatch(push('/featured')),
  onSubmit: () => dispatch(submit()),
  onChange: e =>
    dispatch(
      change({
        [e.currentTarget.name]:
          e.currentTarget.type === 'checkbox'
            ? e.currentTarget.checked
            : e.currentTarget.value
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
