import React, { PureComponent } from 'react'
import { node } from 'prop-types'

import styles from './TileGrid.styl'

class TileGrid extends PureComponent {
  render() {
    const { children } = this.props

    return <div className={styles.TileGrid}>{children}</div>
  }
}

TileGrid.displayName = 'TileGrid'

TileGrid.propTypes = {
  children: node
}

export default TileGrid
