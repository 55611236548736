import { connect } from 'react-redux'
import {
  change,
  noCamera,
  setImage,
  setError,
  validate,
  clearErrors
} from '../actions'
import onComponentDidMount from '../../../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../../../component/Primus/with-primus'
import FormComponent from '../component/Form'

const getImageFromSource = ({ onNoCamera, onImage, onError, source }) => {
  if (!navigator.camera) return onNoCamera()
  const sources = {
    camera: window.Camera.PictureSourceType.CAMERA,
    photoLibrary: window.Camera.PictureSourceType.PHOTOLIBRARY
  }
  navigator.camera.getPicture(onImage, onError, {
    destinationType: window.Camera.DestinationType.DATA_URL,
    sourceType: sources[source]
  })
}

const mapStateToProps = state => state.gallery.toJS()

const mapDispatchToProps = dispatch => ({
  onChange: data => dispatch(change(data)),
  onNoCamera: () => dispatch(noCamera()),
  onImage: data => dispatch(setImage(data)),
  onError: error => dispatch(setError(error)),
  onSubmit: primus => dispatch(validate(primus)),
  clean: () => dispatch(clearErrors())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(onComponentDidMount(getImageFromSource, FormComponent)))
