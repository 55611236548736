exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResponsiveTable__ResponsiveTableTable___3Vlye{width:100%;border-collapse:collapse}", ""]);

// exports
exports.locals = {
	"ResponsiveTableTable": "ResponsiveTable__ResponsiveTableTable___3Vlye"
};