import React from 'react'
import { func, bool } from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import ButtonDisplay from '../../component/Primitive/ButtonDisplay'
import Icon from '../../component/Primitive/Icon'
import Margin from '../../component/Primitive/Margin'
import MinimalLayout from '../../component/Layout/MinimalLayout'
import Prose from '../../component/Primitive/Prose'
import ScrollArea from '../../component/Primitive/ScrollArea'
import Section from '../../component/Primitive/Section'
import Separator from '../../component/Primitive/Separator'
import SpacedList from '../../component/Primitive/SpacedList'
import TextAlign from '../../component/Primitive/TextAlign'
import Type from '../../component/Primitive/Type'

import Delay from 'react-delay'

import { subscribeAll, ignore } from './actions'

class Notification extends React.Component {
  componentWillMount() {
    if (this.props.asked) this.props.onAlreadyAsked()
  }
  render() {
    const { onSubscribe, onIgnore } = this.props
    return (
      <MinimalLayout primary>
        <Delay wait={600}>
          <ScrollArea>
            <Section spacing="xy" center grow>
              <TextAlign center>
                <Icon type="NotificationsActive" height={96} />
                <Type element="h1" size="title">
                  Stay updated
                </Type>
                <Margin my={3}>
                  <Separator />
                </Margin>
                <Prose>
                  <p>
                    Gloucester Rugby would like to send you alerts directly to
                    your mobile for Match Action and Stadium Promotions.
                  </p>
                </Prose>
              </TextAlign>
            </Section>
            <Section spacing="xy">
              <SpacedList>
                <ButtonDisplay block onClick={onSubscribe}>
                  Yes please
                </ButtonDisplay>
                <ButtonDisplay block outline onClick={onIgnore}>
                  No thanks
                </ButtonDisplay>
              </SpacedList>
            </Section>
          </ScrollArea>
        </Delay>
      </MinimalLayout>
    )
  }
}

Notification.propTypes = {
  asked: bool,
  onSubscribe: func,
  onIgnore: func,
  onAlreadyAsked: func
}

const mapDispatchToProps = dispatch => ({
  onSubscribe: () => {
    dispatch(subscribeAll())
    dispatch(push('/gate'))
  },
  onIgnore: () => {
    dispatch(ignore())
    dispatch(push('/gate'))
  },
  onAlreadyAsked: () => {
    dispatch(push('/gate'))
  }
})

export default connect(
  state => state.notification.toJS(),
  mapDispatchToProps
)(Notification)
