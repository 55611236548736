import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'

import ShrinkWrap, { ShrinkWrapItem } from '../ShrinkWrap'
import IconButton from '../IconButton'
import TextAlign from '../TextAlign'

export class MediaControls extends PureComponent {
  render() {
    const { onReplay, onForwards, onTogglePlayState, playing } = this.props

    return (
      <ShrinkWrap fullwidth>
        {onReplay && (
          <ShrinkWrapItem>
            <TextAlign right>
              <IconButton
                icon="Replay10"
                a11yText="Replay 10 seconds"
                onClick={onReplay}
                rounded
                small
              />
            </TextAlign>
          </ShrinkWrapItem>
        )}
        <ShrinkWrapItem shrink>
          <IconButton
            icon={playing ? 'Pause' : 'Play'}
            a11yText={playing ? 'Pause' : 'Play'}
            onClick={onTogglePlayState}
            rounded
          />
        </ShrinkWrapItem>
        {onForwards && (
          <ShrinkWrapItem>
            <IconButton
              icon="Forward30"
              a11yText="Forwards 30 seconds"
              onClick={onForwards}
              rounded
              small
            />
          </ShrinkWrapItem>
        )}
      </ShrinkWrap>
    )
  }
}

MediaControls.displayName = 'MediaControls'

MediaControls.propTypes = {
  onReplay: func,
  onForwards: func,
  onTogglePlayState: func.isRequired,
  playing: bool
}

export default MediaControls
