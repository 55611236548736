exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BackgroundImage__BackgroundImage___2RhDA{width:100%;height:0;padding-bottom:100%;background-repeat:no-repeat;background-position:50% 50%;background-size:cover}.BackgroundImage__fullsize___1jfhY{height:100%!important;width:100%!important;padding-bottom:0!important}", ""]);

// exports
exports.locals = {
	"BackgroundImage": "BackgroundImage__BackgroundImage___2RhDA",
	"fullsize": "BackgroundImage__fullsize___1jfhY"
};