import React, { PureComponent } from 'react'

import Position from '../../../../component/Primitive/Position'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import TileGrid from '../../../../component/Primitive/TileGrid'

import MotmTile from '../../../../component/Common/Tile/Motm'
import QuizTile from '../../../../component/Common/Tile/Quiz'
import GalleryTile from '../../../../component/Common/Tile/Gallery'

class Play extends PureComponent {
  render() {
    return (
      <Position position="absolute" style={{ width: '100%', height: '100%' }}>
        <Section grow fullHeight>
          <ScrollArea>
            <TileGrid>
              <MotmTile height={1 / 3} />
              <QuizTile height={1 / 3} />
              <GalleryTile height={1 / 3} />
            </TileGrid>
          </ScrollArea>
        </Section>
      </Position>
    )
  }
}

export default Play
