exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Square__Square___x8Xq0{display:inline-block;text-align:center;vertical-align:middle}.Square__size5___2JxGx{min-width:40px;min-height:40px;padding:8px}.Square__size5___2JxGx,.Square__size7___2iDvm{line-height:24px}.Square__size7___2iDvm{min-width:56px;min-height:56px;padding:16px}.Square__Square-base___38rIW,.Square__Square-gloucester___3Fn2u{background-color:#000;color:#fff}.Square__Square-inverse___3xW3D{background-color:#fff;color:#000}", ""]);

// exports
exports.locals = {
	"sizes": "5 7",
	"Square": "Square__Square___x8Xq0",
	"size5": "Square__size5___2JxGx",
	"size7": "Square__size7___2iDvm",
	"Square-base": "Square__Square-base___38rIW",
	"Square-gloucester": "Square__Square-gloucester___3Fn2u",
	"Square-inverse": "Square__Square-inverse___3xW3D"
};