import React, { Fragment } from 'react'
import { func, oneOf } from 'prop-types'
import calcPassword from '../../../lib/password-calculator'

import MinimalLayout from '../../../component/Layout/MinimalLayout'
import ScrollArea from '../../../component/Primitive/ScrollArea'
import Type from '../../../component/Primitive/Type'
import Section from '../../../component/Primitive/Section'
import Prose from '../../../component/Primitive/Prose'
import Icon from '../../../component/Primitive/Icon'
import TextAlign from '../../../component/Primitive/TextAlign'
import ButtonDisplay from '../../../component/Primitive/ButtonDisplay'
import ButtonBase from '../../../component/Primitive/ButtonBase'
import Separator from '../../../component/Primitive/Separator'
import Margin from '../../../component/Primitive/Margin'
import SpacedList from '../../../component/Primitive/SpacedList'
import Rule from '../../../component/Primitive/Rule'
import SelectableText from '../../../component/Primitive/SelectableText'
import PlatformSpecific from '../../../component/Cordova/PlatformSpecific'

const unrestrictedSSID = process.env.UNRESTRICTED_SSID
const unrestrictedPasskey =
  process.env.UNRESTRICTED_PASSKEY ||
  calcPassword(process.env.WIFI_PASSWORD_SECRET).password

class Wifi extends React.Component {
  componentDidMount() {
    this.props.getConnectedSSID()
  }

  render() {
    const { currentStep, onConnect } = this.props
    return (
      <MinimalLayout primary>
        <Section grow>
          <ScrollArea>
            <Section spacing="xt" center grow>
              <TextAlign center>
                <Icon type="Wifi" height={96} />
                <SpacedList>
                  <Type element="h1" size="title">
                    Welcome to <br />
                    Gloucester Rugby
                  </Type>
                  <Prose>
                    <p>
                      With this app on your phone you will always have access to
                      our state-of-the-art Wi-Fi
                    </p>
                  </Prose>
                </SpacedList>
                <Margin my={3}>
                  <Separator />
                </Margin>
                <Prose>
                  {currentStep === 'loading' && (
                    <p>
                      We’re checking if you can access our state-of-the-art
                      Wi-Fi
                    </p>
                  )}
                  {currentStep === 'ready' && <p>High-speed Wi-Fi available</p>}
                  {currentStep === 'connecting' && (
                    <p>Connecting, please wait…</p>
                  )}
                  {currentStep === 'failed' && (
                    <div>
                      <p>We couldn’t connect you.</p>
                      <PlatformSpecific platforms={['Android']}>
                        <p>
                          Try removing{' '}
                          <strong>
                            <SelectableText>{unrestrictedSSID}</SelectableText>
                          </strong>{' '}
                          from your 'Saved&nbsp;Networks' and try again.
                        </p>
                      </PlatformSpecific>
                    </div>
                  )}
                  {currentStep === 'unknown' && (
                    <p>We can't tell if you’re in our stadium</p>
                  )}
                  {currentStep === 'complete' && <p>You’re connected!</p>}
                  {currentStep === 'incompatible' && (
                    <Fragment>
                      <p>Your device cannot connect automatically.</p>
                      <p>In your Wi-Fi settings, use these details:</p>
                      <Rule muted />
                      <p>
                        Network:{' '}
                        <strong>
                          <SelectableText>{unrestrictedSSID}</SelectableText>
                        </strong>
                      </p>
                      <p>
                        Password:{' '}
                        <strong>
                          <SelectableText>{unrestrictedPasskey}</SelectableText>
                        </strong>
                      </p>
                      <Rule muted />
                    </Fragment>
                  )}
                </Prose>
              </TextAlign>
            </Section>
            <Section spacing="xt">
              <SpacedList>
                {currentStep === 'unknown' && (
                  <Fragment>
                    <ButtonDisplay block onClick={() => onConnect()}>
                      Connect anyway
                    </ButtonDisplay>
                    <ButtonBase block to="/notification">
                      Skip
                    </ButtonBase>
                  </Fragment>
                )}
                {currentStep === 'ready' && (
                  <Fragment>
                    <ButtonDisplay block onClick={() => onConnect()}>
                      Connect
                    </ButtonDisplay>
                    <ButtonBase block to="/notification">
                      Skip
                    </ButtonBase>
                  </Fragment>
                )}
                {currentStep === 'connecting' && (
                  <ButtonBase block to="/notification">
                    Skip
                  </ButtonBase>
                )}
                {currentStep === 'complete' && (
                  <ButtonDisplay block to="/notification">
                    Next step
                  </ButtonDisplay>
                )}
                {currentStep === 'failed' && (
                  <Fragment>
                    <ButtonDisplay block onClick={() => onConnect()}>
                      Try again
                    </ButtonDisplay>
                    <ButtonBase block to="/notification">
                      Skip
                    </ButtonBase>
                  </Fragment>
                )}
                {currentStep === 'incompatible' && (
                  <ButtonDisplay block to="/notification">
                    Next
                  </ButtonDisplay>
                )}
              </SpacedList>
            </Section>
            <Section spacing="xy">
              <TextAlign center>
                <Type size="tiny">Powered by Colooder</Type>
              </TextAlign>
            </Section>
          </ScrollArea>
        </Section>
      </MinimalLayout>
    )
  }
}

Wifi.defaultProps = {
  getConnectedSSID: f => f
}

Wifi.propTypes = {
  currentStep: oneOf([
    'unknown',
    'loading',
    'ready',
    'connecting',
    'complete',
    'failed',
    'not-ready',
    'incompatible'
  ]).isRequired,
  onConnect: func.isRequired,
  getConnectedSSID: func.isRequired
}

export default Wifi
