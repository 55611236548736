import React from 'react'

import MinimalLayout from '../../component/Layout/MinimalLayout'
import ScrollArea from '../../component/Primitive/ScrollArea'
import Type from '../../component/Primitive/Type'
import Section from '../../component/Primitive/Section'
import Prose from '../../component/Primitive/Prose'
import Rule from '../../component/Primitive/Rule'
import Toolbar from '../../component/Primitive/Toolbar'
import ToolbarBackButton from '../../component/Primitive/Toolbar/ToolbarBackButton'

const Terms = () => (
  <MinimalLayout>
    <Section grow>
      <Toolbar buttonsBefore={<ToolbarBackButton />} />
      <ScrollArea>
        <Section spacing="xt">
          <Type element="h1" size="title">
            Terms of use
          </Type>
        </Section>
        <Section spacing="xy">
          <Prose>
            <p>Effective Date: May 30, 2018</p>
            <Rule spaced />
            <p>
              This policy explains what information we collect when you use
              Colooder’s sites, services, mobile applications, products, and
              content (“Services”). It also has information about how we store,
              use, transfer, and delete that information. Our aim is not just to
              comply with privacy law. It’s to earn your trust.
            </p>
            <h2>Information We Collect & How We Use It</h2>
            <p>
              Colooder will only introduce advertising through Gloucester
              Rugby’s Sponsors and Partners. The Club may receive money from
              those partners - but they will not receive any of your data or
              information. The tracking we do at Colooder is to make our product
              work as well as possible.
            </p>
            <p>
              In order to give you the best possible experience using Colooder,
              we collect information from your interactions with our network.
              Some of this information, you actively tell us (such as your email
              address, which we use to track your account or communicate with
              you). Other information, we collect based on actions you take
              while using Colooder, such as what pages you access and your
              interactions with our product features (like highlights, follows,
              and applause). This information includes records of those
              interactions, your Internet Protocol address, information about
              your device (such as device or browser type), and referral
              information.
            </p>
            <p>We use this information to:</p>
            <p>
              provide, test, improve, promote and personalise Colooder Services,
              fight spam and other forms of abuse, generate aggregate,
              non-identifying information about how people use Colooder
              Services.
            </p>
            <p>
              When you create your Colooder account, and authenticate with a
              third-party service (like Twitter, Facebook or Google) we may
              collect, store, and periodically update information associated
              with that third-party account, such as your lists of friends or
              followers. We will never publish through your third-party account
              without your permission.
            </p>
            <h2>Information Disclosure</h2>
            <p>
              Colooder won’t transfer information about you to third parties for
              the purpose of providing or facilitating third-party advertising
              to you. We won’t sell information about you.
            </p>
            <p>
              We may share your account information with third parties in some
              circumstances, including: (1) with your consent; (2) to a service
              provider or partner who meets our data protection standards; (3)
              with academic or non-profit researchers, with aggregation,
              anonymization, or pseudonomization; (4) when we have a good faith
              belief it is required by law, such as pursuant to a subpoena or
              other legal process; (5) when we have a good faith belief that
              doing so will help prevent imminent harm to someone.
            </p>
            <p>
              If we are going to share your information in response to legal
              process, we’ll give you notice so you can challenge it (for
              example by seeking court intervention), unless we’re prohibited by
              law or believe doing so may endanger others. We will object to
              requests for information about users of our services that we
              believe are improper.
            </p>
            <h2>Data Storage</h2>
            <p>
              Colooder uses third-party vendors and hosting partners, such as
              Amazon, for hardware, software, networking, storage, and related
              technology we need to run Colooder. We maintain two types of logs:
              server logs and event logs.
            </p>
            <h2>Third-Party Embeds</h2>
            <p>
              Some of the content that you see displayed on Colooder is not
              hosted by Colooder. These “embeds” are hosted by a third-party and
              embedded in Colooder. For example: YouTube or Vimeo videos, Imgur
              or Giphy gifs, SoundCloud audio files, Twitter tweets, GitHub
              code, or Scribd documents that appear. These files send data to
              the hosted site just as if you were visiting that site directly
              (for example, when you load a Colooder post page with a YouTube
              video embedded in it, YouTube receives data about your activity).
              Colooder does not control what data third parties collect in cases
              like this, or what they will do with it. So, third-party embeds on
              Colooder are not covered by this privacy policy. They are covered
              by the privacy policy of the third-party service.
            </p>
            <p>
              Some embeds may ask you for personal information, such as your
              email address, through a form. We do our best to keep bad people
              off of Colooder. However, if you choose to submit your information
              to a third party this way, we don’t know what they may do with it.
              As explained above, their actions are not covered by this Privacy
              Policy. So, please be careful when you see embedded forms on
              Colooder asking for your email address or any other personal
              information. Make sure you understand who you are submitting your
              information to and what they say they plan to do with it. We
              suggest that you do not submit personal information to any
              third-party through an embedded form.
            </p>
            <p>
              If you embed a form that allows submission of personal information
              by users, you must provide near the embedded form a prominent link
              to an applicable Privacy Policy that clearly states how to you
              intend to use any information collected. Failure to do so may lead
              Colooder to disable the post or take other action to limit or
              disable your account.
            </p>
            <h2>Tracking & Cookies</h2>
            <p>
              We use browser cookies and similar technologies to recognize you
              when you return to our Services. We use them in various ways, for
              example to log you in, remember your preferences (such as default
              language), evaluate email effectiveness, allow our paywall and
              meter to function, and personalise content and other information.
            </p>
            <p>
              Colooder doesn’t track you across the Internet. We track only your
              interactions within the Colooder platform (which encompasses
              Colooder.app and custom domains hosted by Colooder).
            </p>
            <p>
              Some third-party services that we use to provide the Colooder
              Service, such as Google Analytics, may place their own cookies in
              your browser. This Privacy Policy covers use of cookies by
              Colooder only and not the use of cookies by third parties.
            </p>
            <p>
              Colooder complies with the “Do Not Track” (“DNT”) standard
              recommended by the World Wide Web Consortium. For logged-out users
              browsing with DNT enabled, Colooder’s analytics will not receive
              data about you, but we will do some first-party tracking in order
              to customize content and provide data to third-party service
              providers that enable Colooder Services to work. When you use
              Colooder while logged-in to your account, we cannot comply with
              DNT.
            </p>
            <h2>Modifying or Deleting Your Personal Information</h2>
            <p>
              If you have a Colooder account, you can access, modify or export
              your personal information, or delete your account here.
            </p>
            <p>
              To protect information from accidental or malicious destruction,
              we may maintain residual copies for a brief time period. But, if
              you delete your account, your information and content will be
              unrecoverable after that time.
            </p>
            <h2>Data Security</h2>
            <p>
              We use encryption (HTTPS/TLS) to protect data transmitted to and
              from our site. However, no data transmission over the Internet is
              100% secure, so we can’t guarantee security. You use the Service
              at your own risk, and you’re responsible for taking reasonable
              measures to secure your account.
            </p>
            <h2>Business Transfers</h2>
            <p>
              If we are involved in a merger, acquisition, bankruptcy,
              reorganization or sale of assets such that your information would
              be transferred or become subject to a different privacy policy,
              we’ll notify you in advance so you can opt out of any such new
              policy by deleting your account before transfer.
            </p>
            <h2>Email from Colooder</h2>
            <p>
              Sometimes we’ll send you emails about your account, service
              changes or new policies. You can’t opt out of this type of
              “transactional” email (unless you delete your account). But, you
              can opt out of non-administrative emails such as digests,
              newsletters, and activity notifications through your account’s
              “Me” page.
            </p>
            <p>
              When your interact with an email sent from Colooder (such as
              opening an email or clicking on a particular link in an email), we
              may receive information about that interaction. We won’t email you
              to ask for your password or other account information. If you
              receive such an email, please send it to us so we can investigate.
            </p>
            <h2>Changes to this Policy</h2>
            <p>
              Colooder may periodically update this Policy. We’ll notify you
              about significant changes to it. The most current version of the
              policy will always be here and we will archive former versions of
              the policy here.
            </p>
          </Prose>
        </Section>
      </ScrollArea>
    </Section>
  </MinimalLayout>
)

export default Terms
