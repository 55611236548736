import React, { Fragment, PureComponent } from 'react'
import { instanceOf, number } from 'prop-types'

import Countdown from '../../../Primitive/Countdown/container'
import Type from '../../../Primitive/Type'
import TileCustom from '../../../Primitive/TileCustom'
import TextAlign from '../../../Primitive/TextAlign'
import SpacedList from '../../../Primitive/SpacedList'
import Margin from '../../../Primitive/Margin'
import Separator from '../../../Primitive/Separator'
import Prose from '../../../Primitive/Prose'

import image from './image/background.jpg'

export class MotmPreviewTile extends PureComponent {
  render() {
    const { height, startTime } = this.props
    return (
      <TileCustom imageUrl={image} height={height}>
        <TextAlign center>
          <SpacedList>
            <Type element="h1" size="display2">
              Stowford&nbsp;Press Man&nbsp;of&nbsp;the&nbsp;Match
            </Type>
            <Prose>
              <p>Votes are your voice and a boost to the team</p>
            </Prose>
            {startTime && (
              <Fragment>
                <Margin my={3}>
                  <Separator />
                </Margin>
                <Type element="div" size="display2">
                  Vote in
                </Type>
                <Countdown targetTime={new Date(startTime)} />
              </Fragment>
            )}
          </SpacedList>
        </TextAlign>
      </TileCustom>
    )
  }
}

MotmPreviewTile.propTypes = {
  height: number,
  startTime: instanceOf(Date)
}

export default MotmPreviewTile
