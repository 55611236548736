import Immutable from 'immutable'
import { USERGATE_SUCCESS, USERGATE_ERROR, USERGATE_CHANGE } from './actions'

const initialState = Immutable.fromJS({
  complete: false,
  data: {
    dateOfBirth: null,
    termsAgreed: false,
    basicConsent: false
  },
  error: null,
  errorProperties: {}
})

export default (state = initialState, action) => {
  switch (action.type) {
    case USERGATE_ERROR:
      return state.merge({
        error: action.error,
        errorProperties: action.errorProperties
      })
    case USERGATE_SUCCESS:
      return state.merge({
        complete: true,
        error: null,
        errorProperties: {}
      })
    case USERGATE_CHANGE:
      return state.mergeIn(['data'], action.data)
    default:
      return state
  }
}
