import createCrudActioner from '../../../../../lib/crud-actioner'
export const QUIZ_INIT = 'QUIZ_INIT'
export const QUIZ_FORM_CHANGE = 'QUIZ_FORM_CHANGE'

const { fetch: fetchQuiz, create, resetError } = createCrudActioner({
  prefix: 'QUIZ',
  action: 'quiz'
})

export const initQuiz = () => (dispatch, getState) => {
  const state = getState()
  const profile = state.profile && state.profile.toJS()
  const { user } = profile
  const data = profile.authenticated
    ? {
        emailAddress: user.emailAddress,
        newsletterConsent: user.newsletterConsent,
        promotionsConsent: user.promotionsConsent
      }
    : state.quiz.data
  dispatch({
    type: QUIZ_INIT,
    data
  })
}

export const enterCompetition = primus => (dispatch, getState) => {
  dispatch(
    create(primus, {
      quizId: getState().quiz.get('_id'),
      ...getState()
        .quiz.get('data')
        .toJS()
    })
  )
}

export const change = data => ({
  type: QUIZ_FORM_CHANGE,
  data
})

export { fetchQuiz, resetError }
