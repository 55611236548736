import React from 'react' // eslint-disable-line no-unused-vars

// svgo ./src/component/Primitive/Crest/source --enable=removeXMLNS--enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2

import Blank from './source/Blank.svg'
import Bath from './source/Bath.svg'
import BristolBears from './source/BristolBears.svg'
import BristolBearsReverse from './source/BristolBearsReverse.svg'
import BristolUnited from './source/BristolUnited.svg'
import CardiffBlues from './source/CardiffBlues.svg'
import Castres from './source/Castres.svg'
import Dragons from './source/Dragons.svg'
import Edinburgh from './source/Edinburgh.svg'
import Exeter from './source/Exeter.svg'
import ExeterReverse from './source/ExeterReverse.svg'
import GlasgowWarriors from './source/GlasgowWarriors.svg'
import GlasgowWarriorsReverse from './source/GlasgowWarriorsReverse.svg'
import Gloucester from './source/Gloucester.svg'
import Harlequins from './source/Harlequins.svg'
import HarlequinsReverse from './source/HarlequinsReverse.svg'
import Leicester from './source/Leicester.svg'
import Leinster from './source/Leinster.svg'
import LeinsterReverse from './source/LeinsterReverse.svg'
import LondonIrish from './source/LondonIrish.svg'
import LondonIrishReverse from './source/LondonIrishReverse.svg'
import Lyon from './source/Lyon.svg'
import Montpellier from './source/Montpellier.svg'
import Munster from './source/Munster.svg'
import Newcastle from './source/Newcastle.svg'
import NewcastleReverse from './source/NewcastleReverse.svg'
import NewportGwentDragons from './source/NewportGwentDragons.svg'
import Northampton from './source/Northampton.svg'
import Ospreys from './source/Ospreys.svg'
import OspreysReverse from './source/OspreysReverse.svg'
import Racing92 from './source/Racing92.svg'
import Racing92Reverse from './source/Racing92Reverse.svg'
import Sale from './source/Sale.svg'
import SaleReverse from './source/SaleReverse.svg'
import Saracens from './source/Saracens.svg'
import SaracensReverse from './source/SaracensReverse.svg'
import Scarlets from './source/Scarlets.svg'
import StadeFrancais from './source/StadeFrancais.svg'
import Toulouse from './source/Toulouse.svg'
import Toulon from './source/Toulon.svg'
import Ulster from './source/Ulster.svg'
import UlsterReverse from './source/UlsterReverse.svg'
import Wasps from './source/Wasps.svg'
import WaspsReverse from './source/WaspsReverse.svg'
import Worcester from './source/Worcester.svg'
import WorcesterReverse from './source/WorcesterReverse.svg'

const Svgs = {
  Blank,
  Bath,
  // This is a hack to allow the name from opta to be Bristol.
  // At some point remove whichever Bristol team aren't in opta
  Bristol: BristolBears,
  BristolReverse: BristolBearsReverse,
  BristolBears,
  BristolBearsReverse,
  BristolUnited,
  Cardiff: CardiffBlues,
  CardiffBlues,
  Castres,
  Dragons,
  Edinburgh,
  Exeter,
  ExeterReverse,
  GlasgowWarriors,
  GlasgowWarriorsReverse,
  Gloucester,
  Harlequins,
  HarlequinsReverse,
  Leicester,
  Leinster,
  LeinsterReverse,
  LondonIrish,
  LondonIrishReverse,
  Lyon,
  Montpellier,
  Munster,
  Newcastle,
  NewcastleReverse,
  NewportGwentDragons,
  Northampton,
  Ospreys,
  OspreysReverse,
  Racing92,
  Racing92Reverse,
  Sale,
  SaleReverse,
  Saracens,
  SaracensReverse,
  Scarlets,
  StadeFrancais,
  Toulon,
  Toulouse,
  Ulster,
  UlsterReverse,
  Wasps,
  WaspsReverse,
  Worcester,
  WorcesterReverse
}

export default Svgs
