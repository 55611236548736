exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PlayerSelector__PlayerSelector___2gh3v{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto}.PlayerSelector__PlayerSelectorNotification___37_Z_{padding-top:40px!important;margin-top:-24px}.PlayerSelector__PlayerSelectorConfirmation___2bL6t{display:block}", ""]);

// exports
exports.locals = {
	"PlayerSelector": "PlayerSelector__PlayerSelector___2gh3v",
	"PlayerSelectorNotification": "PlayerSelector__PlayerSelectorNotification___37_Z_",
	"PlayerSelectorConfirmation": "PlayerSelector__PlayerSelectorConfirmation___2bL6t"
};