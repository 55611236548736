import Immutable from 'immutable'
import reduceReducers from 'reduce-reducers'

import createCrudReducer from '../../../../../lib/crud-reducer'
import {
  PHOTO_FORM_CHANGE,
  PHOTO_NO_CAMERA,
  PHOTO_SET_IMAGE,
  PHOTO_SET_ERROR,
  PHOTO_SEND,
  PHOTO_SEND_COMPLETE,
  PHOTO_SEND_FAILURE,
  PHOTO_CLEAR_ERRORS,
  PHOTO_SET_SOURCE,
  PHOTO_VALIDATION_ERRORS
} from './actions'

const initialState = Immutable.fromJS({
  images: [],
  sending: false,
  error: null,
  cameraError: null,
  image: null,
  gallery: [],
  source: null,
  validationErrors: {}
})

const fetchGalleryMapper = images => ({ images })
const fetchGalleryReducer = createCrudReducer({
  prefix: 'GALLERY',
  fetchMapper: fetchGalleryMapper
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PHOTO_CLEAR_ERRORS:
      return state.merge({
        error: null,
        cameraError: null,
        validationErrors: {}
      })
    case PHOTO_FORM_CHANGE:
      return state.merge(action.data)
    case PHOTO_SET_IMAGE:
      return state.set('image', action.image)
    case PHOTO_SET_ERROR:
      return state.set('error', action.error.message)
    case PHOTO_NO_CAMERA:
      return state.set('cameraError', 'Sorry, camera not available.')
    case PHOTO_SEND:
      return state.set('sending', true)
    case PHOTO_SEND_COMPLETE:
      return state.merge({
        sending: false,
        error: null,
        cameraError: null,
        image: null,
        message: null
      })
    case PHOTO_SEND_FAILURE:
      return state.merge({
        sending: false,
        error: action.error
      })
    case PHOTO_SET_SOURCE:
      return state.set('source', action.source)
    case PHOTO_VALIDATION_ERRORS:
      return state.set('validationErrors', action.errors)
    default:
      return state
  }
}

export default reduceReducers(reducer, fetchGalleryReducer)
