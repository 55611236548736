import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, node, oneOf, string } from 'prop-types'
import styles from './Type.styl'

class Type extends PureComponent {
  render() {
    const {
      children,
      className,
      element,
      muted,
      size,
      status,
      tight
    } = this.props
    const TypeEl = element

    return (
      <TypeEl
        className={classNames(
          styles.Type,
          size && styles[size],
          status && styles[status],
          muted && styles.muted,
          tight && styles.tight,
          className
        )}
      >
        {children}
      </TypeEl>
    )
  }
}

Type.displayName = 'Type'

Type.defaultProps = {
  element: 'div',
  size: 'base'
}

Type.propTypes = {
  children: node.isRequired,
  className: string,
  element: string,
  muted: bool,
  size: oneOf([
    'display4',
    'display3',
    'display2',
    'display1',
    'title',
    'subtitle',
    'base',
    'small',
    'tiny'
  ]),
  status: oneOf(['error', 'notice', 'success', 'warning']),
  tight: bool
}

export default Type
