import React, { PureComponent } from 'react'
import { bool, func, number, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './ButtonDisplay.styl'

import ButtonBase from '../ButtonBase'
import ButtonProgress from '../ButtonProgress'

export class ButtonDisplay extends PureComponent {
  render() {
    const {
      outline,
      reverse,
      theme,
      children,
      className,
      onProgress,
      progressDuration,
      ...other
    } = this.props
    return (
      <ButtonBase
        className={classNames(
          styles.ButtonDisplay,
          theme && styles[`ButtonDisplay-${theme}`],
          outline && styles.outline,
          theme && outline && styles[`outline-${theme}`],
          reverse && styles.reverse,
          theme && reverse && styles[`reverse-${theme}`],
          className
        )}
        {...other}
      >
        {onProgress && (
          <ButtonProgress
            onProgress={onProgress}
            progressDuration={progressDuration}
          />
        )}
        <span>{children}</span>
      </ButtonBase>
    )
  }
}

ButtonDisplay.displayName = 'ButtonDisplay'

ButtonDisplay.propTypes = {
  className: string,
  onProgress: func,
  progressDuration: number,
  outline: bool,
  reverse: bool,
  theme: string
}

export default withTheme(ButtonDisplay)
