import React, { PureComponent } from 'react'
import { bool, node, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './TabBar.styl'

import Icon from '../Icon'
import SmartLink from '../SmartLink'

class TabBarItem extends PureComponent {
  render() {
    const {
      icon,
      indicator,
      active,
      children,
      theme,
      to,
      featured
    } = this.props

    return (
      <SmartLink
        className={classNames(
          styles.TabBarItem,
          theme && styles[`TabBarItem-${theme}`],
          featured && styles.featured,
          active && styles.active
        )}
        to={to}
      >
        {!!indicator && (
          <div
            className={classNames(
              styles.TabBarItemIndicator,
              theme && styles[`TabBarItemIndicator-${theme}`]
            )}
          >
            {typeof indicator === 'string' && indicator}
          </div>
        )}
        <div className={styles.TabBarItemIcon}>
          <Icon type={icon} />
        </div>
        <div className={styles.TabBarItemLabel}>{children}</div>
      </SmartLink>
    )
  }
}

TabBarItem.displayName = 'TabBarItem'

TabBarItem.propTypes = {
  icon: string,
  indicator: oneOfType([bool, string]),
  active: bool,
  children: node,
  theme: string,
  to: string.isRequired,
  featured: bool
}

export default withTheme(TabBarItem)
