exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OverlayTemplate__OverlayTemplate___kxqAk{position:absolute;top:0;left:0;right:0;bottom:0;z-index:200;display:-webkit-box;display:-ms-flexbox;display:flex;overflow:hidden;-webkit-animation:OverlayTemplate__overlay-reveal___3-xtp .3s cubic-bezier(.165,.84,.44,1) 1 forwards;animation:OverlayTemplate__overlay-reveal___3-xtp .3s cubic-bezier(.165,.84,.44,1) 1 forwards}.OverlayTemplate__noReveal___Jsym_{-webkit-animation:none;animation:none}@-webkit-keyframes OverlayTemplate__overlay-reveal___3-xtp{0%{-webkit-transform:translateY(100%);transform:translateY(100%)}to{-webkit-transform:translateY(0);transform:translateY(0)}}@keyframes OverlayTemplate__overlay-reveal___3-xtp{0%{-webkit-transform:translateY(100%);transform:translateY(100%)}to{-webkit-transform:translateY(0);transform:translateY(0)}}", ""]);

// exports
exports.locals = {
	"OverlayTemplate": "OverlayTemplate__OverlayTemplate___kxqAk",
	"overlay-reveal": "OverlayTemplate__overlay-reveal___3-xtp",
	"noReveal": "OverlayTemplate__noReveal___Jsym_"
};