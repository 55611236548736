import React, { PureComponent } from 'react'
import { arrayOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './StepProgress.styl'

export class StepProgress extends PureComponent {
  render() {
    const { steps } = this.props

    return (
      <div className={styles.StepProgress}>
        {steps.map((step, i) => (
          <div
            key={`StepProgressItem${i}`}
            className={classNames(styles.StepProgressItem, styles[step])}
          />
        ))}
      </div>
    )
  }
}

StepProgress.displayName = 'StepProgress'

StepProgress.propTypes = {
  steps: arrayOf(string).isRequired
}

export default StepProgress
