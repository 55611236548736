import React, { PureComponent, Children } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './TabBar.styl'

class TabBar extends PureComponent {
  constructor() {
    super()
    this.state = {
      activeItemIndex: -1,
      previousActiveItemIndex: -1,
      itemCount: 0,
      transitionDuration: 0
    }

    this.getActiveItem = this.getActiveItem.bind(this)
  }

  getActiveItem() {
    const { children, pathname } = this.props
    const childrenArr = Children.toArray(children)
    const activeItemIndex = childrenArr.indexOf(
      childrenArr.filter(
        child => !!pathname && pathname.includes(child.props.to.split('/')[1])
      )[0]
    )
    const itemCount = childrenArr.length
    const distance = Math.abs(activeItemIndex - this.state.activeItemIndex)
    if (
      this.state.activeItemIndex > -1 &&
      this.state.activeItemIndex === activeItemIndex
    ) {
      return
    }
    this.setState({
      activeItemIndex,
      itemCount,
      previousActiveItemIndex: this.state.activeItemIndex,
      transitionDuration: (300 - (distance - 1) * 50) * distance
    })
  }

  componentDidMount() {
    this.getActiveItem()
  }

  componentDidUpdate() {
    this.getActiveItem()
  }

  render() {
    const { children, theme } = this.props

    const itemWidth = 100 / this.state.itemCount
    const a = itemWidth * this.state.activeItemIndex
    const b = a + itemWidth
    const clipPath = `polygon(${a}% 0%, ${b}% 0%, ${b}% 100%, ${a}% 100%)`
    return (
      <div
        className={classNames(
          styles.TabBar,
          theme && styles[`TabBar-${theme}`]
        )}
      >
        {this.state.activeItemIndex > -1 && (
          <div
            className={styles.TabBarIndicator}
            style={{
              clipPath,
              WebkitClipPath: clipPath,
              transitionDuration: `${this.state.transitionDuration}ms`
            }}
          />
        )}
        <div className={styles.TabBarInner}>
          {children &&
            Children.map(children, (child, i) => {
              const active = i === this.state.activeItemIndex
              return React.cloneElement(child, {
                active
              })
            })}
        </div>
      </div>
    )
  }
}

TabBar.displayName = 'TabBar'

TabBar.defaultProps = {
  pathname: ''
}

TabBar.propTypes = {
  pathname: string,
  children: node,
  theme: string
}

export default withTheme(TabBar)
