import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class GalleryTile extends PureComponent {
  render() {
    const { height } = this.props
    return (
      <Tile
        heading="Fan Gallery"
        to="/activity/play/gallery"
        imageUrl={image}
        height={height}
      />
    )
  }
}

GalleryTile.propTypes = {
  height: number
}

export default GalleryTile
