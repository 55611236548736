import React, { PureComponent } from 'react'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Separator.styl'

export class Separator extends PureComponent {
  render() {
    return (
      <div className={classNames(styles.Separator)}>
        <div className={classNames(styles.SeparatorCircles)}>
          <svg viewBox="0 0 52 8">
            <circle cx="4" cy="4" r="4" />
            <circle cx="24" cy="4" r="4" />
            <circle cx="44" cy="4" r="4" />
          </svg>
        </div>
      </div>
    )
  }
}

Separator.displayName = 'Separator'

export default withTheme(Separator)
