import React, { PureComponent } from 'react'
import { string } from 'prop-types'

import Cover from '../../../../../../component/Primitive/Cover'
import FormattedDate from 'src/component/Primitive/FormattedDate'
import moment from 'moment'
class Start extends PureComponent {
  render() {
    const {
      preTitle,
      title,
      description,
      publishedDate,
      closedMessage,
      openDate,
      closeDate
    } = this.props
    const now = new Date()
    const closed = !(
      now >= moment(openDate).toDate() && now < moment(closeDate).toDate()
    )
    return (
      <Cover
        crestClub="Gloucester"
        crestDisplayName="Gloucester Rugby"
        to={closed ? '' : '/activity/play/quiz/questions'}
        preTitle={preTitle}
        title={title}
        cta={closed ? '' : 'Start Quiz'}
      >
        <p>
          <strong>
            <FormattedDate date={publishedDate} format="LL" />
          </strong>
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: closed ? closedMessage : description
          }}
        />
      </Cover>
    )
  }
}

Start.propTypes = {
  preTitle: string,
  title: string,
  publishedDate: string,
  closedMessage: string,
  closeDate: string,
  openDate: string,
  description: string
}

export default Start
