exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonProgress__ButtonProgress___2Cjmz{position:absolute;top:0;right:0;bottom:0;left:0;background-color:currentColor;opacity:.2;-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);-webkit-animation:ButtonProgress__button-progress___CQ53B 5s linear 1 forwards;animation:ButtonProgress__button-progress___CQ53B 5s linear 1 forwards}@-webkit-keyframes ButtonProgress__button-progress___CQ53B{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}@keyframes ButtonProgress__button-progress___CQ53B{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}", ""]);

// exports
exports.locals = {
	"ButtonProgress": "ButtonProgress__ButtonProgress___2Cjmz",
	"button-progress": "ButtonProgress__button-progress___CQ53B"
};