import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './SelectableItem.styl'

import Avatar from '../Avatar/'
import Type from '../Type/'
import Icon from '../Icon/'
import Prose from '../Prose/'

export class SelectableItem extends PureComponent {
  render() {
    const { description, imageUrl, name, text, theme, value } = this.props

    return (
      <label
        className={classNames(
          styles.SelectableItem,
          theme && styles[`SelectableItem-${theme}`]
        )}
      >
        <input
          type="radio"
          name={name}
          className={styles.SelectableItemControl}
          value={value || text}
        />
        <div
          className={classNames(
            styles.SelectableItemInner,
            theme && styles[`SelectableItemInner-${theme}`]
          )}
        >
          <div className={styles.SelectableItemAvatar}>
            <Avatar name={text} src={imageUrl} size={56} />
          </div>
          <div className={styles.SelectableItemContent}>
            <Type
              element="h3"
              size="display1"
              className={styles.SelectableItemHeading}
            >
              {text}
            </Type>
            {description && (
              <div className={styles.SelectableItemDescription}>
                <Prose>{description}</Prose>
              </div>
            )}
          </div>
          <div
            className={classNames(
              styles.SelectableItemIndicator,
              theme && styles[`SelectableItemIndicator-${theme}`]
            )}
          >
            <Icon type="Check" width={20} />
          </div>
        </div>
      </label>
    )
  }
}

SelectableItem.displayName = 'SelectableItem'

SelectableItem.defaultProps = {
  name: 'SelectableItems'
}

SelectableItem.propTypes = {
  description: string,
  imageUrl: string,
  name: string,
  text: string.isRequired,
  theme: string,
  value: string
}

export default withTheme(SelectableItem)
