import Immutable from 'immutable'
import moment from 'moment'
import sortBy from 'orderby'
import createCrudReducer from '../../../../../lib/crud-reducer'

const initialState = Immutable.fromJS({
  currentStep: 'loading',
  events: {},
  rawEvents: [],
  lastFetchEvents: -Infinity
})

const groupByMonth = events =>
  events.reduce((groups, event) => {
    const date = moment(event.startDate)
    const group = date.format('MMMM YYYY')
    if (!groups[group]) groups[group] = []
    groups[group].push({ ...event, startDate: date.toDate() })

    groups[group] = sortBy(groups[group], [event => event.startDate])
    return groups
  }, {})

const fetchEventMapper = events => ({
  events: groupByMonth(events),
  rawEvents: events,
  lastFetchEvents: Date.now()
})

const dataReducer = createCrudReducer({
  prefix: 'EVENT',
  fetchMapper: fetchEventMapper
})

const eventReducer = (state = initialState, action) => {
  switch (action) {
    default:
      return dataReducer(state, action)
  }
}

export default eventReducer
