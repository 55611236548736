exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Cover__Cover___1M3kO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex:1 0 auto;flex:1 0 auto;min-height:100%}.Cover__CoverCrest___1ITvU{width:50%;margin-left:auto;padding-left:8px;max-width:240px}.Cover__CoverImage___3350x{margin-bottom:16px}.Cover__Cover-base___2AmAX{background:#00cfdd;background:-webkit-gradient(linear,left top,left bottom,color-stop(15%,rgba(0,0,0,0)),color-stop(15%,#00cfdd),to(#7ed0e3));background:-webkit-linear-gradient(top,rgba(0,0,0,0) 15%,#00cfdd 0,#7ed0e3);background:linear-gradient(180deg,rgba(0,0,0,0) 15%,#00cfdd 0,#7ed0e3);color:#fff}.Cover__Cover-gloucester___2Gp8G{background:#da1a32;background:-webkit-gradient(linear,left top,left bottom,color-stop(15%,rgba(0,0,0,0)),color-stop(15%,#da1a32),to(#8d0000));background:-webkit-linear-gradient(top,rgba(0,0,0,0) 15%,#da1a32 0,#8d0000);background:linear-gradient(180deg,rgba(0,0,0,0) 15%,#da1a32 0,#8d0000);color:#fff}.Cover__Cover-inverse___14cOz{background:#ff9800;background:-webkit-gradient(linear,left top,left bottom,color-stop(15%,rgba(0,0,0,0)),color-stop(15%,#ff9800),to(#fb8c00));background:-webkit-linear-gradient(top,rgba(0,0,0,0) 15%,#ff9800 0,#fb8c00);background:linear-gradient(180deg,rgba(0,0,0,0) 15%,#ff9800 0,#fb8c00);color:#fff}", ""]);

// exports
exports.locals = {
	"Cover": "Cover__Cover___1M3kO",
	"CoverCrest": "Cover__CoverCrest___1ITvU",
	"CoverImage": "Cover__CoverImage___3350x",
	"Cover-base": "Cover__Cover-base___2AmAX",
	"Cover-gloucester": "Cover__Cover-gloucester___2Gp8G",
	"Cover-inverse": "Cover__Cover-inverse___14cOz"
};