exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IconAnimation__IconAnimation___3uz3K{display:inline-block;-webkit-animation-play-state:paused;animation-play-state:paused}.IconAnimation__refresh___Zy4uU{-webkit-animation-name:IconAnimation__refresh___Zy4uU;animation-name:IconAnimation__refresh___Zy4uU;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-duration:5s;animation-duration:5s;-webkit-animation-timing-function:linear;animation-timing-function:linear}.IconAnimation__playing___3Cdft{-webkit-animation-play-state:running;animation-play-state:running}.IconAnimation__reset___20T4S{-webkit-animation-name:IconAnimation__none___2-c_2;animation-name:IconAnimation__none___2-c_2}@-webkit-keyframes IconAnimation__refresh___Zy4uU{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes IconAnimation__refresh___Zy4uU{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"IconAnimation": "IconAnimation__IconAnimation___3uz3K",
	"refresh": "IconAnimation__refresh___Zy4uU",
	"playing": "IconAnimation__playing___3Cdft",
	"reset": "IconAnimation__reset___20T4S",
	"none": "IconAnimation__none___2-c_2"
};