import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import { Spring, animated } from 'react-spring'

import styles from './Expander.styl'

import Type from '../Type'

export class Expander extends PureComponent {
  constructor() {
    super()

    this.state = {
      expanded: false,
      used: false
    }
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle() {
    this.setState({
      expanded: !this.state.expanded,
      used: this.props.singleUse
    })
  }

  render() {
    const { children, direction, labelClosed, labelOpen } = this.props

    return (
      <div
        className={classNames(
          styles.Expander,
          this.state.expanded && styles.expanded,
          direction && styles[direction]
        )}
      >
        {!this.state.used && (
          <button className={styles.ExpanderToggle} onClick={this.handleToggle}>
            <Type size="tiny">
              {this.state.expanded ? labelOpen : labelClosed}
            </Type>
          </button>
        )}

        <Spring
          native
          from={{ height: 0 }}
          to={{ height: this.state.expanded ? 'auto' : 0 }}
        >
          {props => (
            <animated.div className={styles.ExpanderContent} style={props}>
              <div className={styles.ExpanderContentInner}>{children}</div>
            </animated.div>
          )}
        </Spring>
      </div>
    )
  }
}

Expander.displayName = 'Expander'

Expander.defaultProps = {
  direction: 'down',
  labelClosed: 'Show details',
  labelOpen: 'Hide details'
}

Expander.propTypes = {
  children: node,
  direction: oneOf(['down', 'up']),
  singleUse: bool,
  labelClosed: string,
  labelOpen: string
}

export default Expander
