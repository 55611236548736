exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Notification__Notification___3xu9Y{color:#fff;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-line-pack:center;align-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background-color:grey;padding:16px 24px}.Notification__success___2Dyaa{background-color:#4caf50}.Notification__notice___HridU{background-color:#2196f3}.Notification__warning___25vWQ{background-color:#ff9800}.Notification__error___uqfYk{background-color:#da1a32}", ""]);

// exports
exports.locals = {
	"Notification": "Notification__Notification___3xu9Y",
	"success": "Notification__success___2Dyaa",
	"notice": "Notification__notice___HridU",
	"warning": "Notification__warning___25vWQ",
	"error": "Notification__error___uqfYk"
};