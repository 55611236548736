import React from 'react'
import { func, object, bool } from 'prop-types'

import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../component/Primitive/SpacedList'
import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import Type from '../../../../component/Primitive/Type'
import TextAlign from '../../../../component/Primitive/TextAlign'
import Rule from '../../../../component/Primitive/Rule'
import ShrinkWrap, {
  ShrinkWrapItem
} from '../../../../component/Primitive/ShrinkWrap'
import SmartLink from '../../../../component/Primitive/SmartLink'

const Profile = ({ onLogOut, user, authenticated }) => (
  <Section grow>
    <ScrollArea>
      <Section spacing="xt" grow>
        {authenticated ? (
          <SpacedList>
            <Type element="h1" size="display2">
              Hi, {user.firstName}
            </Type>
            <SpacedList>
              <ButtonDisplay block to="/profile/update">
                Update
              </ButtonDisplay>
              <ShrinkWrap fullwidth>
                <ShrinkWrapItem>
                  <a onClick={() => alert('Coming soon')}>Change Password</a>
                </ShrinkWrapItem>
                <ShrinkWrapItem>
                  <TextAlign right>
                    <a onClick={onLogOut}>Log out</a>
                  </TextAlign>
                </ShrinkWrapItem>
              </ShrinkWrap>
            </SpacedList>
          </SpacedList>
        ) : (
          <p>Not logged in</p>
        )}
      </Section>
      <Section spacing="xy">
        <Rule muted spaced />
        <TextAlign center>
          <SmartLink href="https://colooder.app">
            <Type size="tiny">Powered by Colooder</Type>
          </SmartLink>
        </TextAlign>
      </Section>
    </ScrollArea>
  </Section>
)
Profile.propTypes = {
  onLogOut: func,
  user: object,
  authenticated: bool
}

export default Profile
