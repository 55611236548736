import createDebug from 'debug'
import isCompatible from './lib/compatiblity-checker'
import calcPassword from '../../lib/password-calculator'

export const WIFI_CONNECT = 'WIFI_CONNECT'
export const WIFI_CONNECT_FAILURE = 'WIFI_CONNECT_FAILURE'
export const WIFI_CONNECT_COMPLETE = 'WIFI_CONNECT_COMPLETE'
export const WIFI_CONNECT_INCORRECT_NETWORK = 'WIFI_CONNECT_INCORRECT_NETWORK'
export const WIFI_GET_SSID = 'WIFI_GET_SSID'
export const WIFI_GET_SSID_FAILURE = 'WIFI_GET_SSID_FAILURE'
export const WIFI_GET_SSID_COMPLETE = 'WIFI_GET_SSID_COMPLETE'
export const WIFI_INCOMPATIBLE = 'WIFI_INCOMPATIBLE'

const unrestrictedSSID = process.env.UNRESTRICTED_SSID
const unrestrictedPasskey =
  process.env.UNRESTRICTED_PASSKEY ||
  calcPassword(process.env.WIFI_PASSWORD_SECRET).password
const restrictedSSID = process.env.RESTRICTED_SSID
const debug = createDebug('wifi:actions')

export const onConnect = () => async (dispatch, getState) => {
  dispatch({ type: WIFI_CONNECT })
  const { device, WifiWizard2 } = window

  if (!device || !WifiWizard2) {
    debug('No device or WifiWizard2 in window')
    return dispatch(connectFailure())
  }

  let connectedSSID = ''
  try {
    if (window.cordova.platformId === 'android') {
      await WifiWizard2.requestPermission()
    }
    connectedSSID = await WifiWizard2.getConnectedSSID()
  } catch (error) {
    debug('getConnectedSSID error', error)
  }

  debug('Connection status:', {
    connectedSSID,
    restrictedSSID,
    unrestrictedSSID
  })

  if (connectedSSID === unrestrictedSSID) {
    debug('Already connected to unrestrictedSSID')
    return dispatch(connectComplete())
  }

  try {
    if (device.platform === 'iOS') {
      debug('iOS device')
      await WifiWizard2.iOSConnectNetwork(unrestrictedSSID, unrestrictedPasskey)
    } else {
      debug('Android device')
      debug(`Connecting ${unrestrictedSSID} ${unrestrictedPasskey}`)
      await WifiWizard2.connect(
        unrestrictedSSID,
        true,
        unrestrictedPasskey,
        'WPA'
      )
    }
  } catch (error) {
    debug('Network connect error', error)
    return dispatch(connectFailure())
  }

  try {
    connectedSSID = await WifiWizard2.getConnectedSSID()
  } catch (error) {
    debug('getConnectedSSID error', error)
  }
  debug('Connected SSID after connect request:', connectedSSID)

  if (connectedSSID === unrestrictedSSID) {
    debug('Connection successful')
    return dispatch(connectComplete())
  }

  return dispatch(connectFailure())
}

export const incompatible = () => ({
  type: WIFI_INCOMPATIBLE
})

export const connectIncorrectNetwork = () => ({
  type: WIFI_CONNECT_INCORRECT_NETWORK
})

export const connectFailure = () => ({
  type: WIFI_CONNECT_FAILURE
})

export const connectComplete = data => ({
  type: WIFI_CONNECT_COMPLETE,
  data
})

export const getConnectedSSID = () => async (dispatch, getState) => {
  const { device, WifiWizard2 } = window
  if (!isCompatible(device)) {
    return dispatch(incompatible())
  }
  dispatch({ type: WIFI_GET_SSID })

  if (!device || !WifiWizard2) {
    debug('No device or WifiWizard2 in window')
    return dispatch(getConnectedSSIDFailure())
  }

  let connectedSSID = ''
  try {
    connectedSSID = await WifiWizard2.getConnectedSSID()
    return dispatch(getConnectedSSIDComplete(connectedSSID))
  } catch (error) {
    debug('getConnectedSSID error', error)
    return dispatch(getConnectedSSIDFailure())
  }
}

export const getConnectedSSIDFailure = () => ({
  type: WIFI_GET_SSID_FAILURE
})

export const getConnectedSSIDComplete = ssid => ({
  type: WIFI_GET_SSID_COMPLETE,
  ssid
})
