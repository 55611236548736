import uuid from 'uuid/v4'

export default () => {
  if (typeof window !== 'undefined' && window.device) {
    return window.device.uuid
  }
  const storedId = localStorage.getItem('deviceId')
  if (storedId) return storedId
  const deviceId = uuid()
  localStorage.setItem('deviceId', deviceId)
  return deviceId
}
