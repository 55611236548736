import React, { PureComponent } from 'react'
import { bool, func, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './ScrollNav.styl'

import SmartLink from '../SmartLink'

class ScrollNavItem extends PureComponent {
  render() {
    const { active, children, setRef, theme, to } = this.props

    return (
      <li
        className={classNames(
          styles.ScrollNavItem,
          theme && styles[`ScrollNavItem-${theme}`]
        )}
        ref={el => {
          setRef(el)
        }}
      >
        <SmartLink
          className={classNames(
            styles.ScrollNavItemLink,
            theme && styles[`ScrollNavItemLink-${theme}`],
            active && styles.active
          )}
          to={to}
        >
          <span className={styles.ScrollNavItemLinkInner}>{children}</span>
        </SmartLink>
      </li>
    )
  }
}

ScrollNavItem.displayName = 'ScrollNavItem'

ScrollNavItem.defaultProps = {
  setRef: () => {}
}

ScrollNavItem.propTypes = {
  active: bool,
  children: node,
  setRef: func,
  theme: string,
  to: string.isRequired
}

export default withTheme(ScrollNavItem)
