import React, { PureComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import OverlayLayout from '../../../../component/Layout/OverlayLayout'
import AnimatedRoute from '../../../../component/Primitive/AnimatedRoute'
import Show from './Show'
import Episode from './Episode'

class Shows extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <AnimatedRoute>
          {location => (
            <Switch location={location}>
              <Route
                exact
                path="/audio/show/:showId"
                render={props => <Show {...props} />}
              />
              <Route
                exact
                path="/audio/show/:showId/episode/:episodeId"
                render={props => <Episode {...props} />}
              />
            </Switch>
          )}
        </AnimatedRoute>
      </OverlayLayout>
    )
  }
}

export default Shows
