import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './SummaryFeatured.styl'

import ResponsiveMedia from '../ResponsiveMedia'
import SmartLink from '../SmartLink'
import Type from '../Type'

export class SummaryFeatured extends PureComponent {
  render() {
    const { category, heading, imageUrl, to, theme } = this.props

    return (
      <SmartLink
        className={classNames(
          styles.SummaryFeatured,
          theme && styles[`SummaryFeatured-${theme}`]
        )}
        to={to}
      >
        <div className={styles.SummaryFeaturedImage}>
          <ResponsiveMedia ratio={9 / 16}>
            <img src={imageUrl} />
          </ResponsiveMedia>
        </div>
        <div className={styles.SummaryFeaturedContent}>
          <Type
            element="h3"
            size="subtitle"
            className={styles.SummaryFeaturedHeading}
          >
            {heading}
          </Type>
          <Type size="tiny">{category}</Type>
        </div>
      </SmartLink>
    )
  }
}

SummaryFeatured.displayName = 'SummaryFeatured'

SummaryFeatured.propTypes = {
  category: string.isRequired,
  heading: node.isRequired,
  imageUrl: string.isRequired,
  theme: string,
  to: string.isRequired
}

export default withTheme(SummaryFeatured)
