import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import Section from '../../Primitive/Section'
import Blockquote from '../../Primitive/Blockquote'

export class TextWidget extends PureComponent {
  render() {
    const { widget } = this.props
    const { content, attributed } = widget

    return (
      <Section spacing="x">
        <Blockquote quote={content} citation={attributed} />
      </Section>
    )
  }
}

TextWidget.propTypes = {
  widget: object
}

export default TextWidget
