import { connect } from 'react-redux'

import SourceComponent from '../component/Source'

import { setSource } from '../actions'

const mapDispatchToProps = dispatch => ({
  onSetSource: source => dispatch(setSource(source))
})

const mapStateToProps = state => state.gallery.toJS()

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceComponent)
