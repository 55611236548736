export default ({ vote, voteOpenDate, voteCloseDate }) => {
  const now = new Date()
  let step
  const votingStarted = now > new Date(voteOpenDate)
  const votingEnded = now > new Date(voteCloseDate)
  if (!votingStarted && !votingEnded) step = 'preview'
  if (votingStarted && !votingEnded) step = 'active'
  if (votingStarted && vote && vote.player) step = 'voted'
  if (votingStarted && votingEnded) step = 'complete'
  return step
}
