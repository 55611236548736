import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Toolbar.styl'

import SmartLink from '../SmartLink'
import Icon from '../Icon'

export class ToolbarButton extends PureComponent {
  render() {
    const { a11yText, children, icon, theme, ...other } = this.props
    return (
      <SmartLink
        className={classNames(
          styles.ToolbarButton,
          theme && styles[`ToolbarButton-${theme}`]
        )}
        {...other}
      >
        {!!icon && <Icon type={icon} allyText={a11yText} />}
        {children}
      </SmartLink>
    )
  }
}

ToolbarButton.displayName = 'ToolbarButton'

ToolbarButton.propTypes = {
  a11yText: string,
  children: node,
  icon: string,
  theme: string
}

export default withTheme(ToolbarButton)
