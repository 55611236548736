import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import formatDateTime from '../lib/date-formatter'

import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Type from '../../../../component/Primitive/Type'
import LinkList from '../../../../component/Primitive/LinkList'
import LinkListItem from '../../../../component/Primitive/LinkList/LinkListItem'
import Prose from '../../../../component/Primitive/Prose'

class Show extends PureComponent {
  render() {
    const { show, episodes } = this.props

    return (
      <Section grow>
        <ScrollArea>
          <Section>
            <Section spacing="xy">
              <SpacedList>
                <Type element="h1" size="display2">
                  {show.title}
                </Type>
                <Prose
                  html={`<p>${show.description.replace(/\n/g, '<br />')}</p>`}
                />
              </SpacedList>
            </Section>
            <SpacedList>
              {episodes.length < 1 && (
                <Section spacing="x">
                  <Prose>
                    <p>No episodes are currently available.</p>
                  </Prose>
                </Section>
              )}
              {episodes.length > 0 && (
                <LinkList>
                  {episodes.map(({ _id, title, createdDate }, i) => {
                    const episodeNum = episodes.length - i
                    const description = `Episode ${episodeNum}: ${formatDateTime(
                      createdDate
                    )}`
                    return (
                      <LinkListItem
                        key={`Episode${i}`}
                        heading={title}
                        description={description}
                        to={`/audio/show/${show._id}/episode/${_id}`}
                        condensed
                      />
                    )
                  })}
                </LinkList>
              )}
            </SpacedList>
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Show.defaultProps = {
  episodes: []
}

Show.propTypes = {
  show: PropTypes.object,
  episodes: PropTypes.array
}

export default Show
