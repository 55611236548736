import React, { PureComponent } from 'react'
import { number, object } from 'prop-types'
import { connect } from 'react-redux'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class LineupTile extends PureComponent {
  render() {
    const match = this.props.live || this.props.next
    if (!match || !match.lineUp) return null
    const { height } = this.props
    return (
      <Tile
        heading="Team Lineup"
        to="/activity/live/lineups"
        imageUrl={image}
        height={height}
      />
    )
  }
}

LineupTile.propTypes = {
  height: number,
  live: object,
  next: object
}

export default connect(state => state.matches.toJS())(LineupTile)
