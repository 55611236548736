exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollArea__ScrollArea___1gJgf{-webkit-overflow-scrolling:touch;-webkit-box-flex:1;-ms-flex:1 0 0px;flex:1 0 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.ScrollArea__x___QQMQc{width:100%;overflow-x:auto}.ScrollArea__y___16jQB{height:100%;overflow-y:auto}.ScrollArea__hideScrollbar___1BxHA::-webkit-scrollbar,.ScrollArea__hideScrollbar___1BxHA::-webkit-scrollbar-thumb,.ScrollArea__hideScrollbar___1BxHA::-webkit-scrollbar-track{background-color:rgba(0,0,0,0);-webkit-appearance:none;display:none}.ScrollArea__hideScrollbar___1BxHA::-webkit-scrollbar{height:0;width:0}", ""]);

// exports
exports.locals = {
	"ScrollArea": "ScrollArea__ScrollArea___1gJgf",
	"x": "ScrollArea__x___QQMQc",
	"y": "ScrollArea__y___16jQB",
	"hideScrollbar": "ScrollArea__hideScrollbar___1BxHA"
};