import React, { PureComponent } from 'react'
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types'

export class Audio extends PureComponent {
  render() {
    const {
      autoPlay,
      children,
      className,
      controls,
      crossOrigin,
      loop,
      muted,
      preload,
      src,
      setRef
    } = this.props

    return (
      <audio
        ref={el => {
          setRef(el)
        }}
        autoPlay={autoPlay}
        className={className}
        controls={controls}
        crossOrigin={crossOrigin}
        loop={loop}
        muted={muted}
        preload={preload}
        src={src}
      >
        {!children && <p>Sorry, your device doesn’t support embedded audio</p>}
        {children}
      </audio>
    )
  }
}

Audio.displayName = 'Audio'

Audio.defaultProps = {
  setRef: () => {}
}

Audio.propTypes = {
  autoPlay: bool,
  children: node,
  className: string,
  controls: bool,
  crossOrigin: oneOf(['anonymous', 'use-credentials']),
  loop: bool,
  muted: bool,
  preload: oneOfType([bool, oneOf(['none', 'metadata', 'auto'])]),
  src: string,
  setRef: func
}

export default Audio
