import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { node, number, object, oneOf, oneOfType, string } from 'prop-types'

import styles from './Position.styl'

export class Position extends PureComponent {
  render() {
    const {
      children,
      position,
      top,
      right,
      bottom,
      left,
      zIndex,
      style
    } = this.props

    return (
      <div
        className={classNames(styles.Position, position && styles[position])}
        style={{
          top,
          right,
          bottom,
          left,
          zIndex,
          ...style
        }}
      >
        {children}
      </div>
    )
  }
}

Position.displayName = 'Position'

Position.propTypes = {
  children: node.isRequired,
  position: oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky'])
    .isRequired,
  top: oneOfType([number, string]),
  right: oneOfType([number, string]),
  bottom: oneOfType([number, string]),
  left: oneOfType([number, string]),
  style: object,
  zIndex: number
}

export default Position
