import Immutable from 'immutable'
import reduceReducers from 'reduce-reducers'
import sortBy from 'orderby'

import createCrudReducer from '../../../../../lib/crud-reducer'
import getImage from '../../../../../lib/get-image'

const initialState = Immutable.fromJS({
  currentStep: 'loading',
  homeTeam: null,
  awayTeam: null,
  homeLineUp: null,
  awayLineUp: null
})

const sortByPosition = players =>
  sortBy(players, [player => Number(player.position)])

const addImages = lineUp => [
  ...lineUp.map(player => ({
    ...player,
    imageUrl:
      player.images &&
      player.images.widgets &&
      getImage(player.images.widgets, 'Line Up', 'Line Up', 200)
  }))
]

const fetchLineupMapper = ({ match }) => {
  if (!match) return { currentStep: 'error' }
  const { __homeTeam, __awayTeam } = match
  return {
    currentStep: 'team',
    homeTeam: __homeTeam,
    awayTeam: __awayTeam,
    homeLineUp: sortByPosition(addImages(__homeTeam.lineUp)),
    awayLineUp: sortByPosition(addImages(__awayTeam.lineUp))
  }
}
const fetchLineupReducer = createCrudReducer({
  prefix: 'LINEUPS',
  fetchMapper: fetchLineupMapper
})

const lineupsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reduceReducers(lineupsReducer, fetchLineupReducer)
