import React from 'react'
import { bool, func, object } from 'prop-types'

import ButtonDisplay from '../../../component/Primitive/ButtonDisplay'
import CheckControl from '../../../component/Primitive/Control/CheckControl'
import FieldTemplate from '../../../component/Primitive/FieldTemplate'
import Icon from '../../../component/Primitive/Icon'
import Margin from '../../../component/Primitive/Margin'
import MinimalLayout from '../../../component/Layout/MinimalLayout'
import Prose from '../../../component/Primitive/Prose'
import ScrollArea from '../../../component/Primitive/ScrollArea'
import Section from '../../../component/Primitive/Section'
import Separator from '../../../component/Primitive/Separator'
import SmartLink from '../../../component/Primitive/SmartLink'
import TextAlign from '../../../component/Primitive/TextAlign'
import Type from '../../../component/Primitive/Type'
import SpacedList from '../../../component/Primitive/SpacedList'

class UserGate extends React.PureComponent {
  componentWillMount() {
    if (this.props.complete) this.props.onAlreadyChecked()
  }
  render() {
    const { onSubmit, onChange, complete, errorProperties } = this.props
    if (complete) return null
    return (
      <MinimalLayout primary>
        <ScrollArea>
          <Section spacing="xt" center grow>
            <TextAlign center>
              <Icon type="Person" height={96} />
              <Type element="h1" size="title">
                About your data
              </Type>
              <Margin my={3}>
                <Separator />
              </Margin>
              <SpacedList>
                <Prose>
                  <p>
                    It’s yours, but we will ask you at times if we can use it to
                    help us give you a better&nbsp;experience.
                  </p>
                </Prose>
              </SpacedList>
            </TextAlign>
          </Section>
          <Section spacing="xy">
            <FieldTemplate
              controlName="termsAgreed"
              template="check"
              status={errorProperties.termsAgreed && 'error'}
              feedback={errorProperties.termsAgreed}
              required
              inverse
            >
              <CheckControl
                type="checkbox"
                name="termsAgreed"
                value="agreed"
                status={errorProperties.termsAgreed ? 'error' : undefined}
                onChange={onChange}
                required
              >
                <Prose inverse>
                  I agree to the{' '}
                  <SmartLink to="/terms">Terms and conditions</SmartLink>
                </Prose>
              </CheckControl>
            </FieldTemplate>
            <TextAlign center>
              <SpacedList>
                <ButtonDisplay block onClick={onSubmit}>
                  Next step
                </ButtonDisplay>
                <p>
                  <SmartLink to="/profile/login">
                    Login to existing account
                  </SmartLink>
                </p>
              </SpacedList>
            </TextAlign>
          </Section>
        </ScrollArea>
      </MinimalLayout>
    )
  }
}

UserGate.propTypes = {
  complete: bool,
  onSubmit: func,
  onChange: func,
  onAlreadyChecked: func,
  errorProperties: object
}

export default UserGate
