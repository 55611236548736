import React, { PureComponent } from 'react'
import { func, number } from 'prop-types'

import IconButton from '../IconButton'
import RangeControl from '../Control/RangeControl'

import ShrinkWrap, { ShrinkWrapItem } from '../ShrinkWrap'

export class MediaVolume extends PureComponent {
  constructor() {
    super()

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.onChange(e.currentTarget.value)
  }

  render() {
    const { onChange, volume } = this.props

    return (
      <ShrinkWrap fullwidth>
        <ShrinkWrapItem shrink>
          <IconButton
            icon="VolumeMute"
            a11yText="Mute"
            onClick={() => onChange('0')}
            transparent
            small
          />
        </ShrinkWrapItem>
        <ShrinkWrapItem>
          <RangeControl
            min="0"
            max="100"
            onChange={this.handleChange}
            value={`${volume}`}
            name="volume"
          />
        </ShrinkWrapItem>
        <ShrinkWrapItem shrink>
          <IconButton
            icon="VolumeUp"
            a11yText="Volume Full"
            onClick={() => onChange('100')}
            transparent
            small
          />
        </ShrinkWrapItem>
      </ShrinkWrap>
    )
  }
}

MediaVolume.displayName = 'MediaVolume'

MediaVolume.defaultProps = {
  volume: 25
}

MediaVolume.propTypes = {
  onChange: func.isRequired,
  volume: number
}

export default MediaVolume
