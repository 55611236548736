import React from 'react'
import { string, func } from 'prop-types'
import createDebug from 'debug'
import createAudioQueue from './lib/queue'
import Primus from './lib/primus'
import AudioOutput from '../AudioOutput'

const bufferSize = 4096
const audioQueue = createAudioQueue(bufferSize * 4)

let decoder = {
  decode: f => f,
  onDecode: decoded => {
    audioQueue.write(decoded)
  }
}

const debug = createDebug('audio-socket-source')

class AudioSockectSource extends React.Component {
  constructor(...arg) {
    super(...arg)

    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    this.state = {
      ready: false,
      live: false
    }
    this.audioBuffer = null
    this.createSource = this.createSource.bind(this)
    this.handleStop = this.handleStop.bind(this)
  }

  componentWillMount() {
    if (window.Worker) {
      debug('Has worker')
      this.worker = new Worker('./js/worker.js')
      decoder.decode = sample => this.worker.postMessage(['decode', sample])
      this.worker.postMessage(['init', this.audioContext.sampleRate])
      this.worker.onmessage = e => {
        decoder.onDecode(e.data)
      }
    }
    debug('Connecting to audio server', this.props.url)
    this.primus = Primus.connect(this.props.url)
    this.primus.on('data', data => {
      if (data.status) {
        debug('status received', data)
        this.setState({
          live: data.status === 'online'
        })
      }
    })
    this.primus.write({ request: 'getStatus' })
    this.primus.on('open', () => {
      debug('open')
      this.setState({ ready: true })
    })
    this.primus.on('end', () => {
      debug('end')
      this.setState({ ready: false })
    })
    this.primus.on('close', () => {
      debug('close')
      this.setState({ ready: false })
    })
  }

  componentWillUnmount() {
    this.primus.removeAllListeners('open')
    this.primus.removeAllListeners('end')
    this.primus.removeAllListeners('data')
    this.worker.terminate()
    this.primus.end()
  }
  decode(data) {
    decoder.decode(data)
  }
  createSource() {
    debug('create source')
    this.primus.on('data', this.decode)
    const processor = this.audioContext.createScriptProcessor(bufferSize, 1, 1)
    const silence = new Float32Array(bufferSize)
    processor.onaudioprocess = ({ inputBuffer, outputBuffer }) => {
      if (audioQueue.length() > bufferSize * 2) {
        const data = audioQueue.read(bufferSize)
        outputBuffer.getChannelData(0).set(data)
      } else {
        outputBuffer.getChannelData(0).set(silence)
      }
    }

    return processor
  }

  handleStop() {
    this.primus.removeListener('data', this.decode)
  }

  render() {
    return (
      <AudioOutput
        audioContext={this.audioContext}
        createSource={this.createSource}
        available={this.state.ready && this.state.live}
        Component={this.props.Component}
        onStop={this.handleStop}
      />
    )
  }
}

AudioSockectSource.propTypes = {
  url: string,
  Component: func
}

export default AudioSockectSource
