import React, { PureComponent } from 'react'

import Section from '../../../../../../component/Primitive/Section'
import TextAlign from '../../../../../../component/Primitive/TextAlign'
import Spinner from '../../../../../../component/Primitive/Spinner'

class QuizLoading extends PureComponent {
  render() {
    return (
      <Section spacing="xy" grow center>
        <TextAlign center>
          <Spinner spinning revealDelay={2000} />
        </TextAlign>
      </Section>
    )
  }
}

export default QuizLoading
