import React from 'react'
import { object, bool } from 'prop-types'

import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Type from '../../../../component/Primitive/Type'
import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'

const Thanks = ({ user, authenticated }) => (
  <Section grow>
    <ScrollArea>
      <Section spacing="xy">
        {!authenticated ? (
          <p>You are not logged in.</p>
        ) : (
          <SpacedList>
            <Type element="h1" size="display2">
              Welcome {user.firstName},
            </Type>
            <Prose>
              <p>Thanks for creating an account with Colooder.</p>
            </Prose>
            <ButtonDisplay block to="/featured/">
              Let's get started!
            </ButtonDisplay>
          </SpacedList>
        )}
      </Section>
    </ScrollArea>
  </Section>
)

Thanks.propTypes = {
  user: object,
  authenticated: bool
}

export default Thanks
