import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Summary.styl'

import ResponsiveMedia from '../ResponsiveMedia'
import SmartLink from '../SmartLink'
import Type from '../Type'

export class Summary extends PureComponent {
  render() {
    const { category, heading, imageUrl, to, theme } = this.props

    return (
      <SmartLink
        className={classNames(
          styles.Summary,
          theme && styles[`Summary-${theme}`]
        )}
        to={to}
      >
        <div className={styles.SummaryImage}>
          <ResponsiveMedia ratio={9 / 16}>
            <img src={imageUrl} />
          </ResponsiveMedia>
        </div>
        <div className={styles.SummaryContent}>
          <Type element="h3" size="base" className={styles.SummaryHeading}>
            {heading}
          </Type>
          <Type size="tiny" className={styles.SummaryCategory}>
            {category}
          </Type>
        </div>
      </SmartLink>
    )
  }
}

Summary.displayName = 'Summary'

Summary.propTypes = {
  category: string.isRequired,
  heading: node.isRequired,
  imageUrl: string.isRequired,
  theme: string,
  to: string.isRequired
}

export default withTheme(Summary)
