import React, { PureComponent } from 'react'
import { bool, node, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './ScrollArea.styl'

class ScrollArea extends PureComponent {
  render() {
    const { axis, hideScrollbar, children } = this.props

    return (
      <div
        className={classNames(
          styles.ScrollArea,
          axis === 'x' && styles.x,
          axis === 'y' && styles.y,
          hideScrollbar && styles.hideScrollbar
        )}
      >
        {children}
      </div>
    )
  }
}

ScrollArea.displayName = 'ScrollArea'

ScrollArea.defaultProps = {
  axis: 'y'
}

ScrollArea.propTypes = {
  axis: oneOf(['x', 'y']),
  hideScrollbar: bool,
  children: node
}

export default ScrollArea
