import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './IconAnimation.styl'

export class IconAnimation extends PureComponent {
  render() {
    const { animation, children } = this.props

    return (
      <div
        className={classNames(
          styles.IconAnimation,
          animation && styles[animation],
          styles.playing
        )}
        ref={wrapper => (this.wrapper = wrapper)}
      >
        {children}
      </div>
    )
  }
}

IconAnimation.displayName = 'IconAnimation'

IconAnimation.propTypes = {
  animation: string,
  children: node
}

export default IconAnimation
