exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Expander__Expander___20nM8{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.Expander__ExpanderToggle___3pyo5{text-align:center;padding:16px 24px;border-bottom:1px solid #d9d9d9}.Expander__up___1Tqw7 .Expander__ExpanderToggle___3pyo5{border-bottom:0;border-top:1px solid #d9d9d9;-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}.Expander__ExpanderToggle___3pyo5:focus{outline:0}.Expander__ExpanderContent___3utBR{overflow:hidden}.Expander__up___1Tqw7 .Expander__ExpanderContent___3utBR{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}.Expander__ExpanderContentInner___1qXR5{padding:16px 0}", ""]);

// exports
exports.locals = {
	"Expander": "Expander__Expander___20nM8",
	"ExpanderToggle": "Expander__ExpanderToggle___3pyo5",
	"up": "Expander__up___1Tqw7",
	"ExpanderContent": "Expander__ExpanderContent___3utBR",
	"ExpanderContentInner": "Expander__ExpanderContentInner___1qXR5"
};