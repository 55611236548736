import React, { PureComponent } from 'react'
import { bool, number, func } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class AudioRefLinkTile extends PureComponent {
  render() {
    const { onClick, height, playing, available } = this.props
    return (
      <Tile
        imageUrl={image}
        heading={
          <span>
            Listen to
            <br />
            RefFeed Live
          </span>
        }
        height={height}
        ctaText={available ? (playing ? 'Pause' : 'Play') : 'Offline'}
        ctaIcon={available ? (playing ? 'Pause' : 'Play') : 'Error'}
        ctaIconOffset={0}
        handleClick={onClick}
        active={available}
        description="First Team home fixtures only"
      />
    )
  }
}

AudioRefLinkTile.propTypes = {
  onClick: func,
  height: number,
  playing: bool,
  available: bool
}

export default AudioRefLinkTile
