import { connect } from 'react-redux'

import onComponentDidMount from '../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../component/Primus/with-primus'
import { register, change, resetErrors } from '../../actions'
import Form from '../component/Form'

const mapStateToProps = state => {
  return {
    ...state.profile.toJS(),
    dateOfBirth: state.userGate.toJS().data.dateOfBirth
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  resetErrors: () => dispatch(resetErrors()),
  onSubmit: (e, primus) => {
    e.preventDefault()
    dispatch(register(primus))
  },
  onChange: e =>
    dispatch(
      change({
        [e.currentTarget.name]:
          e.currentTarget.type === 'checkbox'
            ? e.currentTarget.checked
            : e.currentTarget.value
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(onComponentDidMount(({ resetErrors }) => resetErrors(), Form)))
