import React, { PureComponent } from 'react'
import Position from '../Position'

export class Sticky extends PureComponent {
  render() {
    return <Position top={0} {...this.props} position="sticky" />
  }
}

Sticky.displayName = 'Sticky'

export default Sticky
