import React, { PureComponent } from 'react'
import { bool, node } from 'prop-types'

import OverlayTemplate from '../../../component/Primitive/OverlayTemplate'
import MinimalTemplate from '../../../component/Primitive/MinimalTemplate'
import Crest from '../../../component/Primitive/Crest'
import Margin from '../../../component/Primitive/Margin'
import SmartLink from '../../../component/Primitive/SmartLink'
import Toolbar from '../../../component/Primitive/Toolbar'
import ToolbarBackButton from '../../../component/Primitive/Toolbar/ToolbarBackButton'

class OverlayLayout extends PureComponent {
  render() {
    const {
      children,
      showToolbar,
      reverse,
      buttonsBefore,
      buttonsAfter,
      ...other
    } = this.props

    const Header = (
      <Toolbar
        reverse={reverse}
        buttonsBefore={buttonsBefore || <ToolbarBackButton />}
        buttonsAfter={
          buttonsAfter || (
            <Margin mr={3}>
              <SmartLink to="/featured">
                <Crest
                  club="Gloucester"
                  a11yText="Gloucester Rugby"
                  width={24}
                />
              </SmartLink>
            </Margin>
          )
        }
      />
    )

    return (
      <OverlayTemplate {...other}>
        <MinimalTemplate header={showToolbar && Header}>
          {children}
        </MinimalTemplate>
      </OverlayTemplate>
    )
  }
}

OverlayLayout.defaultProps = {
  showToolbar: true
}

OverlayLayout.propTypes = {
  showToolbar: bool,
  buttonsBefore: node,
  buttonsAfter: node,
  children: node,
  reverse: bool
}

export default OverlayLayout
