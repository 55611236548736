exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Hero__HeroContent___39z-7{position:relative;padding:12px 16px 20px;margin-top:-48px;margin-left:8px}.Hero__HeroHeading___TH5s0{margin-bottom:8px}.Hero__Hero-base___3z8qe .Hero__HeroContent___39z-7{background-color:#00cfdd;color:#fff}.Hero__Hero-gloucester___3A6nu .Hero__HeroContent___39z-7{background-color:#da1a32;color:#fff}.Hero__Hero-inverse___1J3rh .Hero__HeroContent___39z-7{background-color:#ff9800;color:#000}", ""]);

// exports
exports.locals = {
	"HeroContent": "Hero__HeroContent___39z-7",
	"HeroHeading": "Hero__HeroHeading___TH5s0",
	"Hero-base": "Hero__Hero-base___3z8qe",
	"Hero-gloucester": "Hero__Hero-gloucester___3A6nu",
	"Hero-inverse": "Hero__Hero-inverse___1J3rh"
};