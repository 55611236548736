import React, { Component } from 'react'
import createDebug from 'debug'
import Section from '../../component/Primitive/Section'
import ScrollArea from '../../component/Primitive/ScrollArea'
import Type from '../../component/Primitive/Type'
import TileGrid from '../../component/Primitive/TileGrid'
import ShowsList from './Shows/Container/ShowsList'
import AudioSocketSource from '../../component/Audio/AudioSocketSource'
import AudioRefLinkTile from '../../component/Common/Tile/AudioRefLink'
import CherryAndWhiteTile from '../../component/Common/Tile/CherryAndWhite'
const debug = createDebug('audio-overlay')
const url = process.env.AUDIO_URL

export class AudioOverlay extends Component {
  componentDidCatch(error, info) {
    debug('error', error, info)
  }
  render() {
    return (
      <Section grow fullHeight>
        <ScrollArea>
          <Section spacing="xy">
            <Type element="div" size="display2">
              Audio
            </Type>
          </Section>
          <Section grow>
            <TileGrid>
              <AudioSocketSource
                url={url}
                Component={({ playing, available, onClick }) => (
                  <AudioRefLinkTile
                    playing={playing}
                    available={available}
                    onClick={onClick}
                  />
                )}
              />
              <ShowsList />
              <CherryAndWhiteTile />
            </TileGrid>
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

AudioOverlay.displayName = 'AudioOverlay'

export default AudioOverlay
