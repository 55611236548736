exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResultStat__ResultStat___sKk5z{line-height:1}.ResultStat__ResultStat___sKk5z *{margin-right:4px}.ResultStat__ResultStatAffix___2UjPk{display:inline-block;font-size:14px;line-height:16px;line-height:1}.ResultStat__ResultStatValue___2VbN4{display:inline-block;font-size:20px;line-height:32px;font-weight:900;text-transform:uppercase;line-height:1}", ""]);

// exports
exports.locals = {
	"ResultStat": "ResultStat__ResultStat___sKk5z",
	"ResultStatAffix": "ResultStat__ResultStatAffix___2UjPk",
	"ResultStatValue": "ResultStat__ResultStatValue___2VbN4"
};