import React from 'react'
import { object, func } from 'prop-types'
import FieldTemplate from '../../../component/Primitive/FieldTemplate'
import Prose from '../../../component/Primitive/Prose'
import CheckControl from '../../../component/Primitive/Control/CheckControl'

import createConsentOptions from '../lib/consent-options'

const consentOptions = createConsentOptions()

const ConsentOptions = ({ data, errorProperties, onChange }) =>
  consentOptions.map(consent => (
    <FieldTemplate
      key={`register-consent-${consent.name}`}
      controlName={consent.name}
      template="check"
      required
      status={errorProperties[consent.name] && 'error'}
      feedback={errorProperties[consent.name]}
    >
      <CheckControl
        type="checkbox"
        name={consent.name}
        value={consent.name}
        checked={!!data[consent.name]}
        status={errorProperties[consent.name] && 'error'}
        onChange={onChange}
      >
        <Prose>{consent.label}</Prose>
      </CheckControl>
    </FieldTemplate>
  ))

ConsentOptions.propTypes = {
  data: object,
  errorProperties: object,
  onChange: func
}

export default ConsentOptions
