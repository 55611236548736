import React, { PureComponent } from 'react'

import OverlayLayout from '../../../component/Layout/OverlayLayout'
import Section from '../../../component/Primitive/Section'
import ScrollArea from '../../../component/Primitive/ScrollArea'
import SpacedList from '../../../component/Primitive/SpacedList'
import Type from '../../../component/Primitive/Type'
import Prose from '../../../component/Primitive/Prose'

class Facilities extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <Section grow>
          <ScrollArea>
            <Section spacing="xt">
              <Type element="h1" size="display2">
                Facilities for Disabled Supporters
              </Type>
            </Section>
            <Section spacing="xy">
              <SpacedList>
                <Prose>
                  <p>
                    Kingsholm Stadium is easily accessible and all entrances
                    have wheelchair access gates.
                  </p>
                  <p>
                    There is ample disabled seating at pitch side as well as in
                    some high-level locations. All stands have disabled toilet
                    facilities.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Parking
                </Type>
                <Prose>
                  <p>
                    There is limited parking on site for disabled drivers and
                    all are currently sold in advance.
                  </p>
                  <p>
                    The Barnwood Park and Ride (EDF Energy site) use specially
                    adapted buses to transport guests directly to the stadium.
                    As well as this we have several NCP car parks near the
                    stadium with disabled spaces.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Corporate Areas
                </Type>
                <Prose>
                  <p>
                    There are various packages available for disabled
                    supporters.
                  </p>
                  <p>
                    For full information please call{' '}
                    <a href="tel:08718718781">0871 871 8781</a>, Option 4.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Places & Positions
                </Type>
                <Prose>
                  <p>
                    The Stowford Press Stand has a number of front row spaces
                    with the ability for a helper to sit alongside a wheelchair
                    user.
                  </p>
                  <p>
                    The South (Mira Showers) Stand also has front row spaces
                    with a helper alongside. It also has eight spaces on a high
                    viewing platform, again with the ability for a helper to sit
                    alongside.
                  </p>
                  <p>
                    Call the Ticket Office on{' '}
                    <a href="tel:08718718781">0871 871 8781</a> Option 1.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Toilets
                </Type>
                <Prose>
                  <p>
                    Stowford Press Stand spectators can use the disabled toilets
                    at the East End of the South (Mira Showers) Stand located
                    opposite the side entrance to the club shop.
                  </p>
                  <p>
                    Mira Showers Stand spectators can use the toilets on all
                    levels of the stand using lifts if necessary.
                  </p>
                  <p>Stewards are present to assist and direct if required.</p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Food & Retail
                </Type>
                <Prose>
                  <p>
                    All food and retail outlets are available to disabled
                    supporters with either with a lowered counter area or by
                    again asking your nearest steward to assist you.
                  </p>
                  <p>
                    Please remember to ask a steward for help or directions if
                    needed.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  Contact
                </Type>
                <Prose>
                  <p>
                    For any disability issues call{' '}
                    <a href="tel:01452876578">01452 876578</a>.
                  </p>
                  <p>
                    For ticketing queries, please call{' '}
                    <a href="tel:08718718781">0871 871 8781</a>.
                  </p>
                </Prose>
              </SpacedList>
            </Section>
          </ScrollArea>
        </Section>
      </OverlayLayout>
    )
  }
}

export default Facilities
