exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Form__Form___1GPHJ{width:100%}", ""]);

// exports
exports.locals = {
	"Form": "Form__Form___1GPHJ"
};