import React, { PureComponent } from 'react'
import { bool, node, oneOfType, string } from 'prop-types'

import CustomCheckControl from './CustomCheckControl'
import NativeCheckControl from './NativeCheckControl'
import ShrinkWrap, { ShrinkWrapItem } from '../../ShrinkWrap'

class CheckControl extends PureComponent {
  render() {
    const { children, native } = this.props

    const CheckControlType = native ? NativeCheckControl : CustomCheckControl

    return (
      <ShrinkWrap element="label" vAlign="middle">
        <ShrinkWrapItem shrink>
          <CheckControlType {...this.props} />
        </ShrinkWrapItem>
        <ShrinkWrapItem>{children}</ShrinkWrapItem>
      </ShrinkWrap>
    )
  }
}

CheckControl.propTypes = 'CheckControl'

CheckControl.propTypes = {
  children: oneOfType([string, node]).isRequired,
  native: bool
}

export default CheckControl
