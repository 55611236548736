import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Prose.styl'

class Prose extends PureComponent {
  render() {
    const { children, className, html, inverse, theme } = this.props
    if (!children && !html) return null
    return (
      <div
        className={classNames(
          styles.Prose,
          theme && styles[`Prose-${theme}`],
          inverse && styles.inverse,
          theme && inverse && styles[`inverse-${theme}`],
          className
        )}
        {...html && { dangerouslySetInnerHTML: { __html: html } }}
      >
        {children}
      </div>
    )
  }
}

Prose.displayName = 'Prose'

Prose.propTypes = {
  children: node,
  className: string,
  html: string,
  inverse: bool,
  theme: string
}

export default withTheme(Prose)
