import React from 'react'

import { Tweet } from 'react-twitter-widgets'

const socialComponents = {
  tweet: socialId => <Tweet tweetId={socialId} />
}

export default ({ type, socialId }) => {
  const socialComponent = socialComponents[type]
  return socialComponent ? socialComponent(socialId) : null
}
