import { push } from 'react-router-redux'

import createCrudActioner from '../../../../../lib/crud-actioner'

const gallerySchema = require('../../../../../../../components/service/gallery/schema')()

const { create: createGallery, fetch: fetchGallery } = createCrudActioner({
  prefix: 'GALLERY',
  action: 'gallery'
})

export { createGallery, fetchGallery }

export const PHOTO_FORM_CHANGE = 'PHOTO_FORM_CHANGE'
export const PHOTO_NO_CAMERA = 'PHOTO_NO_CAMERA'
export const PHOTO_SET_IMAGE = 'PHOTO_SET_IMAGE'
export const PHOTO_SET_ERROR = 'PHOTO_SET_ERROR'
export const PHOTO_SEND = 'PHOTO_SEND'
export const PHOTO_SEND_COMPLETE = 'PHOTO_SEND_COMPLETE'
export const PHOTO_SEND_FAILURE = 'PHOTO_SEND_FAILURE'
export const PHOTO_CLEAR_ERRORS = 'PHOTO_CLEAR_ERRORS'
export const PHOTO_SET_SOURCE = 'PHOTO_SET_SOURCE'
export const GALLERY_FETCH = 'GALLERY_FETCH'
export const GALLERY_FETCH_COMPLETE = 'GALLERY_FETCH_COMPLETE'
export const GALLERY_FETCH_FAILURE = 'GALLERY_FETCH_FAILURE'
export const PHOTO_VALIDATION_ERRORS = 'PHOTO_VALIDATION_ERRORS'

export const change = data => ({
  type: PHOTO_FORM_CHANGE,
  data
})

export const setImage = image => ({
  type: PHOTO_SET_IMAGE,
  image
})

export const setError = error => ({
  type: PHOTO_SET_ERROR,
  error
})

export const setValidationErrors = errors => ({
  type: PHOTO_VALIDATION_ERRORS,
  errors
})

export const noCamera = () => ({
  type: PHOTO_NO_CAMERA
})

export const clearErrors = () => ({
  type: PHOTO_CLEAR_ERRORS
})

export const setSource = source => (dispatch, getState) => {
  dispatch({ type: PHOTO_SET_SOURCE, source })
  dispatch(push('/activity/play/gallery/form'))
  dispatch(clearErrors())
}

export const validate = primus => (dispatch, getState) => {
  const state = getState().gallery.toJS()
  const formData = {
    name: state.name,
    emailAddress: state.emailAddress,
    images: state.image ? { image: state.image } : null
  }

  gallerySchema.validate(formData, (error, validationErrors) => {
    if (Object.keys(validationErrors).length !== 0) {
      dispatch(setValidationErrors(validationErrors))
    } else if (error) {
      dispatch(setError(error))
    } else {
      dispatch({ type: PHOTO_SEND })
      dispatch(send(primus))
    }
  })
}

export const send = primus => (dispatch, getState) => {
  const state = getState().gallery.toJS()
  const data = {
    name: state.name,
    message: state.message,
    emailAddress: state.emailAddress,
    image: state.image
  }

  dispatch({ type: PHOTO_SEND })

  primus.writeAndWait(
    { action: 'gallery:create', data },
    ({ error, response }) => {
      if (error) return dispatch(sendFailure(error.message))
      if (response._id) return dispatch(sendComplete())
      dispatch(
        sendFailure(
          'There was an problem sending your photo. Please try again later.'
        )
      )
    }
  )
}

export const sendComplete = () => (dispatch, getState) => {
  dispatch(push('/activity/play/gallery/thanks'))
  dispatch({ type: PHOTO_SEND_COMPLETE })
}

export const sendFailure = error => ({
  type: PHOTO_SEND_FAILURE,
  error
})
