import React, { PureComponent } from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import shuffle from 'lodash.shuffle'

import styles from './QuizQuestion.styl'

import QuizQuestionAnswer from './QuizQuestionAnswer'
import ButtonDisplay from '../ButtonDisplay'
import Section from '../Section'
import SpacedList from '../SpacedList'
import Prose from '../Prose'
import Type from '../Type'

export class QuizQuestion extends PureComponent {
  constructor() {
    super()
    this.state = {
      submitted: false,
      userAnswer: '',
      shuffledAnswers: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleComplete = this.handleComplete.bind(this)
    this.handleNewQuestion = this.handleNewQuestion.bind(this)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleChange(e) {
    if (this.state.submitted) return
    this.setState({ userAnswer: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (!this.state.userAnswer) return
    this.setState({ submitted: true })

    const correctAnswer = this.props.answers.filter(
      answer => answer.correct === true
    )
    const correctValue = correctAnswer[0].value
    this.props.handleSubmit(correctValue === this.state.userAnswer)
    this.timeout = setTimeout(this.handleComplete, 2000)
  }

  handleComplete() {
    this.props.handleComplete()
  }

  handleNewQuestion() {
    this.setState({
      submitted: false,
      userAnswer: '',
      shuffledAnswers: shuffle(this.props.answers)
    })
  }

  componentDidMount() {
    this.handleNewQuestion()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question !== this.props.question) {
      this.handleNewQuestion()
    }
  }

  render() {
    const { heading, question } = this.props
    return (
      <form className={styles.QuizQuestion}>
        <Section grow>
          <Section spacing="xy">
            <SpacedList>
              <Type size="display1">{heading}</Type>
              <Prose>
                <p>{question}</p>
              </Prose>
            </SpacedList>
          </Section>
          <ol className={styles.QuizQuestionList}>
            {this.state.shuffledAnswers.map((answer, i) => {
              return (
                <QuizQuestionAnswer
                  key={`QuizQuestionAnswer${i}`}
                  selected={this.state.userAnswer === answer.value}
                  disabled={this.state.submitted}
                  onClick={this.handleChange}
                  {...answer}
                />
              )
            })}
          </ol>
        </Section>

        <Section spacing="xy">
          {!this.state.submitted && this.state.userAnswer && (
            <ButtonDisplay
              className={styles.QuizQuestionSubmitButton}
              block
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </ButtonDisplay>
          )}
        </Section>
      </form>
    )
  }
}

QuizQuestion.displayName = 'QuizQuestion'

QuizQuestion.defaultProps = {
  completeCtaText: 'Next Question',
  handleSubmit: data => {
    console.log('Complete', data)
  },
  handleComplete: data => {
    console.log('Complete', data)
  }
}

QuizQuestion.propTypes = {
  completeCtaText: string,
  heading: string,
  question: string.isRequired,
  answers: arrayOf(
    shape({
      text: string.isRequired,
      value: string,
      correct: bool
    })
  ).isRequired,
  handleSubmit: func,
  handleComplete: func
}

export default QuizQuestion
