const crypto = require('crypto')
const twentyFourHours = 86400000

const calc = secret => {
  const date = new Date()
  const dayOfWeek = date.getDay()
  const lastMonday = new Date(
    date.getTime() - (dayOfWeek - 1) * twentyFourHours
  )
  const plain = secret + lastMonday.toISOString().substring(0, 10)
  const password = crypto
    .createHash('md5')
    .update(plain)
    .digest('hex')
    .substr(0, 8)

  return { plain, password, date: lastMonday }
}

export default calc
