import React, { PureComponent } from 'react'

import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import FieldTemplate from '../../../../component/Primitive/FieldTemplate'
import Form from '../../../../component/Primitive/Form'
import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import Type from '../../../../component/Primitive/Type'
import SpacedList from '../../../../component/Primitive/SpacedList'
import TextControl from '../../../../component/Primitive/Control/TextControl'

class PasswordRequest extends PureComponent {
  render() {
    const errors = []
    const data = []

    return (
      <ScrollArea>
        <Section spacing="xy">
          <SpacedList>
            <Type element="h1" size="display2">
              Password Reset
            </Type>
            <Prose>
              <p>
                Enter your email address and we’ll send you an email with
                instructions to reset your password.
              </p>
            </Prose>
            <Form
              action=""
              method="post"
              onSubmit={() => {
                console.log('submitted')
              }}
            >
              <FieldTemplate
                controlName="email"
                label="Email Address"
                required
                {...!!errors.email && {
                  feedback: errors.email,
                  status: 'error'
                }}
              >
                <TextControl
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={data.email}
                  onChange={this.handleChange}
                  {...!!errors.email && {
                    status: 'error'
                  }}
                />
              </FieldTemplate>
              <ButtonDisplay type="submit" block>
                Reset Password
              </ButtonDisplay>
            </Form>
          </SpacedList>
        </Section>
      </ScrollArea>
    )
  }
}

export default PasswordRequest
