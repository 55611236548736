import React, { PureComponent } from 'react'
import { func, node, number, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Cover.styl'

import Section from '../Section'
import Crest from '../Crest'
import SpacedList from '../SpacedList'
import Type from '../Type'
import Prose from '../Prose'
import ButtonDisplay from '../ButtonDisplay'
import ResponsiveMedia from '../ResponsiveMedia'

export class Cover extends PureComponent {
  render() {
    const {
      children,
      crestClub,
      crestDisplayName,
      cta,
      imageAlt,
      imageSrc,
      imageRatio,
      onClick,
      preTitle,
      theme,
      title,
      to
    } = this.props

    return (
      <div
        className={classNames(styles.Cover, theme && styles[`Cover-${theme}`])}
      >
        <Section grow spacing="xt">
          {crestClub && (
            <div className={styles.CoverCrest}>
              <Crest club={crestClub} a11yText={crestDisplayName} />
            </div>
          )}
          {imageSrc && (
            <div className={styles.CoverImage}>
              <ResponsiveMedia ratio={imageRatio}>
                <img src={imageSrc} alt={imageAlt} />
              </ResponsiveMedia>
            </div>
          )}
          <SpacedList>
            <div>
              {preTitle && <Type size="display3">{preTitle}</Type>}
              {title && <Type size="display4">{title}</Type>}
            </div>
            <Prose>{children}</Prose>
          </SpacedList>
        </Section>
        <Section spacing="xy">
          {(to || onClick) && (
            <ButtonDisplay
              block
              reverse
              {...onClick && { onClick }}
              {...to && { to }}
            >
              {cta}
            </ButtonDisplay>
          )}
        </Section>
      </div>
    )
  }
}

Cover.displayName = 'Cover'

Cover.defaultProps = {
  imageAlt: '',
  imageRatio: 9 / 16
}

Cover.propTypes = {
  children: node,
  crestClub: string,
  crestDisplayName: string,
  cta: string,
  imageRatio: number,
  imageSrc: string,
  imageAlt: string,
  onClick: func,
  preTitle: string,
  theme: string,
  title: string,
  to: string
}

export default withTheme(Cover)
