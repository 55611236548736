exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Separator__Separator___1yKut{line-height:0}.Separator__SeparatorCircles___1ZmY-{width:52px;margin:0 auto;fill:currentColor}", ""]);

// exports
exports.locals = {
	"Separator": "Separator__Separator___1yKut",
	"SeparatorCircles": "Separator__SeparatorCircles___1ZmY-"
};