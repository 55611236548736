export const KEYBOARD_SHOW = 'KEYBOARD_SHOW'
export const KEYBOARD_HIDE = 'KEYBOARD_HIDE'

export const show = () => ({
  type: KEYBOARD_SHOW
})

export const hide = () => ({
  type: KEYBOARD_HIDE
})
