import React from 'react'

import ButtonDisplay from '../../../../../../component/Primitive/ButtonDisplay'
import Prose from '../../../../../../component/Primitive/Prose'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea'
import Section from '../../../../../../component/Primitive/Section'
import SpacedList from '../../../../../../component/Primitive/SpacedList'
import Type from '../../../../../../component/Primitive/Type'
import OverlayLayout from '../../../../../../component/Layout/OverlayLayout'

const GalleryThanks = () => (
  <OverlayLayout>
    <Section grow>
      <ScrollArea>
        <Section spacing="xt">
          <Type element="h1" size="display2">
            Fan Gallery
          </Type>
        </Section>
        <Section spacing="xy">
          <SpacedList>
            <Prose>Thanks! Your photo has been added</Prose>
            <ButtonDisplay to="/activity/play/gallery" block>
              Return to Gallery
            </ButtonDisplay>
          </SpacedList>
        </Section>
      </ScrollArea>
    </Section>
  </OverlayLayout>
)

export default GalleryThanks
