import React, { PureComponent } from 'react'
import { bool, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './BackgroundImage.styl'

class BackgroundImage extends PureComponent {
  render() {
    const { alt, color, fullsize, position, ratio, size, url } = this.props
    const formattedRatio = parseFloat((ratio * 100).toFixed(4))

    return (
      <div
        className={classNames(
          styles.BackgroundImage,
          fullsize && styles.fullsize
        )}
        style={{
          backgroundColor: color,
          backgroundPosition: position,
          paddingBottom: `${formattedRatio}%`,
          backgroundSize: size,
          backgroundImage: `url(${url})`
        }}
        {...alt !== '' && {
          role: 'img',
          'aria-label': alt
        }}
      />
    )
  }
}

BackgroundImage.displayName = 'BackgroundImage'

BackgroundImage.propTypes = {
  alt: string.isRequired,
  color: string,
  fullsize: bool,
  position: string,
  ratio: number,
  size: string,
  url: string.isRequired
}

export default BackgroundImage
