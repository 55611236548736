import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Detail.styl'

import Icon from '../Icon'
import Type from '../Type'
import Prose from '../Prose'

export class Detail extends PureComponent {
  render() {
    const { children, icon, label, theme } = this.props

    return (
      <div
        className={classNames(
          styles.Detail,
          theme && styles[`Detail-${theme}`]
        )}
      >
        <div className={styles.DetailHeader}>
          <span className={styles.DetailIcon}>
            <Icon type={icon} width={16} />
          </span>
          <Type size="tiny">{label}</Type>
        </div>
        <Prose>{children}</Prose>
      </div>
    )
  }
}

Detail.displayName = 'Detail'

Detail.propTypes = {
  children: node,
  icon: string,
  label: string,
  theme: string
}

export default withTheme(Detail)
