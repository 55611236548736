import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, number } from 'prop-types'
import styles from './Spinner.styl'

class Spinner extends PureComponent {
  render() {
    const { spinning, revealDelay } = this.props
    return (
      <div
        className={classNames(styles.Spinner, spinning && styles.spinning)}
        style={{ animationDelay: revealDelay && `${revealDelay}ms` }}
      >
        <div className={classNames(styles.SpinnerInner)}>
          <svg viewBox="0 0 21 21">
            <circle cx="10.5" cy="1.5" r="1.5" />
            <circle cx="4.1" cy="4.1" r="1.5" />
            <circle cx="1.5" cy="10.5" r="1.5" />
            <circle cx="4.1" cy="16.9" r="1.5" />
            <circle cx="10.5" cy="19.5" r="1.5" />
            <circle cx="16.9" cy="16.9" r="1.5" />
            <circle cx="19.5" cy="10.5" r="1.5" />
            <circle cx="16.9" cy="4.1" r="1.5" />
          </svg>
        </div>
      </div>
    )
  }
}

Spinner.displayName = 'Spinner'

Spinner.propTypes = {
  spinning: bool,
  revealDelay: number
}

export default Spinner
