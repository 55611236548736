import { push } from 'react-router-redux'

const debug = require('debug')('notification')

const init = ({ dispatch }) => {
  debug('Init')
  if (window.FirebasePlugin) {
    debug('Setup push notification response')
    window.FirebasePlugin.onNotificationOpen(
      notification => {
        debug(notification)
        if (notification.link && notification.link) {
          debug('Attempting to link')
          dispatch(push(notification.link))
        }
      },
      error => {
        debug(error)
      }
    )
  }
}

export default init
