import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'

import styles from './Directions.styl'

import ButtonDisplay from '../ButtonDisplay'

const getDirectionsLink = address => {
  const url = {
    iOS: `http://maps.apple.com/?daddr=${encodeURIComponent(address)}`,
    Android: `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`
  }

  const platform =
    typeof window !== 'undefined' && window.device && window.device.platform

  return url[platform] || url['Android']
}

export class Directions extends PureComponent {
  render() {
    const { address, map } = this.props

    return (
      <div className={styles.Directions}>
        <div className={styles.DirectionsMap}>{map}</div>
        <div className={styles.DirectionsAction}>
          <ButtonDisplay href={getDirectionsLink(address)} block>
            Get Directions
          </ButtonDisplay>
        </div>
      </div>
    )
  }
}

Directions.displayName = 'Directions'

Directions.propTypes = {
  map: node,
  address: string
}

export default Directions
