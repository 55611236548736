import React, { PureComponent } from 'react'
import { node, object, string, bool } from 'prop-types'
import { withRouter } from 'react-router-dom'

import MainTemplate from '../../../component/Primitive/MainTemplate'
import TabBar from '../../../component/Primitive/TabBar'
import TabBarItem from '../../../component/Primitive/TabBar/TabBarItem'

const Footer = ({ pathname }) => (
  <TabBar pathname={pathname}>
    <TabBarItem to="/featured" icon="Star">
      Featured
    </TabBarItem>
    <TabBarItem to="/activity" icon="RugbyBall">
      Rugby
    </TabBarItem>
    <TabBarItem to="/audio" icon="Headset" featured>
      Audio
    </TabBarItem>
    <TabBarItem to="/venue" icon="Venue">
      Venue
    </TabBarItem>
    <TabBarItem to="/profile/login" icon="Person">
      Me
    </TabBarItem>
  </TabBar>
)

Footer.defaultProps = {
  pathname: ''
}

Footer.propTypes = {
  pathname: string
}

class MainLayout extends PureComponent {
  render() {
    const { children, location, keyboardHidden } = this.props

    return (
      <MainTemplate
        footer={keyboardHidden && <Footer pathname={location.pathname} />}
      >
        {children}
      </MainTemplate>
    )
  }
}

MainLayout.propTypes = {
  children: node,
  location: object,
  keyboardHidden: bool
}

export default withRouter(MainLayout)
