import React, { PureComponent } from 'react'
import { object, string, number, func } from 'prop-types'

import Tile from 'src/component/Primitive/Tile'
import image from './image/background.jpg'

export class SeatServeTile extends PureComponent {
  constructor(...args) {
    super(...args)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    this.props.onClick(
      this.props.primus,
      this.props.emailAddress,
      this.props.key
    )
  }
  render() {
    const { height, emailAddress } = this.props
    return (
      <Tile
        heading="In-Seat Delivery"
        ctaText={
          'Food+drink delivered to your seat\n' + (emailAddress ? '' : ' *')
        }
        handleClick={this.handleClick}
        imageUrl={image}
        height={height}
      />
    )
  }
}

SeatServeTile.propTypes = {
  emailAddress: string,
  key: string,
  primus: object,
  height: number,
  onClick: func
}

export default SeatServeTile
