import React, { Fragment, PureComponent } from 'react'
import { number, object } from 'prop-types'
import { connect } from 'react-redux'
import Countdown from '../../../Primitive/Countdown/container'
import Type from '../../../Primitive/Type'
import Prose from '../../../Primitive/Prose'
import TileCustom from '../../../Primitive/TileCustom'
import TextAlign from '../../../Primitive/TextAlign'
import SpacedList from '../../../Primitive/SpacedList'
import Margin from '../../../Primitive/Margin'
import CrestLockup from '../../../Primitive/CrestLockup'
import Crest from '../../../Primitive/Crest'

import image from './image/background.jpg'

import difference from '../../../../lib/minutes-differencer'

export class MatchPreviewTile extends PureComponent {
  render() {
    const { height, next } = this.props
    if (next && difference(next.dateTime) > 0) return null
    return (
      <TileCustom imageUrl={image} height={height}>
        <TextAlign center>
          {next ? (
            <SpacedList>
              <CrestLockup
                homeCrest={
                  <Crest
                    club={next.__homeTeam.name}
                    a11yText={next.__homeTeam.displayName}
                    constrain={96}
                    reverse
                  />
                }
                awayCrest={
                  <Crest
                    club={next.__awayTeam.name}
                    a11yText={next.__awayTeam.displayName}
                    constrain={96}
                    reverse
                  />
                }
                large
              />

              {next.dateTime && (
                <Fragment>
                  <Margin mt={6}>
                    <Type element="div" size="display2">
                      Available in
                    </Type>
                  </Margin>
                  <Countdown targetTime={new Date(next.dateTime)} />
                </Fragment>
              )}
            </SpacedList>
          ) : (
            <Prose>
              <p>No upcoming matches scheduled</p>
            </Prose>
          )}
        </TextAlign>
      </TileCustom>
    )
  }
}

MatchPreviewTile.propTypes = {
  height: number,
  next: object
}

export default connect(state => state.matches.toJS())(MatchPreviewTile)
