exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Rule__Rule___1vRaS{position:relative;height:16px}.Rule__Rule___1vRaS:after{content:\"\";position:absolute;top:50%;right:0;left:0;height:1px;background-color:currentColor}.Rule__muted___Kmf4P{opacity:.2}.Rule__spaced___1NDhW{margin-top:16px;margin-bottom:16px}", ""]);

// exports
exports.locals = {
	"Rule": "Rule__Rule___1vRaS",
	"muted": "Rule__muted___Kmf4P",
	"spaced": "Rule__spaced___1NDhW"
};