import React, { PureComponent } from 'react'

import Section from '../../component/Primitive/Section'
import ScrollArea from '../../component/Primitive/ScrollArea'
import TileGrid from '../../component/Primitive/TileGrid'

import VenueInfoTile from '../../component/Common/Tile/VenueInfo'
import FacilitiesTile from '../../component/Common/Tile/Facilities'
import WifiTile from '../../component/Common/Tile/Wifi'

class Venue extends PureComponent {
  render() {
    return (
      <Section grow fullHeight>
        <ScrollArea>
          <TileGrid>
            <VenueInfoTile height={2 / 4} />
            <FacilitiesTile height={1 / 4} />
            <WifiTile height={1 / 4} />
          </TileGrid>
        </ScrollArea>
      </Section>
    )
  }
}

export default Venue
