import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withPrimus from '../../../../component/Primus/with-primus'
import Show from '../Component/Show'

import { fetchEpisodesForShow } from '../actions'

class ShowContainer extends Component {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
  }

  componentDidMount() {
    const showId = this.props.match.params.showId
    this.props.fetchEpisodesForShow(this.primus, showId)
  }

  render() {
    return <Show show={this.props.show} episodes={this.props.episodes} />
  }
}

ShowContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ showId: PropTypes.string })
  }),
  primus: PropTypes.object.isRequired,
  fetchEpisodesForShow: PropTypes.func.isRequired,
  show: PropTypes.object,
  episodes: PropTypes.array
}

ShowContainer.defaultProps = {
  show: {},
  episodes: []
}

const mapStateToProps = (state, ownProps) => {
  const showId = ownProps.match.params.showId
  const showState = state.shows.toJS()
  const show = showState.shows.find(show => show._id === showId)
  return { ...ownProps, show, episodes: showState.episodes }
}
const mapDispatchToProps = dispatch => ({
  fetchEpisodesForShow: (primus, showId) =>
    dispatch(fetchEpisodesForShow(primus, { showId }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(ShowContainer))
