import createDebug from 'debug'
const debug = createDebug('notifcations')
export const NOTIFICATION_SUBSCRIBE_ALL = 'NOTIFICATION_SUBSCRIBE_ALL'
export const NOTIFICATION_IGNORE = 'NOTIFICATION_IGNORE'

export const subscribeAll = () => (dispatch, getState) => {
  dispatch({
    type: NOTIFICATION_SUBSCRIBE_ALL
  })
  debug('Subscribe all')
  if (window.FirebasePlugin) {
    debug('Firebase detected')
    window.FirebasePlugin.hasPermission(data => {
      debug('Permissions detected', data)
      if (window.device && window.device.platform === 'iOS')
        window.FirebasePlugin.grantPermission()
      window.FirebasePlugin.subscribe('news')
      window.FirebasePlugin.subscribe('matchEvents')
    })
  } else {
    debug('No firebase detected')
  }
}

export const ignore = () => ({
  type: NOTIFICATION_IGNORE
})
