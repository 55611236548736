exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResponsiveTableDataCell__ResponsiveTableDataCell___3mPui{text-align:center;padding:16px 8px;font-variant-numeric:tabular-nums;vertical-align:baseline;border-bottom:1px solid;font-size:14px;line-height:16px}.ResponsiveTableDataCell__ResponsiveTableDataCell___3mPui:first-child{padding-left:24px}.ResponsiveTableDataCell__ResponsiveTableDataCell___3mPui:last-child{padding-right:24px}.ResponsiveTableDataCell__ResponsiveTableDataCell-gloucester___3erf4{border-bottom-color:#d9d9d9}", ""]);

// exports
exports.locals = {
	"ResponsiveTableDataCell": "ResponsiveTableDataCell__ResponsiveTableDataCell___3mPui",
	"ResponsiveTableDataCell-gloucester": "ResponsiveTableDataCell__ResponsiveTableDataCell-gloucester___3erf4"
};