import React from 'react'
import { bool, node, number, string } from 'prop-types'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps'

import styles from './Map.styl'

const key = process.env.GOOGLE_MAP_KEY

const Map = withScriptjs(
  withGoogleMap(props => {
    const { showMarker, lat, lng, defaultZoom } = props

    return (
      <div className={styles.Map}>
        <GoogleMap
          defaultZoom={defaultZoom}
          defaultCenter={{ lat, lng }}
          defaultOptions={{ disableDefaultUI: true }}
        >
          {showMarker && <Marker position={{ lat, lng }} />}
        </GoogleMap>
      </div>
    )
  })
)

Map.displayName = 'Map'

Map.defaultProps = {
  defaultZoom: 15,
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `360px` }} />,
  mapElement: <div style={{ height: `100%` }} />
}

Map.propTypes = {
  defaultZoom: number,
  showMarker: bool,
  googleMapURL: string,
  loadingElement: node,
  containerElement: node,
  mapElement: node,
  lat: number.isRequired,
  lng: number.isRequired
}

class MapErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error)
    console.log(info)
  }
  render() {
    return <Map {...this.props} />
  }
}

export default MapErrorBoundary
