import React, { PureComponent } from 'react'
import { array } from 'prop-types'

import styles from './ImageList.styl'

import ResponsiveMedia from '../ResponsiveMedia'

export class ImageList extends PureComponent {
  render() {
    const { images } = this.props

    if (images.length < 1) return null

    return (
      <div className={styles.ImageList}>
        {images.map((image, i) => (
          <div className={styles.ImageListImage} key={`ImageListItem${i}`}>
            <ResponsiveMedia ratio={image.ratio}>
              <img src={image.url} alt={image.alt || ''} />
            </ResponsiveMedia>
          </div>
        ))}
      </div>
    )
  }
}

ImageList.displayName = 'ImageList'

ImageList.defaultProps = {
  images: []
}

ImageList.propTypes = {
  images: array
}

export default ImageList
