exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ResponsiveMedia__ResponsiveMedia___2kXUX{position:relative;height:0;overflow:hidden;padding-bottom:100%;text-align:left}.ResponsiveMedia__ResponsiveMedia___2kXUX>img{width:100%}.ResponsiveMedia__ResponsiveMedia___2kXUX>iframe{position:absolute;width:100%;height:100%}.ResponsiveMedia__visible___1TZH0{background-color:rgba(0,0,0,.1)}", ""]);

// exports
exports.locals = {
	"ResponsiveMedia": "ResponsiveMedia__ResponsiveMedia___2kXUX",
	"visible": "ResponsiveMedia__visible___1TZH0"
};