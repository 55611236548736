exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Countdown__Countdown___SLf5e{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.Countdown__CountdownItem___3ufqj{text-align:center;width:80px;padding:16px 8px;background-color:grey;color:#000}.Countdown__CountdownItem___3ufqj+.Countdown__CountdownItem___3ufqj{margin-left:5px}.Countdown__CountdownItemValue___3nNyz{font-size:24px;line-height:32px;font-weight:900;text-transform:uppercase;font-variant-numeric:tabular-nums}.Countdown__CountdownItemLabel___291-r{font-size:11px;line-height:16px;text-transform:uppercase;font-weight:700}.Countdown__CountdownItem-base___22JuZ{background-color:#fff;color:#000}.Countdown__CountdownItem-gloucester___6g_c7{background-color:#fff;color:#54585a}.Countdown__CountdownItem-inverse___2EeK7{background-color:#fff;color:#404040}", ""]);

// exports
exports.locals = {
	"Countdown": "Countdown__Countdown___SLf5e",
	"CountdownItem": "Countdown__CountdownItem___3ufqj",
	"CountdownItemValue": "Countdown__CountdownItemValue___3nNyz",
	"CountdownItemLabel": "Countdown__CountdownItemLabel___291-r",
	"CountdownItem-base": "Countdown__CountdownItem-base___22JuZ",
	"CountdownItem-gloucester": "Countdown__CountdownItem-gloucester___6g_c7",
	"CountdownItem-inverse": "Countdown__CountdownItem-inverse___2EeK7"
};