import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

import withTheme from '../../../../Theme/withTheme'
import styles from './ResponsiveTableRow.styl'

export class ResponsiveTableRow extends PureComponent {
  render() {
    const { children, highlighted, separatorBelow, theme } = this.props
    return (
      <tr
        className={classNames(
          styles.ResponsiveTableRow,
          theme && styles[`ResponsiveTableRow-${theme}`],
          highlighted && styles.highlighted,
          separatorBelow && styles.separatorBelow
        )}
      >
        {children}
      </tr>
    )
  }
}

ResponsiveTableRow.propTypes = {
  children: node.isRequired,
  highlighted: bool,
  separatorBelow: bool,
  theme: string
}

export default withTheme(ResponsiveTableRow)
