import React, { PureComponent } from 'react'
import { bool, oneOf, number, string } from 'prop-types'
import classNames from 'classnames'

import svgDimensionsFormatter from '../../../lib/svg-dimensions-formatter'
import ratioScaler from '../../../lib/ratio-scaler'
import styles from './Crest.styl'

import Svgs from './Svgs'

const cleanClubName = club => club.replace(/ /g, '')

class Crest extends PureComponent {
  render() {
    const { a11yText, club, constrain, width, height, reverse } = this.props
    const svgName = club ? cleanClubName(club) : ''
    const SvgType =
      (reverse && Svgs[`${svgName}Reverse`]) || Svgs[svgName] || Svgs['Blank']
    const sized = width || height || constrain

    const getSizes = () => {
      const customDimensions = { width, height }
      const nativeDimensions = svgDimensionsFormatter(SvgType)

      if (constrain) {
        ;+nativeDimensions.width > +nativeDimensions.height
          ? (customDimensions.width = constrain)
          : (customDimensions.height = constrain)
      }
      const ratioDimensions = ratioScaler(
        customDimensions,
        nativeDimensions,
        'ceil'
      )

      return {
        width: `${Math.ceil(ratioDimensions.width)}px`,
        height: `${Math.ceil(ratioDimensions.height)}px`,
        lineHeight: `${Math.ceil(ratioDimensions.height)}px`
      }
    }

    return (
      <span
        className={classNames(styles.Crest, sized && styles.sized)}
        {...a11yText !== '' && {
          role: 'img',
          'aria-label': a11yText
        }}
        style={sized && getSizes()}
      >
        <SvgType />
      </span>
    )
  }
}

Crest.displayName = 'Crest'

Crest.propTypes = {
  a11yText: string.isRequired,
  club: oneOf(Object.keys(Svgs)),
  constrain: number,
  height: number,
  width: number,
  reverse: bool
}

export default Crest
