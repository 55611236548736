import React, { PureComponent } from 'react'
import { bool, node, oneOf } from 'prop-types'
import classNames from 'classnames'
import styles from './Section.styl'

class Section extends PureComponent {
  render() {
    const { center, children, fullHeight, grow, spacing } = this.props
    return (
      <div
        className={classNames(
          styles.Section,
          center && styles.center,
          grow && styles.grow,
          fullHeight && styles.fullHeight,
          spacing && styles[spacing]
        )}
      >
        {children}
      </div>
    )
  }
}

Section.displayName = 'Section'

Section.propTypes = {
  center: bool,
  children: node,
  fullHeight: bool,
  grow: bool,
  spacing: oneOf(['x', 'y', 'xy', 'xt'])
}

export default Section
