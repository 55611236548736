import React, { PureComponent } from 'react'
import { array } from 'prop-types'

import Section from '../../../../../../component/Primitive/Section/index'
import ScrollArea from '../../../../../../component/Primitive/ScrollArea/index'
import SpacedList from '../../../../../../component/Primitive/SpacedList/index'
import SummaryFeatured from '../../../../../../component/Primitive/SummaryFeatured/index'
import Summary from '../../../../../../component/Primitive/Summary/index'
import Prose from '../../../../../../component/Primitive/Prose/index'
import Type from '../../../../../../component/Primitive/Type/index'

class Articles extends PureComponent {
  render() {
    let { articles } = this.props

    return (
      <Section grow>
        <ScrollArea>
          <Section>
            <Section spacing="xt">
              <Type element="h1" size="display2">
                Latest News
              </Type>
            </Section>
            {!articles && (
              <Section spacing="xy">
                <Prose>
                  <p>News is currently not available.</p>
                </Prose>
              </Section>
            )}
            {articles && articles.length > 0 && (
              <Section spacing="xy">
                <SpacedList>
                  {articles.slice(0, 1).map(article => (
                    <SummaryFeatured
                      key={article._id}
                      imageUrl={article.thumbnailImageUrl}
                      to={`/activity/home/news/article/${article.slug}`}
                      heading={article.headline}
                      category={article.category}
                    />
                  ))}
                  {articles.slice(1).map(article => (
                    <Summary
                      key={article._id}
                      imageUrl={article.heroImageUrl}
                      to={`/activity/home/news/article/${article.slug}`}
                      heading={article.headline}
                      category={article.category}
                    />
                  ))}
                </SpacedList>
              </Section>
            )}
          </Section>
        </ScrollArea>
      </Section>
    )
  }
}

Articles.propTypes = {
  articles: array
}

export default Articles
