import Immutable from 'immutable'
import reduceReducers from 'reduce-reducers'
import { MOTM_UPDATE_VOTES } from './actions'

import createCrudReducer from '../../../../../lib/crud-reducer'
import calculateCurrentStep from '../../../../../lib/current-step-calculator'

const initialState = Immutable.fromJS({
  currentStep: 'loading',
  myVote: null,
  motm: null,
  lastFetchMotm: -Infinity
})

const createVoteMapper = ({ player }) => ({
  currentStep: 'voted',
  myVote: player
})
const createVoteReducer = createCrudReducer({
  prefix: 'MOTM_VOTE',
  createMapper: createVoteMapper
})

const fetchAllVotesMapper = votes => ({ votes })
const fetchAllVotesReducer = createCrudReducer({
  prefix: 'MOTM_FETCH_ALL_VOTES',
  fetchMapper: fetchAllVotesMapper
})

const fetchMotmMapper = motm => {
  if (!motm) {
    return { currentStep: 'inactive' }
  }
  return {
    motm,
    lastFetchMotm: Date.now(),
    myVote: motm.vote ? motm.vote.player : null,
    currentStep: calculateCurrentStep(motm)
  }
}

const fetchMotmReducer = createCrudReducer({
  prefix: 'MOTM',
  fetchMapper: fetchMotmMapper
})

const upvateVotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOTM_UPDATE_VOTES:
      const { votes } = state.get('motm').toJS()
      const { player } = action.vote

      return state.set(
        'votes',
        votes.map(({ _id, voteCount }) => ({
          _id,
          voteCount: _id === player ? voteCount + 1 : voteCount
        }))
      )
    default:
      return state
  }
}

export default reduceReducers(
  upvateVotesReducer,
  fetchMotmReducer,
  fetchAllVotesReducer,
  createVoteReducer
)
