import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import onComponentDidMount from '../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../component/Primus/with-primus'
import { deauthenticate, change, update, resetErrors } from '../../actions'
import Form from '../component/Form'

const mapStateToProps = state => state.profile.toJS()
const mapDispatchToProps = dispatch => ({
  resetErrors: () => dispatch(resetErrors()),
  onLogOut: () => {
    dispatch(deauthenticate())
    dispatch(push('/profile/login'))
  },
  onSubmit: (e, primus) => {
    e.preventDefault()
    dispatch(update(primus))
    dispatch(push('/profile'))
  },
  onChange: e =>
    dispatch(
      change({
        [e.currentTarget.name]:
          e.currentTarget.type === 'checkbox'
            ? e.currentTarget.checked
            : e.currentTarget.value
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(onComponentDidMount(({ resetErrors }) => resetErrors(), Form)))
