import React, { PureComponent } from 'react'
import { number } from 'prop-types'
import createDebug from 'debug'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

const unrestrictedSSID = process.env.UNRESTRICTED_SSID
const restrictedSSID = process.env.RESTRICTED_SSID
const debug = createDebug('tile:wifi')

export class WifiTile extends PureComponent {
  constructor(...args) {
    super(...args)
    this.state = {
      connection: 'outOfStadium',
      ssid: null
    }
  }

  async componentDidMount() {
    if (!window.WifiWizard2) return
    try {
      const connectedSSID = await window.WifiWizard2.getConnectedSSID()
      let connection = 'outOfStadium'
      if (connectedSSID === unrestrictedSSID) connection = 'premium'
      if (connectedSSID === restrictedSSID) connection = 'free'
      this.setState({
        ssid: connectedSSID,
        connection
      })
    } catch (error) {
      debug('getConnectedSSID error', error)
    }
  }

  render() {
    const { height } = this.props

    const connection = this.state.connection

    const states = {
      outOfStadium: {
        heading: 'State-of-the-art Wi-Fi',
        ctaText: 'Connect during your next visit',
        to: '/wifi'
      },
      free: {
        heading: `Connect to ${unrestrictedSSID}`,
        ctaText: 'Get state-of-the-art Wi-Fi',
        to: '/wifi'
      },
      premium: {
        heading: 'You’re connected',
        ctaText: unrestrictedSSID,
        to: undefined
      }
    }

    return (
      <Tile
        heading={states[connection].heading}
        to={states[connection].to}
        imageUrl={image}
        height={height}
        ctaText={states[connection].ctaText}
      />
    )
  }
}

WifiTile.propTypes = {
  height: number
}

export default WifiTile
