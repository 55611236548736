import React, { PureComponent } from 'react'
import { number, object } from 'prop-types'
import { connect } from 'react-redux'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class ReviewTile extends PureComponent {
  render() {
    const { height, last } = this.props
    if (!last) return null
    return (
      <Tile
        heading="Post Match Review"
        to="/activity/live/review"
        imageUrl={image}
        height={height}
        ctaText={`${last.__homeTeam.displayName} ${last.__scores.home} - ${
          last.__scores.away
        } ${last.__awayTeam.displayName}`}
      />
    )
  }
}

ReviewTile.propTypes = {
  height: number,
  last: object
}

export default connect(state => state.matches.toJS())(ReviewTile)
