import React, { PureComponent } from 'react'

import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import FieldTemplate from '../../../../component/Primitive/FieldTemplate'
import Form from '../../../../component/Primitive/Form'
import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import Type from '../../../../component/Primitive/Type'
import SpacedList from '../../../../component/Primitive/SpacedList'
import TextControl from '../../../../component/Primitive/Control/TextControl'

class PasswordReset extends PureComponent {
  render() {
    const errors = []
    const data = []

    return (
      <ScrollArea>
        <Section spacing="xy">
          <SpacedList>
            <Type element="h1" size="display2">
              Password Reset
            </Type>
            <Prose>
              <p>
                Enter your email address and we’ll send you an email with
                instructions to reset your password.
              </p>
            </Prose>
            <Form
              action=""
              method="post"
              onSubmit={() => {
                console.log('submitted')
              }}
            >
              <FieldTemplate
                controlName="password"
                label="New Password"
                required
                {...!!errors.password && {
                  feedback: errors.password,
                  status: 'error'
                }}
              >
                <TextControl
                  name="password"
                  type="password"
                  required
                  autoComplete="new-password"
                  value={data.password}
                  onChange={this.handleChange}
                  {...!!errors.password && {
                    status: 'error'
                  }}
                />
              </FieldTemplate>
              <FieldTemplate
                controlName="password"
                label="Confirm Password"
                required
                {...!!errors.confirmPassword && {
                  feedback: errors.confirmPassword,
                  status: 'error'
                }}
              >
                <TextControl
                  name="confirmPassword"
                  type="password"
                  required
                  autoComplete="new-password"
                  value={data.confirmPassword}
                  onChange={this.handleChange}
                  {...!!errors.confirmPassword && {
                    status: 'error'
                  }}
                />
              </FieldTemplate>
              <ButtonDisplay type="submit" block>
                Save Password
              </ButtonDisplay>
            </Form>
          </SpacedList>
        </Section>
      </ScrollArea>
    )
  }
}

export default PasswordReset
