import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'

import styles from './Byline.styl'

import Type from '../Type'

export class Byline extends PureComponent {
  render() {
    const { author, displayDate, timestamp } = this.props

    return (
      <div className={styles.Byline}>
        <Type size="tiny">
          {displayDate && (
            <time {...timestamp && { dateTime: timestamp }}>{displayDate}</time>
          )}
          {displayDate && author && ' • '}
          {author && <span>By {author}</span>}
        </Type>
      </div>
    )
  }
}

Byline.displayName = 'Byline'

Byline.propTypes = {
  author: string,
  displayDate: string,
  timestamp: string,
  children: node,
  theme: string
}

export default Byline
