const debug = require('debug')('analytics')

const init = (window, store) => {
  debug('Init')
  window.analytics = {
    log: (...args) => {
      debug('log', ...args)
    },
    setUser: userId => {
      debug('setUser', userId)
    }
  }
  debug('Setup analytics')
  if (window.FirebasePlugin) {
    window.analytics = {
      log: (event, params) => {
        window.FirebasePlugin.logEvent(event, params)
      },
      setUser: userId => {
        window.FirebasePlugin.setUserId(userId)
      }
    }
  }
  const profile = store.getState().profile.toJS()
  if (profile && profile.user && profile.user._id) {
    window.analytics.setUser(profile.user._id)
  } else if (window.device && window.device.uuid) {
    window.analytics.setUser(window.device.uuid)
  }
}

export default init
