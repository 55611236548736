import Immutable from 'immutable'
import { NOTIFICATION_SUBSCRIBE_ALL, NOTIFICATION_IGNORE } from './actions'

const initialState = Immutable.fromJS({
  asked: false,
  ignored: false,
  topics: {
    news: null,
    matchEvents: null
  }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SUBSCRIBE_ALL:
      return state.merge({
        asked: true,
        ignored: false,
        topics: {
          news: true,
          matchEvents: true
        }
      })
    case NOTIFICATION_IGNORE:
      return state.merge({
        asked: true,
        ignored: true
      })
    default:
      return state
  }
}
