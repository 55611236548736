import { connect } from 'react-redux'

import GalleryComponent from '../component/List'
import onComponentDidMount from '../../../../../../lib/component-did-mount-helper'
import withPrimus from '../../../../../../component/Primus/with-primus'

import { fetchGallery } from '../actions'

const mapDispatchToProps = dispatch => ({
  fetchGallery: primus => dispatch(fetchGallery(primus))
})

const mapStateToProps = state => state.gallery.toJS()

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPrimus(
    onComponentDidMount(
      ({ fetchGallery, primus }) => fetchGallery(primus),
      GalleryComponent
    )
  )
)
