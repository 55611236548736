exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InlineImage__InlineImageImage___3k6Ic{background-color:#d9d9d9;padding-left:24px;padding-right:24px}.InlineImage__InlineImageCaption___xrF2k{margin-left:24px;margin-right:24px;margin-top:8px}", ""]);

// exports
exports.locals = {
	"InlineImageImage": "InlineImage__InlineImageImage___3k6Ic",
	"InlineImageCaption": "InlineImage__InlineImageCaption___xrF2k"
};