import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { bool, string } from 'prop-types'
import styles from './Rule.styl'

class Rule extends PureComponent {
  render() {
    const { className, muted, spaced } = this.props
    return (
      <div
        className={classNames(
          styles.Rule,
          muted && styles.muted,
          spaced && styles.spaced,
          className
        )}
      />
    )
  }
}

Rule.displayName = 'Rule'

Rule.propTypes = {
  className: string,
  muted: bool,
  spaced: bool
}

export default Rule
