import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './MinimalTemplate.styl'

class MinimalTemplate extends PureComponent {
  render() {
    const { children, header, primary, theme } = this.props
    return (
      <div
        className={classNames(
          styles.MinimalTemplate,
          theme && styles[`MinimalTemplate-${theme}`],
          primary && styles.primary,
          primary && theme && styles[`primary-${theme}`]
        )}
      >
        {!!header && (
          <div className={styles.MinimalTemplateHeader}>{header}</div>
        )}
        <div className={styles.MinimalTemplateBody}>{children}</div>
      </div>
    )
  }
}

MinimalTemplate.displayName = 'MinimalTemplate'

MinimalTemplate.propTypes = {
  children: node.isRequired,
  header: node,
  primary: bool,
  theme: string
}

export default withTheme(MinimalTemplate)
