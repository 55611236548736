import { connect } from 'react-redux'

import Tile from './component/QuizTile'

export default connect(state => {
  const matches = state.matches.toJS()
  const status = matches && matches.live && matches.live.status
  return {
    status
  }
})(Tile)
