import React, { PureComponent } from 'react'

import OverlayLayout from '../../../../../component/Layout/OverlayLayout'
import Section from '../../../../../component/Primitive/Section'
import ScrollArea from '../../../../../component/Primitive/ScrollArea'
import SpacedList from '../../../../../component/Primitive/SpacedList'
import Type from '../../../../../component/Primitive/Type'
import Prose from '../../../../../component/Primitive/Prose'
import Rule from '../../../../../component/Primitive/Rule'
import ButtonDisplay from '../../../../../component/Primitive/ButtonDisplay'
import ResponsiveMedia from '../../../../../component/Primitive/ResponsiveMedia'
import TextImageLockup from '../../../../../component/Primitive/TextImageLockup'

import image from './image/swipestation.jpg'

const getSwipestationStoreLink = () =>
  window.device && window.device.platform === 'iOS'
    ? 'https://itunes.apple.com/gb/app/swipestation/id1012471579'
    : 'https://play.google.com/store/apps/details?id=com.adbibio.push'

const SwipeStationButton = () => (
  <ButtonDisplay
    block
    href={getSwipestationStoreLink()}
    onClick={() => window.analytics.log('click', { type: 'swipestation' })}
  >
    Open SwipeStation
  </ButtonDisplay>
)

class Order extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <Section grow fullHeight>
          <ScrollArea>
            <Section spacing="xy">
              <SpacedList>
                <Type element="h1" size="display2">
                  Click & Collect
                </Type>
                <Prose>
                  <p>
                    Use SwipeStation to order food and drink from your phone
                    without the need for a big wait.
                  </p>
                </Prose>
                <SwipeStationButton />
              </SpacedList>
            </Section>
            <Rule muted />
            <Section spacing="y">
              <SpacedList>
                <TextImageLockup
                  text={
                    <Type element="h1" size="display2">
                      What is SwipeStation?
                    </Type>
                  }
                  image={
                    <ResponsiveMedia ratio={9 / 16}>
                      <img src={image} />
                    </ResponsiveMedia>
                  }
                />
                <Section spacing="x">
                  <Prose>
                    <p>No one likes queues and no one likes to be rushed.</p>
                    <p>
                      Download the SwipeStation app to buy your food and drink
                      through your phone. Then swipe at a SwipeStation to make
                      payment and print your voucher. Then take your voucher to
                      the nearest Fast Lane to collect your order.
                    </p>
                    <p>
                      SwipeStation is the fastest way to get served your food
                      and drinks. So get your vouchers printed and ready in
                      advance and head straight to the Fast Lane.
                    </p>
                    <p>Easy as that.</p>
                  </Prose>
                </Section>
                <Section spacing="x">
                  <SwipeStationButton />
                </Section>
              </SpacedList>
            </Section>
          </ScrollArea>
        </Section>
      </OverlayLayout>
    )
  }
}

export default Order
