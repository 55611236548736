import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import InlineVideo from '../../Primitive/InlineVideo'

export class InlineVideoWidget extends PureComponent {
  render() {
    const { widget } = this.props
    const { provider, videoId } = widget

    return <InlineVideo provider={provider} videoId={videoId} />
  }
}

InlineVideoWidget.propTypes = {
  widget: object
}

export default InlineVideoWidget
