import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Toolbar.styl'

export class Toolbar extends PureComponent {
  render() {
    const {
      buttonsAfter,
      buttonsBefore,
      children,
      reverse,
      theme,
      transparent
    } = this.props

    return (
      <div
        className={classNames(
          styles.Toolbar,
          theme && styles[`Toolbar-${theme}`],
          reverse && styles.Toolbar,
          reverse && theme && styles[`reverse-${theme}`],
          transparent && styles.transparent
        )}
      >
        {buttonsBefore && (
          <div className={styles.ToolbarBefore}>{buttonsBefore}</div>
        )}
        {children && <div className={styles.ToolbarCenter}>{children}</div>}
        {buttonsAfter && (
          <div className={styles.ToolbarAfter}>{buttonsAfter}</div>
        )}
      </div>
    )
  }
}

Toolbar.displayName = 'Toolbar'

Toolbar.propTypes = {
  buttonsAfter: node,
  buttonsBefore: node,
  children: node,
  reverse: bool,
  theme: string,
  transparent: bool
}

export default withTheme(Toolbar)
