import React, { PureComponent } from 'react'
import { bool, oneOf, node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Panel.styl'

export class Panel extends PureComponent {
  render() {
    const { children, padding, shadow, theme } = this.props

    return (
      <div
        className={classNames(
          styles.Panel,
          theme && styles[`Panel-${theme}`],
          shadow && styles.hasShadow,
          padding && styles[padding]
        )}
      >
        {children}
      </div>
    )
  }
}

Panel.displayName = 'Panel'

Panel.propTypes = {
  children: node,
  padding: oneOf(['x', 'y', 'xy']),
  shadow: bool,
  theme: string
}

export default withTheme(Panel)
