import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './TextImageLockup.styl'

export class TextImageLockup extends PureComponent {
  render() {
    const { text, image, theme } = this.props

    return (
      <div
        className={classNames(
          styles.TextImageLockup,
          theme && styles[`TextImageLockup-${theme}`]
        )}
      >
        <div className={styles.TextImageLockupText}>{text}</div>
        <div className={styles.TextImageLockupImage}>{image}</div>
      </div>
    )
  }
}

TextImageLockup.displayName = 'TextImageLockup'

TextImageLockup.propTypes = {
  text: node,
  image: node,
  theme: string
}

export default withTheme(TextImageLockup)
