import createCrudActioner from '../../../../../lib/crud-actioner'

export const MOTM_UPDATE_VOTES = 'MOTM_UPDATE_VOTES'

const { create: createVote } = createCrudActioner({
  prefix: 'MOTM_VOTE',
  action: 'motm:vote'
})
const { fetch: fetchMotm } = createCrudActioner({
  prefix: 'MOTM',
  action: 'motm'
})
const { fetch: fetchAllVotes } = createCrudActioner({
  prefix: 'MOTM_FETCH_ALL_VOTES',
  action: 'motm:votes'
})

const updateVotes = vote => (dispatch, getState) => {
  dispatch({
    type: MOTM_UPDATE_VOTES,
    vote
  })
}

export { fetchMotm, createVote, fetchAllVotes, updateVotes }
