import React, { PureComponent } from 'react'
import { bool, node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './TileCustom.styl'

import BackgroundImage from '../BackgroundImage'
import SmartLink from '../SmartLink'

class TileCustom extends PureComponent {
  render() {
    const { active, children, height, imageUrl, to } = this.props

    const dimensions = {
      flexBasis: `${parseFloat((height * 100).toFixed(4))}%`
    }
    return (
      <div
        className={classNames(styles.TileCustom, active && styles.active)}
        style={dimensions}
      >
        <div className={styles.TileCustomImage}>
          {!!imageUrl && <BackgroundImage url={imageUrl} alt="" fullsize />}
        </div>
        <div className={styles.TileCustomInner}>
          {to && <SmartLink to={to} className={styles.TileCustomLink} />}
          <div className={styles.TileCustomContent}>{children}</div>
        </div>
      </div>
    )
  }
}

TileCustom.displayName = 'TileCustom'

TileCustom.propTypes = {
  active: bool,
  children: node,
  height: number,
  imageUrl: string.isRequired,
  to: string
}

export default TileCustom
