import React, { PureComponent } from 'react'
import { node } from 'prop-types'

import styles from './Form.styl'

export class Form extends PureComponent {
  render() {
    const { children } = this.props

    return (
      <form {...this.props} className={styles.Form}>
        {children}
      </form>
    )
  }
}

Form.displayName = 'Form'

Form.propTypes = {
  children: node
}

export default Form
