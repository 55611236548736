import React, { PureComponent } from 'react'
import { oneOf } from 'prop-types'

import withTheme from '../../Theme/withTheme'
import CardProfile from './CardProfile'
import MiniProfile from './MiniProfile'
import StandardProfile from './StandardProfile'

class Profile extends PureComponent {
  render() {
    const { template } = this.props
    const componentMap = {
      card: () => <CardProfile {...this.props} />,
      mini: () => <MiniProfile {...this.props} />,
      standard: () => <StandardProfile {...this.props} />
    }

    return componentMap[template]()
  }
}

Profile.displayName = 'Profile'

Profile.defaultProps = {
  template: 'standard'
}

Profile.propTypes = {
  template: oneOf(['card', 'mini', 'standard'])
}

export default withTheme(Profile)
