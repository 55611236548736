import React, { PureComponent } from 'react'

import Prose from '../../../../component/Primitive/Prose'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import Section from '../../../../component/Primitive/Section'
import SpacedList from '../../../../component/Primitive/SpacedList'
import Type from '../../../../component/Primitive/Type'

class PasswordSent extends PureComponent {
  render() {
    return (
      <ScrollArea>
        <Section spacing="xy">
          <SpacedList>
            <Type element="h1" size="display2">
              Password Reset
            </Type>
            <Prose>
              <p>Instructions have been sent to you.</p>
            </Prose>
          </SpacedList>
        </Section>
      </ScrollArea>
    )
  }
}

export default PasswordSent
