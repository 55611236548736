import React, { PureComponent } from 'react'

import Position from '../../../../component/Primitive/Position'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import TileGrid from '../../../../component/Primitive/TileGrid'

import NewsTile from '../../../../component/Common/Tile/News'

class Home extends PureComponent {
  render() {
    return (
      <Position position="absolute" style={{ width: '100%', height: '100%' }}>
        <Section grow fullHeight>
          <ScrollArea>
            <TileGrid>
              <NewsTile height={1} />
            </TileGrid>
          </ScrollArea>
        </Section>
      </Position>
    )
  }
}

export default Home
