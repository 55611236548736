import { push } from 'react-router-redux'
export const USERGATE_SUCCESS = 'USERGATE_SUCCESS'
export const USERGATE_CHANGE = 'USERGATE_CHANGE'
export const USERGATE_ERROR = 'USERGATE_ERROR'

export const submit = () => (dispatch, getState) => {
  const data = getState().userGate.toJS().data
  if (!data.termsAgreed) {
    return dispatch({
      type: USERGATE_ERROR,
      error: 'There was a problem',
      errorProperties: { termsAgreed: 'You must agree to terms to continue.' }
    })
  }
  dispatch({
    type: USERGATE_SUCCESS
  })
  dispatch(push('/gate/complete'))
}

export const change = data => ({
  type: USERGATE_CHANGE,
  data
})
