import createCrudActioner from '../../../lib/crud-actioner'

const { fetch: fetchShows } = createCrudActioner({
  prefix: 'SHOWS',
  action: 'shows'
})

const { fetch: fetchEpisodesForShow } = createCrudActioner({
  prefix: 'EPISODES',
  action: 'episodes'
})

export { fetchShows, fetchEpisodesForShow }
