import React from 'react'
import {
  array,
  func,
  node,
  number,
  object,
  oneOf,
  shape,
  string
} from 'prop-types'
import { connect } from 'react-redux'
import { fetchMotm, updateVotes, fetchAllVotes, createVote } from '../actions'
import Motm from '../'
import withPrimus from '../../../../../../component/Primus/with-primus'
import getDeviceUuid from '../../../../../../lib/device-id'

const debug = require('debug')('motm')
const ttl = 5 * 1000
const device = getDeviceUuid()

class MotmContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleConfirmation = this.handleConfirmation.bind(this)
    this.getVotes = this.getVotes.bind(this)
    this.getVoteLeaders = this.getVoteLeaders.bind(this)
    this.primus = this.props.primus
  }

  componentDidMount() {
    const forceRefresh = this.props.lastFetchMotm + ttl < Date.now()
    if (forceRefresh || !this.props.motm) {
      debug('Fetching latest MOTM')
      this.props.fetchMotm(this.primus)
    }
  }

  handleConfirmation(player) {
    debug('Saving MOTM vote:', player)
    const { _id } = this.props.motm
    this.props.createVote(this.primus, { motm: _id, player, device })
  }

  getVotes(limit) {
    const votes = this.props.motm && this.props.motm.votes
    const totalVotes = votes.reduce(
      (accumulator, current) => accumulator + current.voteCount,
      0
    )
    const sortedVotes = votes.sort((a, b) => b.voteCount - a.voteCount)
    const limitedVotes = limit ? sortedVotes.slice(0, limit) : sortedVotes
    limitedVotes.map(item => {
      const votePrecent = (100 / totalVotes) * item.voteCount
      // Doesn't need to be too precise
      item.votePercent = +votePrecent.toFixed(1) || '0'
    })
    return limitedVotes
  }

  getVoteLeaders(limit) {
    const squad = (this.props.motm && this.props.motm.players) || []
    const limitedVotes = this.getVotes(10)
    const voteLeaders = limitedVotes.map(leader => {
      const player = squad.find(player => player._id === leader._id)
      return { ...player, ...leader }
    })
    return voteLeaders
  }

  render() {
    const {
      error,
      currentStep,
      motm,
      myVote,
      updateVotes,
      fetchAllVotes,
      primus
    } = this.props
    const { players, voteOpenDate, voteCloseDate, __match } = motm || {}

    return (
      <Motm
        error={error}
        currentStep={currentStep}
        squad={players}
        startTime={new Date(voteOpenDate)}
        voteCloseDate={new Date(voteCloseDate)}
        handleConfirmation={
          currentStep === 'active' ? this.handleConfirmation : null
        }
        updateVotes={currentStep === 'voted' ? updateVotes : null}
        fetchAllVotes={currentStep === 'voted' ? fetchAllVotes : null}
        myVote={myVote}
        homeTeam={__match && __match.__homeTeam}
        awayTeam={__match && __match.__awayTeam}
        voteLeaders={
          ['voted', 'complete'].includes(currentStep)
            ? this.getVoteLeaders(10)
            : []
        }
        primus={primus}
      />
    )
  }
}

MotmContainer.propTypes = {
  error: node,
  currentStep: oneOf([
    'error',
    'loading',
    'preview',
    'active',
    'inactive',
    'voted',
    'complete'
  ]).isRequired,
  motm: shape({
    _id: string,
    players: array,
    votes: array,
    startDate: string,
    voteCloseDate: string
  }),
  fetchMotm: func.isRequired,
  updateVotes: func.isRequired,
  fetchAllVotes: func.isRequired,
  lastFetchMotm: number,
  createVote: func.isRequired,
  myVote: string,
  primus: object.isRequired
}

const mapDispatchToProps = dispatch => ({
  fetchMotm: primus => dispatch(fetchMotm(primus, { device })),
  updateVotes: votes => dispatch(updateVotes(votes, { device })),
  fetchAllVotes: primus => dispatch(fetchAllVotes(primus, { device })),
  createVote: (primus, data) => dispatch(createVote(primus, data))
})

const mapStateToProps = state => state.motm.toJS()

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(MotmContainer))
