exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SelectableText__SelectableText___2sSR0{-webkit-user-select:text!important;-moz-user-select:text!important;-ms-user-select:text!important;user-select:text!important}", ""]);

// exports
exports.locals = {
	"SelectableText": "SelectableText__SelectableText___2sSR0"
};