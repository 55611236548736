import Immutable from 'immutable'
import reduceReducers from 'reduce-reducers'
import sortBy from 'orderby'
import uniqBy from 'lodash.uniqby'

import {
  COMMENTARY_COMMENTARY_ADD,
  COMMENTARY_COMMENTARY_UPDATE,
  COMMENTARY_COMMENTARY_DELETE,
  MATCH_UPDATE,
  SCORES_UPDATE
} from './actions'

import createCrudReducer from '../../../../../lib/crud-reducer'

const initialState = Immutable.fromJS({
  error: null,
  live: null,
  commentary: [],
  scores: {
    home: 0,
    away: 0
  }
})

const fetchMatchesMapper = ({ live }) => {
  if (!live) return {}
  const { __commentary: commentary, __scores: scores } = live
  return {
    live,
    commentary,
    scores
  }
}
const fetchMatchesReducer = createCrudReducer({
  prefix: 'MATCHES',
  fetchMapper: fetchMatchesMapper
})

const uniqueAndSortCommentary = commentary =>
  sortBy(uniqBy(commentary, '_id'), ['minute']).reverse()

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTARY_COMMENTARY_ADD:
      return state.merge({
        commentary: uniqueAndSortCommentary([
          ...state.get('commentary').toJS(),
          action.commentary
        ])
      })
    case COMMENTARY_COMMENTARY_UPDATE:
      return state.merge({
        commentary: uniqueAndSortCommentary([
          ...state.get('commentary').toJS(),
          ...action.commentary
        ])
      })
    case COMMENTARY_COMMENTARY_DELETE:
      return state.merge({
        commentary: state
          .get('commentary')
          .toJS()
          .filter(({ _id }) => _id !== action.commentary)
      })
    case MATCH_UPDATE:
      return state.mergeDeep({ live: action.match })
    case SCORES_UPDATE:
      const { home, away } = action.scores
      const { scores: existingScores } = state.toJS()
      return state.merge({
        scores: {
          home: home || existingScores.home,
          away: away || existingScores.away
        }
      })
    default:
      return state
  }
}

export default reduceReducers(feedReducer, fetchMatchesReducer)
