import React, { PureComponent } from 'react'

import Position from '../../../../component/Primitive/Position'
import Section from '../../../../component/Primitive/Section'
import ScrollArea from '../../../../component/Primitive/ScrollArea'
import TileGrid from '../../../../component/Primitive/TileGrid'

import FeedTile from '../../../../component/Common/Tile/Feed'
import LineupTile from '../../../../component/Common/Tile/Lineup'
import MatchPreviewTile from '../../../../component/Common/Tile/MatchPreview'
import ReviewTile from '../../../../component/Common/Tile/Review'
import TableTile from '../../../../component/Common/Tile/Table'

class Live extends PureComponent {
  render() {
    return (
      <Position position="absolute" style={{ width: '100%', height: '100%' }}>
        <Section grow fullHeight>
          <ScrollArea>
            <TileGrid>
              <LineupTile height={1 / 4} />
              <FeedTile height={1 / 2} />
              <MatchPreviewTile height={2 / 3} />
              <ReviewTile height={1 / 4} />
              <TableTile height={1 / 4} />
            </TileGrid>
          </ScrollArea>
        </Section>
      </Position>
    )
  }
}

export default Live
