import React, { Fragment } from 'react'
import { func, string, object, bool } from 'prop-types'

import Notification from '../../../../component/Primitive/Notification'
import ButtonDisplay from '../../../../component/Primitive/ButtonDisplay'
import FieldTemplate from '../../../../component/Primitive/FieldTemplate'
import Form from '../../../../component/Primitive/Form'
import TextAlign from '../../../../component/Primitive/TextAlign'
import TextControl from '../../../../component/Primitive/Control/TextControl'
import Type from '../../../../component/Primitive/Type'

const Login = ({
  onChange,
  onSubmit,
  error,
  errorProperties,
  data,
  authenticated,
  primus
}) => (
  <Fragment>
    {error && (
      <Notification icon="Error" status="error">
        {error}
      </Notification>
    )}
    <Form onSubmit={e => onSubmit(e, primus)}>
      <FieldTemplate
        controlName="emailAddress"
        label="Email Address"
        status={errorProperties.emailAddress && 'error'}
        feedback={errorProperties.emailAddress}
        required
      >
        <TextControl
          name="emailAddress"
          type="email"
          autoComplete="email"
          status={errorProperties.emailAddress && 'error'}
          onChange={onChange}
          value={data.emailAddress}
          required
        />
      </FieldTemplate>

      <FieldTemplate
        controlName="password"
        label="Password"
        feedback={errorProperties.password}
        status={errorProperties.password && 'error'}
        required
      >
        <TextControl
          name="password"
          type="password"
          status={errorProperties.password && 'error'}
          onChange={onChange}
          autoComplete="current-password"
          required
        />
      </FieldTemplate>

      <ButtonDisplay type="submit" block>
        Log in
      </ButtonDisplay>
    </Form>
    <TextAlign center style={{ outline: '10px solid lime' }}>
      <Type size="tiny">
        <a onClick={() => alert('Coming soon...')}>Forgotten Password?</a>
      </Type>
    </TextAlign>
  </Fragment>
)

Login.propTypes = {
  onChange: func,
  onSubmit: func,
  error: string,
  errorProperties: object,
  data: object,
  authenticated: bool,
  primus: object
}

export default Login
