exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MainTemplate__MainTemplate___1jIL1{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;overflow:hidden;height:100%;width:100%;min-width:300px}.MainTemplate__MainTemplate___1jIL1 ::-moz-selection{text-shadow:none}.MainTemplate__MainTemplate___1jIL1 ::selection{text-shadow:none}.MainTemplate__MainTemplateHeader___qiFRK{position:relative;z-index:1}.MainTemplate__MainTemplateBody___1qdGD{overflow:hidden;position:relative;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%}.MainTemplate__MainTemplateFooter___1oHJz{position:relative;z-index:300}.MainTemplate__MainTemplate-base___1wTOy{color:#000;background-color:#fff}.MainTemplate__MainTemplate-gloucester___1q9Au{color:#54585a;background-color:#fff}.MainTemplate__MainTemplate-gloucester___1q9Au ::-moz-selection{color:#fff;background:#da1a32}.MainTemplate__MainTemplate-gloucester___1q9Au ::selection{color:#fff;background:#da1a32}.MainTemplate__MainTemplate-inverse___1jl3M{color:#fff;background-color:#000}.MainTemplate__MainTemplate-inverse___1jl3M ::-moz-selection{color:#ff9800;background:#fff}.MainTemplate__MainTemplate-inverse___1jl3M ::selection{color:#ff9800;background:#fff}", ""]);

// exports
exports.locals = {
	"MainTemplate": "MainTemplate__MainTemplate___1jIL1",
	"MainTemplateHeader": "MainTemplate__MainTemplateHeader___qiFRK",
	"MainTemplateBody": "MainTemplate__MainTemplateBody___1qdGD",
	"MainTemplateFooter": "MainTemplate__MainTemplateFooter___1oHJz",
	"MainTemplate-base": "MainTemplate__MainTemplate-base___1wTOy",
	"MainTemplate-gloucester": "MainTemplate__MainTemplate-gloucester___1q9Au",
	"MainTemplate-inverse": "MainTemplate__MainTemplate-inverse___1jl3M"
};