import Immutable from 'immutable'
import { KEYBOARD_SHOW, KEYBOARD_HIDE } from './actions'

const initialState = Immutable.fromJS({
  hidden: true
})

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYBOARD_SHOW:
      return state.merge({
        hidden: false
      })
    case KEYBOARD_HIDE:
      return state.merge({
        hidden: true
      })
    default:
      return state
  }
}
