import React, { PureComponent } from 'react'
import { array, func, object, number, bool } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import { fetchArticles } from '../actions'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Articles from '../Component/Articles'

const debug = createDebug('news')
const ttl = 2 * 60 * 1000

class ArticlesContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
  }

  componentDidMount() {
    this.props.fetchArticles(this.primus)
    const forceRefresh = this.props.lastFetchArticles + ttl < Date.now()
    if (forceRefresh || !this.props.motm) {
      debug('Refreshing news')
      this.props.fetchArticles(this.primus)
    }
  }

  render() {
    return <Articles articles={this.props.articles} />
  }
}

ArticlesContainer.propTypes = {
  primus: object.isRequired,
  fetchArticles: func.isRequired,
  lastFetchArticles: number.isRequired,
  motm: bool,
  articles: array
}

const mapStateToProps = state => state.articles.toJS()
const mapDispatchToProps = dispatch => ({
  fetchArticles: primus => dispatch(fetchArticles(primus))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(ArticlesContainer))
