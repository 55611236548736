import React, { PureComponent } from 'react'
import { bool, number, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Countdown.styl'

export class CountdownItem extends PureComponent {
  render() {
    const {
      hide,
      hideEmpty,
      label,
      padValue,
      pluralise,
      theme,
      value
    } = this.props

    if (hide || (hideEmpty && value === 0)) return null

    const formattedLabel = `${label}${
      pluralise ? (value === 1 ? '' : 's') : ''
    }`
    const formattedValue = padValue ? `${value}`.padStart(padValue, '0') : value
    return (
      <div
        className={classNames(
          styles.CountdownItem,
          theme && styles[`CountdownItem-${theme}`]
        )}
      >
        <div
          className={classNames(
            styles.CountdownItemValue,
            theme && styles[`CountdownItemValue-${theme}`]
          )}
        >
          {formattedValue}
        </div>
        <div
          className={classNames(
            styles.CountdownItemLabel,
            theme && styles[`CountdownItemLabel-${theme}`]
          )}
        >
          {formattedLabel}
        </div>
      </div>
    )
  }
}

CountdownItem.displayName = 'CountdownItem'

CountdownItem.propTypes = {
  hide: bool,
  hideEmpty: bool,
  label: string.isRequired,
  padValue: number,
  pluralise: bool,
  theme: string,
  value: number.isRequired
}

export default withTheme(CountdownItem)
