import React, { PureComponent } from 'react'
import { array, object } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Article from '../Component/Article'

const debug = createDebug('article')

class ArticleContainer extends PureComponent {
  render() {
    const { articles } = this.props
    const { slug } = this.props.match.params
    const article = articles.find(article => article.slug === slug)
    debug('rendering', slug, article)
    if (!article) {
      return <h1>Not found</h1>
    }
    return <Article articles={articles} article={article} />
  }
}

ArticleContainer.propTypes = {
  match: object.isRequired,
  articles: array.isRequired
}

const mapStateToProps = state => state.articles.toJS()

export default connect(mapStateToProps)(withPrimus(ArticleContainer))
