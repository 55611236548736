import React, { PureComponent } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../Theme/withTheme'
import styles from './Blockquote.styl'

export class Blockquote extends PureComponent {
  render() {
    const { citation, quote, quotemarks, theme } = this.props

    return (
      <blockquote
        className={classNames(
          styles.Blockquote,
          theme && styles[`Blockquote-${theme}`],
          quotemarks && styles.quotemarks
        )}
      >
        <span className={styles.BlockquoteQuote}>{quote}</span>
        {citation && (
          <cite className={styles.BlockquoteCitation}>{citation}</cite>
        )}
      </blockquote>
    )
  }
}

Blockquote.displayName = 'Blockquote'

Blockquote.propTypes = {
  citation: string.isRequired,
  quote: string,
  quotemarks: bool,
  theme: string
}

export default withTheme(Blockquote)
