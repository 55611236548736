import React, { PureComponent } from 'react'

import OverlayLayout from '../../../component/Layout/OverlayLayout'
import Section from '../../../component/Primitive/Section'
import ScrollArea from '../../../component/Primitive/ScrollArea'
import SpacedList from '../../../component/Primitive/SpacedList'
import Directions from '../../../component/Primitive/Directions'
import Map from '../../../component/Primitive/Map'
import Type from '../../../component/Primitive/Type'
import Prose from '../../../component/Primitive/Prose'

class Info extends PureComponent {
  render() {
    return (
      <OverlayLayout>
        <Section grow>
          <ScrollArea>
            <Section spacing="xy">
              <Type element="h1" size="display2">
                Kingsholm
              </Type>
            </Section>
            <Directions
              address="Kingsholm Stadium, Kingsholm Rd, Gloucester GL1 3AX"
              map={<Map lat={51.8708097} lng={-2.2421161} showMarker />}
            />
            <Section spacing="xy">
              <SpacedList>
                <Prose>
                  <p>
                    As Kingsholm Stadium is situated in the middle of a
                    residential area, we have an obligation to be good
                    neighbours.
                  </p>
                  <p>
                    Please take a few minutes to read the information posted
                    below to make your visit to Kingsholm as pleasant and easy
                    as possible and to ensure that local residents are
                    inconvenienced as little as possible on match days.
                  </p>
                  <p>
                    This travel page has been developed to ensure that all
                    visitors can get to and from the stadium and the park and
                    ride areas as quickly and easily as possible. For match
                    days, the times have been based on a standard 15.00 kick off
                    but please follow the links for further information to visit
                    the stadium at other times.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  By Car
                </Type>
                <Prose>
                  <h3>To Stadium:</h3>
                  <p>
                    Take junction 11 off the M5 and proceed on the A40 towards
                    the city centre. At the Elmbridge Roundabout take the third
                    exit and stay on the A40. At the next roundabout please take
                    the first left onto the Tewkesbury Road. Go over the next
                    roundabout and the Stadium is on your right hand side
                    approximately 500m. The main reception is at the rear of the
                    main stand off the car park.
                  </p>
                  <h3>Match day Park and Ride:</h3>
                  <p>
                    Supporters wishing to attend the game and take advantage of
                    the Park and Ride service can use the EDF Energy Barnwood
                    Service which runs from 2 hours before kick off until 2
                    hours after the full time whistle.
                  </p>
                  <p>
                    <a href="https://d2cx26qpfwuhvu.cloudfront.net/gloucester/wp-content/uploads/2018/05/15151311/how_to_get_to_barnwood.pdf">
                      EDF Energy at Barnwood
                    </a>
                    : The EDF Energy overflow car park offices are located on
                    the eastern side of Gloucester, about two-and-a-half miles
                    from the city centre, just off the A417 bypass.
                  </p>
                  <p>
                    Important Notice. Please Note that the Tesco’s Park and Ride
                    is no longer available on Gloucester Rugby Matchdays.
                    Apologies for any inconvenience.
                  </p>
                  <h3>From the North:</h3>
                  <p>
                    Leave the M5 at Junction 11, and follow the A40 in the
                    direction of Gloucester. At the first roundabout you
                    approach, take the second turning on the left (A417
                    Southbound). At the second roundabout, go straight across,
                    and enter Barnett Way, with the Lloyds Banking Group
                    building on your left. EDF Energy is now directly in front
                    of you, continue to follow the road round and past the main
                    entrance to EDF Energy towards the overflow car park.
                  </p>
                  <h3>From the South</h3>
                  <p>
                    Take Junction 11a off the M5, and follow the A417 in the
                    direction of Gloucester. At the first roundabout, take the
                    second turning on the left, and follow the A417 bypass. At
                    the second roundabout, take the first left into Barnett Way.
                    The Lloyds Banking Group building is on your left. EDF
                    Energy is now directly in front of you, continue to follow
                    the road round and past the main entrance to EDF Energy
                    towards the overflow car park.
                  </p>
                  <p>
                    The Kingsholm Stadium Park and Ride, coaches supplied by{' '}
                    <a href="http://swanbrook.co.uk/">SWANBROOKS</a> and car
                    park kindly supplied by EDF Energy, runs every 15 minutes
                    from Barnwood to Kingsholm Road 2 hours before kick off.
                  </p>
                  <p>
                    Parking is free and the bus return journey runs every 15
                    minutes for 2 hours after the final whistle from Skinner
                    Street. Cost – £1.50 per adult and £1 per child and OAPs.
                    <strong>
                      (Disabled supporters should not use the EDF Energy Park &
                      Ride but use the Tesco Park & Ride instead)
                    </strong>
                  </p>
                  <p>
                    The postcode for the EDF Energy Park and Ride site for GPS /
                    Sat Nav is: GL4 3RS.
                  </p>
                  <h3>Disabled supporters:</h3>
                  <p>
                    A drop off/ pick up point is located at the front of the
                    main car park for disabled supporters only.
                  </p>
                  <h3>Car Share:</h3>
                  <p>
                    Gloucester Rugby fully supports{' '}
                    <a href="https://liftshare.com/uk/community/gloucestershire">
                      Car Sharing
                    </a>
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  By Bus
                </Type>
                <Prose>
                  <p>
                    Many bus routes are available into the main bus station in
                    Gloucester, which is only a 5 minute walk to the stadium.
                  </p>
                  <p>
                    Key routes and service numbers are laid out below or{' '}
                    <a href="https://www.gloucestershire.gov.uk/transport/public-transport-information/">
                      click here
                    </a>{' '}
                    for more information.
                  </p>
                  <ul>
                    <li>
                      30/31 – Christ Church – Coleford – Brierley – Cindeford –
                      Gloucester
                    </li>
                    <li>91 – Dursley – Gloucester</li>
                    <li>
                      14 – Stroud – The Stanleys – Stonehouse – Kingsway –
                      Gloucester
                    </li>
                    <li>94 – Cheltenham – Churchdown – Gloucester</li>
                    <li>73 – Chepstow – Lydney – Gloucester</li>
                    <li>32 – Ross on Wye – Newent – Gloucester</li>
                    <li>71 – Tewkesbury – Gloucester</li>
                    <li>853 – Oxford – Cheltenham – Gloucester</li>
                    <li>852 – Cirencester – Gloucester</li>
                  </ul>
                </Prose>

                <Type element="h2" size="subtitle">
                  By Coach
                </Type>
                <Prose>
                  <p>
                    If you are organising a coach trip to Kingsholm,{' '}
                    <a href="http://www.gloucester.gov.uk/resident/parking-travel-and-roads/Pages/default.aspx">
                      click here
                    </a>{' '}
                    for information regarding coach parking in the City.
                  </p>
                </Prose>

                <Type element="h2" size="subtitle">
                  By Rail
                </Type>
                <Prose>
                  <p>
                    There is an excellent service from Gloucester with regular
                    trains to the all parts of the country. The station is less
                    than a 10 minute walk from Gloucester Rugby. Come out of the
                    station, turn right and follow the road towards Kingsholm
                    from where signposts will lead you to the ground.
                  </p>
                  <p>
                    To see up to date timetables and ticket information, please
                    visit{' '}
                    <a href="https://www.networkrail.co.uk/">Network Rail</a>.
                  </p>
                </Prose>
              </SpacedList>
            </Section>
          </ScrollArea>
        </Section>
      </OverlayLayout>
    )
  }
}

export default Info
