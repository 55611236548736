import createImageUrlBuilder from 'cf-image-url-builder'

export default (images, context, crop, width, height) => {
  const urlBuilder = createImageUrlBuilder(
    process.env.DARKROOM_URL,
    process.env.DARKROOM_SALT,
    images
  )

  if (urlBuilder.getImage(context) && urlBuilder.getImage(context).crop(crop)) {
    return urlBuilder
      .getImage(context)
      .crop(crop)
      .constrain(width, height)
      .url()
  }
  return null
}
