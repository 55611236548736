import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'

import styles from './ResultStat.styl'

export class ResultStat extends PureComponent {
  render() {
    const { children, prefix, suffix } = this.props

    return (
      <div className={styles.ResultStat}>
        {!!prefix && <div className={styles.ResultStatAffix}>{prefix} </div>}
        <div className={styles.ResultStatValue}>{children}</div>
        {!!suffix && <div className={styles.ResultStatAffix}> {suffix}</div>}
      </div>
    )
  }
}

ResultStat.displayName = 'ResultStat'

ResultStat.propTypes = {
  children: node.isRequired,
  prefix: string,
  suffix: string
}

export default ResultStat
