import React, { PureComponent } from 'react'
import { func, object, number, bool } from 'prop-types'
import createDebug from 'debug'
import { connect } from 'react-redux'

import { fetchEvents } from '../actions'

import withPrimus from '../../../../../../component/Primus/with-primus'
import Events from '../Component/Events'

const debug = createDebug('event')
const ttl = 2 * 60 * 1000

class EventsContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.primus = this.props.primus
  }

  componentDidMount() {
    const forceRefresh = this.props.lastFetchEvents + ttl < Date.now()
    if (forceRefresh || !this.props.motm) {
      debug('Refreshing events')
      this.props.fetchEvents(this.primus)
    }
  }

  render() {
    return <Events events={this.props.events} />
  }
}

EventsContainer.propTypes = {
  primus: object.isRequired,
  fetchEvents: func.isRequired,
  lastFetchEvents: number.isRequired,
  motm: bool,
  events: object
}

const mapStateToProps = state => state.events.toJS()
const mapDispatchToProps = dispatch => ({
  fetchEvents: primus => dispatch(fetchEvents(primus))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPrimus(EventsContainer))
