import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class CherryAndWhiteTile extends PureComponent {
  render() {
    const { height } = this.props
    return (
      <Tile
        heading="Cherry and White Wednesday"
        to="https://www.bbc.co.uk/programmes/p048tczg/clips"
        ctaText="BBC Radio Gloucestershire"
        imageUrl={image}
        height={height}
      />
    )
  }
}

CherryAndWhiteTile.propTypes = {
  height: number
}

export default CherryAndWhiteTile
