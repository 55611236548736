import { combineReducers } from 'redux'
import keyboard from './service/keyboard/reducer'
import matches from './view/App/reducer'
import motm from './view/Activity/component/Play/Motm/reducer'
import quiz from './view/Activity/component/Play/Quiz/reducer'
import gallery from './view/Activity/component/Play/Gallery/reducer'
import wifi from './view/Wifi/reducer'
import notification from './view/Notification/reducer'
import userGate from './view/UserGate/reducer'
import profile from './view/Profile/reducer'
import feed from './view/Activity/component/Live/Feed/reducer'
import events from './view/Activity/component/Access/Events/reducer'
import lineups from './view/Activity/component/Live/Lineups/reducer'
import articles from './view/Activity/component/Home/News/reducer'
import matchReviews from './view/Activity/component/Live/Review/reducer'
import shows from './view/Audio/Shows/reducer'
import standings from './view/Activity/component/Live/Table/reducer'

const reducer = combineReducers({
  matches,
  motm,
  gallery,
  wifi,
  quiz,
  feed,
  events,
  articles,
  lineups,
  userGate,
  profile,
  notification,
  matchReviews,
  keyboard,
  shows,
  standings
})

export default (state, action) => {
  if (action.type === 'RESET_ALL') {
    state = undefined
  }
  return reducer(state, action)
}
