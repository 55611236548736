exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Avatar__Avatar___3Ddx5{display:inline-block;border-radius:50%;overflow:hidden;vertical-align:middle}.Avatar__AvatarInitials___3Q7qp{width:100%;height:100%;text-align:center;vertical-align:middle;font-size:inherit}.Avatar__image___2wuL_{background-color:rgba(0,0,0,0)!important}.Avatar__image___2wuL_ img{width:inherit}.Avatar__Avatar-base___Vie1p{background-color:#00cfdd;color:#fff}.Avatar__Avatar-gloucester___1v2YR{background-color:#da1a32;color:#fff}.Avatar__Avatar-inverse___1yeki{background-color:#fff;color:#000}", ""]);

// exports
exports.locals = {
	"Avatar": "Avatar__Avatar___3Ddx5",
	"AvatarInitials": "Avatar__AvatarInitials___3Q7qp",
	"image": "Avatar__image___2wuL_",
	"Avatar-base": "Avatar__Avatar-base___Vie1p",
	"Avatar-gloucester": "Avatar__Avatar-gloucester___1v2YR",
	"Avatar-inverse": "Avatar__Avatar-inverse___1yeki"
};