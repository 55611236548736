import React, { PureComponent } from 'react'
import { bool, func, number, oneOf, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import styles from './NativeRange.styl'

export class NativeRangeControl extends PureComponent {
  render() {
    const {
      autoComplete,
      autoFocus,
      className,
      controlRef,
      defaultValue,
      disabled,
      id,
      name,
      onBlur,
      onChange,
      onFocus,
      readOnly,
      required,
      status,
      tabIndex,
      min,
      max,
      step,
      value
    } = this.props

    const componentClassName = classNames(
      styles.NativeRangeControl,
      status && styles[status],
      className
    )

    return (
      <input
        aria-invalid={status === 'error' || undefined}
        aria-required={required}
        className={componentClassName}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        max={max}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        ref={controlRef}
        required={required}
        step={step}
        tabIndex={tabIndex}
        type="range"
        value={value}
      />
    )
  }
}

NativeRangeControl.displayName = 'NativeRangeControl'

NativeRangeControl.propTypes = {
  autoComplete: oneOfType([bool, string]),
  autoFocus: bool,
  className: string,
  controlRef: func,
  defaultValue: string,
  disabled: bool,
  id: string,
  max: string,
  min: string,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  readOnly: bool,
  required: bool,
  status: oneOf(['error', 'notice', 'success', 'warning']),
  step: string,
  tabIndex: number,
  value: string
}

export default NativeRangeControl
