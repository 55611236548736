import createCrudActioner from '../../../../../lib/crud-actioner'

export const COMMENTARY_COMMENTARY_ADD = 'COMMENTARY_COMMENTARY_ADD'
export const COMMENTARY_COMMENTARY_UPDATE = 'COMMENTARY_COMMENTARY_UPDATE'
export const COMMENTARY_COMMENTARY_DELETE = 'COMMENTARY_COMMENTARY_DELETE'
export const MATCH_UPDATE = 'MATCH_UPDATE'
export const SCORES_UPDATE = 'SCORES_UPDATE'

const { fetch: fetchMatches } = createCrudActioner({
  prefix: 'MATCHES',
  action: 'matches'
})

export { fetchMatches }

export const newCommentary = commentary => ({
  type: COMMENTARY_COMMENTARY_ADD,
  commentary
})

export const updateCommentary = commentary => ({
  type: COMMENTARY_COMMENTARY_UPDATE,
  commentary
})

export const deleteCommentary = commentary => ({
  type: COMMENTARY_COMMENTARY_DELETE,
  commentary
})

export const onMatchUpdate = match => ({
  type: MATCH_UPDATE,
  match
})

export const onScoresUpdate = scores => ({
  type: SCORES_UPDATE,
  scores
})
