exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Panel__Panel___3hxIq{padding:1px}.Panel__hasShadow___2A1OD{position:relative}.Panel__hasShadow___2A1OD:after{content:\"\";position:absolute;left:0;right:0;height:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none}.Panel__hasShadow___2A1OD:after{top:100%;background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.2)),to(rgba(0,0,0,0)));background-image:-webkit-linear-gradient(top,rgba(0,0,0,.2),rgba(0,0,0,0));background-image:linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,0))}.Panel__x___GOCgt{padding-left:15px;padding-right:15px}.Panel__y___3mIU4{padding-top:23px;padding-bottom:23px}.Panel__xy___3VUb7{padding:15px 23px}.Panel__Panel-base___1xD2J,.Panel__Panel-gloucester___1HeCn{background:#fff}.Panel__Panel-inverse___1SNun{background:#000}", ""]);

// exports
exports.locals = {
	"Panel": "Panel__Panel___3hxIq",
	"hasShadow": "Panel__hasShadow___2A1OD",
	"x": "Panel__x___GOCgt",
	"y": "Panel__y___3mIU4",
	"xy": "Panel__xy___3VUb7",
	"Panel-base": "Panel__Panel-base___1xD2J",
	"Panel-gloucester": "Panel__Panel-gloucester___1HeCn",
	"Panel-inverse": "Panel__Panel-inverse___1SNun"
};