import React, { PureComponent } from 'react'
import { number } from 'prop-types'

import Tile from '../../../../Primitive/Tile'
import image from '../image/background.jpg'

export class QuizTile extends PureComponent {
  render() {
    const { height } = this.props
    return (
      <Tile
        heading="Game of Holms Quiz"
        ctaText="Opportunity to win great prizes"
        to="/activity/play/quiz"
        imageUrl={image}
        height={height}
      />
    )
  }
}

QuizTile.propTypes = {
  height: number
}

export default QuizTile
