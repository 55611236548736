import React, { PureComponent } from 'react'
import { number, string } from 'prop-types'

import Tile from '../../../Primitive/Tile'
import image from './image/background.jpg'

export class FacilitiesTile extends PureComponent {
  render() {
    const { height, heading = 'Kingsholm Stadium' } = this.props
    return (
      <Tile
        heading={heading}
        to="/venue/info"
        imageUrl={image}
        height={height}
      />
    )
  }
}

FacilitiesTile.propTypes = {
  height: number,
  heading: string
}

export default FacilitiesTile
