import React, { PureComponent } from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'
import styles from './TextAlign.styl'

class TextAlign extends PureComponent {
  render() {
    const { children, left, right, center } = this.props
    return (
      <div
        className={classNames(
          styles.TextAlign,
          left && styles.left,
          center && styles.center,
          right && styles.right
        )}
      >
        {children}
      </div>
    )
  }
}

TextAlign.displayName = 'TextAlign'

TextAlign.propTypes = {
  children: node.isRequired,
  left: bool,
  center: bool,
  right: bool
}

export default TextAlign
