exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Summary__Summary___1iTxU{overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.Summary__SummaryImage___2dJbA{-webkit-box-flex:1;-ms-flex:1 0 50%;flex:1 0 50%;padding-right:8px;border-right:2px solid}.Summary__SummaryContent___2bfOA{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding-left:8px;margin-top:-4px}.Summary__SummaryHeading___2uPyi{line-height:20px;margin-bottom:4px}.Summary__SummaryCategory___3SzfH{margin-bottom:-4px}.Summary__Summary-base___2q9gp .Summary__SummaryImage___2dJbA{border-right-color:#00cfdd}.Summary__Summary-base___2q9gp .Summary__SummaryContent___2bfOA{border-left-color:#00cfdd}.Summary__Summary-base___2q9gp .Summary__SummaryCategory___3SzfH{color:#00cfdd}.Summary__Summary-gloucester___23_3q .Summary__SummaryImage___2dJbA{border-right-color:#da1a32}.Summary__Summary-gloucester___23_3q .Summary__SummaryContent___2bfOA{border-left-color:#da1a32}.Summary__Summary-gloucester___23_3q .Summary__SummaryCategory___3SzfH{color:#da1a32}.Summary__Summary-inverse___1g6Ax .Summary__SummaryImage___2dJbA{border-right-color:#ff9800}.Summary__Summary-inverse___1g6Ax .Summary__SummaryContent___2bfOA{border-left-color:#ff9800}.Summary__Summary-inverse___1g6Ax .Summary__SummaryCategory___3SzfH{color:#ff9800}", ""]);

// exports
exports.locals = {
	"Summary": "Summary__Summary___1iTxU",
	"SummaryImage": "Summary__SummaryImage___2dJbA",
	"SummaryContent": "Summary__SummaryContent___2bfOA",
	"SummaryHeading": "Summary__SummaryHeading___2uPyi",
	"SummaryCategory": "Summary__SummaryCategory___3SzfH",
	"Summary-base": "Summary__Summary-base___2q9gp",
	"Summary-gloucester": "Summary__Summary-gloucester___23_3q",
	"Summary-inverse": "Summary__Summary-inverse___1g6Ax"
};