exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Crest__Crest___17A86{display:inline-block}.Crest__Crest___17A86,.Crest__Crest___17A86 svg{width:100%;height:100%}.Crest__Crest___17A86 svg{vertical-align:top}.Crest__sized___3y5ke svg{height:inherit;width:inherit}", ""]);

// exports
exports.locals = {
	"Crest": "Crest__Crest___17A86",
	"sized": "Crest__sized___3y5ke"
};